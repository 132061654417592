import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Row,
  Col,
} from "reactstrap";

function MedicalAdminHeader({ name, parentName }) {
  return (
    <>
      <div className="header bg-info pb-6">
        <div
          className="mx-auto"
          style={{
            // maxWidth: 1500
          }}
        >
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center px-0 py-4">
                <Col lg="12" xs="12" className="">
                  <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-md-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        <i className="fas fa-home" />
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        {parentName}
                      </a>
                    </BreadcrumbItem>
                    <BreadcrumbItem aria-current="page" className="active">
                      {name}
                    </BreadcrumbItem>
                  </Breadcrumb>
                </Col>
                <Col className="text-right" lg="6" xs="5">
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

MedicalAdminHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default MedicalAdminHeader
