import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader"
import { supabase } from '../../../lib/supabase'
import { notifyError, notifySuccess } from '../../../utils/notify'
import ReactDatetime from "react-datetime"
import { Dropzone } from '../../../components/Uploads/Dropzone'
//@ts-ignore
import { pdfjs, Document, Page } from 'react-pdf'

const initialFormState = {
  name: '',
  date: '',
  notes: '',
}

const initialSelectedState = {
  id: '',
  name: '',
  date: '',
  notes: '',
}

function RecordsManagementStarter({ profile, medical, dispatch }: any) {
  const [formData, setFormData] = useState(initialFormState)
  const [selectedRecordForUpdate, setSelectedRecordForUpdate] = useState(initialSelectedState)
  const [records, setRecords] = useState<any>([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [recordForFileView, setRecordForFileView] = useState<any>()
  const [isFormValid, setIsFormValid] = useState(false)
  const [recordFileForCreate, setRecordFileForCreate] = useState<File>()
  const [recordFileForUpdate, setRecordFileForUpdate] = useState<File>()
  const [recordFilenameForUpdate, setRecordFilenameForUpdate] = useState<string>()
  const [recordFileImage, setRecordFileImage] = useState<Blob>()
  const [recordFilePdf, setRecordFilePdf] = useState<Blob>()
  const [numPdfPages, setNumPdfPages] = useState(1)
  const [currentPdfPageNumber, setCurrentPdfPageNumber] = useState<number>(1)

  let history = useHistory()

  useEffect(() => {
    if (!profile.initialized) return
    init()
  }, [profile])

  async function init() {
    setFormData({ ...formData })
    getRecords()
  }

  async function getRecords() {
    const { data, error } = await supabase
      .from('records')
      .select(`*`)
      .eq('profile_id', profile.user.id)

    if (error) {
      console.error("Error fetching records: ", error)
    }
    setRecords(data)
  }

  async function createRecord() {
    if (!formData.name || !recordFileForCreate) return

    const { data: storageData, error: storageError } = await supabase.storage
      .from('records')
      .upload(`${profile.user.id}/${new Date().getTime()}_${recordFileForCreate.name}`,
        recordFileForCreate,
        { contentType: recordFileForCreate.type, }
      )

    if (storageError || !storageData) {
      console.error("Error uploading record file: ", storageError)
      notifyError("Error uploading record file")
      return
    }

    const { data: recordData, error: recordError } = await supabase
      .from('records')
      .insert({
        name: formData.name,
        date: formData.date,
        notes: formData.notes,
        filename: recordFileForCreate.name,
        filepath: storageData.path
      })
      .select()
      .single()

    if (recordError) {
      console.error("Error creating record: ", recordError)
      notifyError("Error adding record")
      return
    }

    notifySuccess("Record added successfully")
    setFormData({ ...initialFormState })
    setRecordFileForCreate(undefined)
    getRecords()
  }

  async function updateRecord(record: any) {
    const { data, error } = await supabase
      .from('records')
      .update({
        name: record.name,
        date: record.date,
        notes: record.notes,
      })
      .eq('id', record.id)

    if (error) {
      console.error("Error updating record: ", error)
      notifyError("Error updating record")
      return
    }

    notifySuccess("Record updated successfully")

    await getRecords()
  }

  async function deleteRecord(id: string) {
    const { error } = await supabase
      .from('records')
      .delete()
      .eq('id', id)

    if (error) {
      console.error("Error deleting record: ", error)
    }
    notifySuccess("Record deleted successfully.")
    await getRecords()
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true

    // Check if valid - first + last name
    if (formData.name != "" && !!recordFileForCreate) {
      // Set save flag
      setIsFormValid(true)
    } else {
      // Set save flag
      setIsFormValid(false)
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false
    }
  }, [formData, recordFileForCreate])

  const onFileSelectForCreate = (files: File[]) => {
    setRecordFileForCreate(files[0])
    if (formData.name === '') {
      setFormData({ ...formData, 'name': files[0].name })
    }
  }

  const onFileSelectForUpdate = (files: File[]) => {
    setRecordFileForUpdate(files[0])
  }

  const onSelectRecordForFileView = async (record: any) => {
    setRecordForFileView(record)
    const { data: downloadData, error: downloadError } = await supabase
      .storage.
      from('records').download(record.filepath)

    if (downloadError || !downloadData) {
      console.error("Error downloading record file: ", downloadError)
      notifyError("Error downloading record file")
      return
    }

    if (downloadData.type.includes('pdf')) {
      setRecordFilePdf(downloadData)
    } else {
      setRecordFileImage(downloadData)
    }
  }

  const onDismissRecordForFileView = () => {
    setRecordForFileView(undefined)
    setRecordFileImage(undefined)
    setRecordFilePdf(undefined)
    setNumPdfPages(1)
    setCurrentPdfPageNumber(1)
  }

  const onPdfLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPdfPages(numPages)
  }

  return (
    <>
      <MedicalAdminHeader name="Records Management" parentName="Starter" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Record</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name (Required)
                          </label>
                          <Input
                            id="input-name"
                            placeholder=""
                            type="text"
                            value={formData.name}
                            onChange={e => setFormData({ ...formData, 'name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Date
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "01/01/2024",
                            }}
                            timeFormat={false}
                            onChange={date => {
                              // Check if string (manual entry)
                              if (typeof date == "string") {
                                // Let module parse
                              } else { // Moment Class
                                // Set date
                                setFormData({ ...formData, 'date': date.format('YYYY-MM-DD') })
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" xs="12">
                        <label className="form-control-label">
                          File Upload
                        </label>
                        <div className="mb-3">
                          {recordFileForCreate ?
                            <div
                              className="text-center d-flex flex-column justify-content-center align-items-center border rounded"
                              style={{
                                height: 150,
                                border: '1px solid rgb(222, 226, 230)'
                              }}
                            >
                              <div className="mb-3 d-flex align-items-center">
                                <span className="text-muted mr-2">Attached File:</span>
                                <span><strong>{recordFileForCreate.name}</strong></span>
                              </div>
                              <Button
                                color="warning"
                                onClick={() => setRecordFileForCreate(undefined)}
                              >
                                Remove
                              </Button>
                            </div> :
                            <Dropzone
                              accept="image/*,application/pdf"
                              onFileSelect={onFileSelectForCreate}
                            />
                          }
                        </div>
                      </Col>
                      <Col lg="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-notes"
                          >
                            Notes
                          </label>
                          <Input
                            id="input-notes"
                            rows="5"
                            style={{ height: 150 }}
                            placeholder=""
                            type="textarea"
                            value={formData.notes}
                            onChange={e => setFormData({ ...formData, 'notes': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="success"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            e.preventDefault()
                            history.push('/admin/home')
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={createRecord}
                          size="xl"
                          disabled={!isFormValid}
                        >
                          Add Record
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Mange My Medical Records</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {records?.map((record: any) => (
                    <tr key={record.id}>
                      <td>
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            e.preventDefault()
                            setSelectedRecordForUpdate({
                              id: record.id,
                              name: record.name,
                              date: record.date,
                              notes: record.notes,
                            })
                            setRecordFilenameForUpdate(record.filename)
                            setShowEditModal(true)
                          }}
                        >
                          {record.name}
                        </a>
                      </td>
                      <td>
                        <span className="text-muted">
                          {record.date}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {record.notes}
                        </span>
                      </td>
                      <td>
                        <div>
                          <a
                            className="table-action"
                            href=""
                            id={`tooltip-view-${record.id}`}
                            onClick={(e) => {
                              e.preventDefault()
                              onSelectRecordForFileView(record)
                            }}
                          >
                            <i className="fas fa-file-contract text-lg" />
                          </a>
                          <UncontrolledTooltip delay={0} target={`tooltip-view-${record.id}`}>
                            View Record Document
                          </UncontrolledTooltip>
                          <a
                            className="table-action table-action-delete ml-3"
                            href=""
                            id={`tooltip-delete-${record.id}`}
                            onClick={(e) => {
                              e.preventDefault()
                              deleteRecord(record.id)
                            }}
                          >
                            <i className="fas fa-trash text-lg" />
                          </a>
                          <UncontrolledTooltip delay={0} target={`tooltip-delete-${record.id}`}>
                            Delete Record
                          </UncontrolledTooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={showEditModal}
        toggle={() => setShowEditModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Record
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowEditModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Name
                    </label>
                    <Input
                      id="input-name"
                      placeholder=""
                      type="text"
                      value={selectedRecordForUpdate.name}
                      onChange={e => setSelectedRecordForUpdate({ ...selectedRecordForUpdate, 'name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" xs="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                    // htmlFor="input-date"
                    >
                      Date
                    </label>
                    <ReactDatetime
                      // id="input-date"
                      inputProps={{
                        placeholder: selectedRecordForUpdate.date,
                      }}
                      timeFormat={false}
                      onChange={date => {
                        // Check if string (manual entry)
                        if (typeof date == "string") {
                          // Let module parse
                        } else { // Moment Class
                          // Set date
                          setSelectedRecordForUpdate({ ...selectedRecordForUpdate, 'date': date.format('YYYY-MM-DD') })
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs="12">
                  <label className="form-control-label">
                    File Upload
                  </label>
                  <div className="mb-3">
                    {recordFilenameForUpdate ?
                      <div
                        className="text-center d-flex flex-column justify-content-center align-items-center border rounded"
                        style={{
                          height: 150,
                          border: '1px solid rgb(222, 226, 230)'
                        }}
                      >
                        <div className="mb-3 d-flex align-items-center">
                          <span className="text-muted mr-2">Attached File:</span>
                          <span><strong>{recordFilenameForUpdate}</strong></span>
                        </div>
                        <Button
                          color="warning"
                          onClick={() => {
                            setRecordFilenameForUpdate(undefined)
                            setRecordFileForUpdate(undefined)
                          }}
                        >
                          Remove
                        </Button>
                      </div> :
                      <Dropzone
                        accept="image/*,application/pdf"
                        onFileSelect={onFileSelectForUpdate}
                      />
                    }
                  </div>
                </Col>
                <Col xs="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-notes"
                    >
                      Notes
                    </label>
                    <Input
                      id="input-notes"
                      rows="5"
                      placeholder=""
                      type="textarea"
                      value={selectedRecordForUpdate.notes}
                      onChange={e => setSelectedRecordForUpdate({ ...selectedRecordForUpdate, 'notes': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  updateRecord(selectedRecordForUpdate)

                  // Close modal
                  setShowEditModal(false)
                }}
                size="xl"
              >
                Update Record
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={!!recordForFileView}
        toggle={onDismissRecordForFileView}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Uploaded Record
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={onDismissRecordForFileView}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="px-4 py-2">
          <h4 className="mb-4">{recordForFileView?.filename}</h4>
          <div className="modal-body d-flex flex-column justify-content-center align-items-center m-2 text-muted">
            {/* <i className="fa-solid fa-file-contract mb-5" style={{ fontSize: 300 }}></i> */}
            {recordFileImage &&
              <img
                src={URL.createObjectURL(recordFileImage)}
                alt={`${recordForFileView?.filename}`}
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain'
                }}
              />
            }
            {recordFilePdf &&

              <Document
                file={URL.createObjectURL(recordFilePdf)}
                onLoadSuccess={onPdfLoadSuccess}
                // style={{ height: '100%' }}
                className="w-auto"
              >
                <Page
                  // width={600}
                  pageNumber={currentPdfPageNumber}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                />
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    Page {currentPdfPageNumber} of {numPdfPages}
                  </p>
                  <div className="d-flex align-items-center">
                    <Button
                      className="mr-2"
                      onClick={() => setCurrentPdfPageNumber(currentPdfPageNumber - 1)}
                      disabled={currentPdfPageNumber === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => setCurrentPdfPageNumber(currentPdfPageNumber + 1)}
                      disabled={currentPdfPageNumber === numPdfPages}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </Document>
            }
          </div>
        </div>
      </Modal>
    </>
  )
}

const ConnectedRecordsManagementStarter = connect((state: any) => ({
  profile: state.profile,
  medical: state.medical
}))(RecordsManagementStarter)

export default ConnectedRecordsManagementStarter
