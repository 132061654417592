import React, { useRef } from "react";
import { connect } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';
import { Button, Container, Row, Col, Modal } from "reactstrap";
import YourHealth from '../..//api';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function ProfileHeader({ profile, setPDFModal }) {
  const [showModal, setShowModal] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(null);

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage: 'url("/assets/img/profile/hero-1.png")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row style={{
            textAlign: 'left'
          }}>
            <Col lg="7" md="10">
              {!profile.isMobile ?
                <>
                  <h1 className="display-2 text-white">Hello{(profile.initialized && profile.user.first_name) ? ` ${profile.user.first_name},` : '!'}</h1>
                  <p className="text-white mt-0 mb-5">
                    YourHealth empowers you to manage health and personal needs, now and during an emergency. YourHealth is the health assurance solution that provides the information and control for safer care and treatments even when you can’t speak for yourself.
                  </p>

                  <p className="text-white mt-0 mb-5">
                    If at any time you want to go to a specific section, click on the three bars on the upper left. It opens a navigation bar that can take you to a specific area.
                  </p>
                </>
                :
                <>
                  <h1 className="display-2 text-white mr-2 ml-2">Hello{(profile.initialized && profile.user.first_name) ? ` ${profile.user.first_name},` : '!'}</h1>
                  <p className="text-white mt-0 mb-5 mr-2 ml-2">
                    YourHealth empowers you to manage health and personal needs, now and during an emergency.
                  </p>
                </>
              }
            </Col>
          </Row>
        </Container>

        {/* no code is ever called that opens this modal */}
        <Modal
          className="modal-dialog-centered"
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          size="lg"
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Your Essential Care Card
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div
            className="modal-body text-center"
            style={{
              overflow: 'auto'
            }}
          >
            <Document file={pdfUrl}>
              <Page
                width={700}
                pageNumber={1}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
            </Document>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              href=""
              onClick={() => {
                // Print ECC
                YourHealth.api.ecc.print(profile);

                // Close modal
                setShowModal(false);
              }}
              size="xl"
            >
              Print
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

//export default ProfileHeader;

// Connect to store
const ConnectedProfileHeader = connect(state => ({
  profile: state.profile
}))(ProfileHeader);

export default ConnectedProfileHeader;
