import React from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import YourHealth from '../../api';
import usePWA from 'react-pwa-install-prompt';

function AdminNavbar({ theme, sidenavOpen, toggleSidenav, profile, dispatch }) {
  const history = useHistory();
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();
  const handleInstall = async () => {
    const didInstall = await promptInstall()
    if (didInstall) {
      // User accepted PWA install
    }
  }

  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };

  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        className="cursor-pointer"
                        alt="Account Icon"
                        src="/assets/img/profiles/user.png"
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      {profile.initialized ?
                        <span id="member-name" className="mb-0 text-sm font-weight-bold cursor-pointer">
                          {profile.user.first_name} {profile.user.last_name}
                        </span>
                        :
                        <span className="mb-0 text-sm font-weight-bold cursor-pointer">
                          Account
                        </span>
                      }
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    href=""
                    onClick={() => {
                      // Navigate to home
                      history.push("/admin/home");
                    }}
                  >
                    <i className="ni ni-single-02" />
                    <span>My Profile</span>
                  </DropdownItem>
                  <DropdownItem
                    href=""
                    onClick={() => {
                      // Navigate to home
                      history.push("/admin/account");
                    }}
                  >
                    <i className="ni ni-archive-2" />
                    <span>My Account</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href=""
                    onClick={(e) => {

                      // Signout (API)
                      YourHealth.api.profile.logout({ profile, dispatch }, function () {
                        // Send to home
                        history.push("/");
                      });
                    }}
                  >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

//export default AdminNavbar;

// Connect to store
const ConnectedAdminNavbar = connect(state => ({
  profile: state.profile
}))(AdminNavbar);

export default ConnectedAdminNavbar;
