import { useForm } from 'react-hook-form'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'
import { ModalFormHeader } from './ModalFormHeader'

type AddConditionModalFormProps = {
  onSubmit: (data: AddConditionFormFields) => void
  onCancel: () => void
}

type AddConditionFormFields = {
  name: string
}
export function AddConditionModalForm({ onSubmit, onCancel }: AddConditionModalFormProps) {
  const { register, handleSubmit } = useForm<AddConditionFormFields>()
  const { ref: nameInputRef, ...registerName } = register('name')

  return (
    <>
      <ModalFormHeader onClose={() => onCancel()}>
        Add New Condition
      </ModalFormHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-4">
          <Row>
            <Col xs="12">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-name"
                >
                  Name
                </label>
                <Input
                  innerRef={nameInputRef}
                  id="input-name"
                  placeholder="Condition"
                  type="text"
                  {...registerName}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            type="button"
            onClick={onCancel}
            size="xl"
            style={{ width: 120 }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            size="xl"
            style={{ width: 120 }}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  )
}