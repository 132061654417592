import { SupabaseClient } from '@supabase/supabase-js'
import { create } from 'zustand'

type SupabaseInstanceState = {
  supabaseInstance: SupabaseClient | null
  setSupbaseInstance: (instance: SupabaseClient) => void
}
export const useSupabase = create<SupabaseInstanceState>((set) => ({
  supabaseInstance: null,
  setSupbaseInstance: (instance) => set({ supabaseInstance: instance })
}))