import { toast } from 'react-toastify'

export function notifyError(message: string) {
  return toast.error(
    <div>
      <p className="mb-1" style={{ color: '#E74C3C' }}>{message}</p>
      <p className="text-xs">If the problem persists, please contact <a href="mailto:support@1yourhealth.com">support@1yourhealth.com</a></p>
    </div>
  )
}

export function notifySuccess(message: string) {
  return toast.success(message)
}