/*
 *  Builder Component - Sign Up
 */

// React
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';

// REact Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

// Imports
import { Builder, BuilderComponent } from '@builder.io/react'

// Styles
import './Quotes.css';

function Quotes(props) {
  // Get props
  const {
    content
  } = props;

  // Get history
  const history = useHistory();

  // Return component
  return (
    <Carousel
      className="portal-quotes"
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
    >
      <div className="portal-quote">
        <h3 className="portal-quote-text">
          "I was in a car accident and was in and out of consciousness. My Essential Care Card was vital in sharing my medical allergies with the ER."
        </h3>
        <div className="portal-profile">
          <div className="portal-profile-name">
            <h3>- Sarah J.</h3>
          </div>
        </div>
      </div>
    </Carousel>
  )
}

Builder.registerComponent(Quotes, {
  name: 'Quotes',
  inputs: [
    {
      name: "content",
      type: "text",
      defaultValue: "This product is super awesome"
    }
  ]
});
