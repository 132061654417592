import { supabase } from '../lib/supabase'

const HEALTHCARE_VISITS_TABLE = 'healthcare_visits'

export async function getHealthcareVisits(profileId: string) {
  const { data } = await supabase
    .from(HEALTHCARE_VISITS_TABLE)
    .select(`*`)
    .throwOnError()
    .eq('profile_id', profileId)
  return data
}

export async function getHealthcareVisit(healthcareVisitId: string) {
  const { data } = await supabase
    .from(HEALTHCARE_VISITS_TABLE)
    .select(`*`)
    .throwOnError()
    .eq('id', healthcareVisitId)
    .single()

  return data
}