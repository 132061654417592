import { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader"
import { supabase } from '../../../lib/supabase'
import { notifyError, notifySuccess } from '../../../utils/notify'
import ReactDatetime from "react-datetime"
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createCondition, getConditions } from '../../../supabase-api/conditions'
import { DropdownSelect, Option } from '../../../components/Dropdowns/DropdownSelect'
import { createMedication, getPrescriptions } from '../../../supabase-api/medications'
import { createContact, getDoctors } from '../../../supabase-api/contacts'
import { AddDoctorModalForm } from '../../../components/ModalForms/AddDoctorModalForm'
import { AddConditionModalForm } from '../../../components/ModalForms/AddConditionModalForm'
import { AddPrescriptionModalForm } from '../../../components/ModalForms/AddPrescriptionModalForm'

import { Database } from '../../../types'
import { getHealthcareVisits } from '../../../supabase-api/healthcare_visits'

const initialHealthcareVisitFormValues = {
  name: '',
  date: '',
  notes: '',
}

type CreateHealthcareVisitBody = {
  healthcare_visit: Database["public"]["Tables"]["healthcare_visits"]["Insert"]
  healthcare_visit_doctor: Omit<Database["public"]["Tables"]["healthcare_visit_doctors"]["Insert"], 'healthcare_visit_id'>, // just a single doctor for now
  healthcare_visit_conditions?: Omit<Database["public"]["Tables"]["healthcare_visit_conditions"]["Insert"], 'healthcare_visit_id'>[],
  healthcare_visit_medications?: Omit<Database["public"]["Tables"]["healthcare_visit_medications"]["Insert"], 'healthcare_visit_id'>[],
}

function HealthcareVisitsStarter({ profile, medical, dispatch }: any) {
  const [healthcareVisitFormData, setHealthcareVisitFormData] = useState(initialHealthcareVisitFormValues)
  const [addItem, setAddItem] = useState(false)

  const [showAddDoctorModal, setShowAddDoctorModal] = useState(false)
  const [showAddConditionModal, setShowAddConditionModal] = useState(false)
  const [showAddPrescriptionModal, setShowAddPrescriptionModal] = useState(false)

  const [selectedDoctor, setSelectedDoctor] = useState<Database["public"]["Tables"]["contact"]["Row"]>()
  const [selectedConditions, setSelectedConditions] = useState<Database["public"]["Tables"]["condition"]["Row"][]>()
  const [selectedPrescriptions, setSelectedPrescriptions] = useState<Database["public"]["Tables"]["medication"]["Row"][]>()

  const queryClient = useQueryClient()

  const { data: conditions } = useQuery({
    queryKey: ['conditions'],
    queryFn: () => getConditions(profile.user.id),
    enabled: !!profile.user.id
  })

  const { data: prescriptions } = useQuery({
    queryKey: ['prescriptions'],
    queryFn: () => getPrescriptions(profile.user.id),
    enabled: !!profile.user.id
  })

  const { data: doctors } = useQuery({
    queryKey: ['doctors'],
    queryFn: () => getDoctors(profile.user.id),
    enabled: !!profile.user.id
  })

  const { data: healthcareVisits } = useQuery({
    queryKey: ['healthcare_visits'],
    queryFn: () => getHealthcareVisits(profile.user.id),
    enabled: !!profile.user.id
  })

  const addDoctorMutation = useMutation({
    mutationFn: (newDoctor: Database["public"]["Tables"]["contact"]["Insert"]) => {
      return createContact(newDoctor)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['doctors'] })
      queryClient.invalidateQueries({ queryKey: ['contacts'] })
      setShowAddDoctorModal(false)
      notifySuccess("Doctor added successfully")
    },
    onError: (error) => {
      console.error("Error adding doctor:", error)
      notifyError("Error adding doctor")
    }
  })

  const addConditionMutation = useMutation({
    mutationFn: (newCondition: Database["public"]["Tables"]["condition"]["Insert"]) => {
      return createCondition(newCondition)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['conditions'] })
      setShowAddConditionModal(false)
      notifySuccess("Condition added successfully")
    },
    onError: (error) => {
      console.error("Error adding condition:", error)
      notifyError("Error adding condition")
    }
  })

  const addPrescriptionMutation = useMutation({
    mutationFn: (newMedication: Database["public"]["Tables"]["medication"]["Insert"]) => {
      return createMedication(newMedication)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['medications'] })
      queryClient.invalidateQueries({ queryKey: ['prescriptions'] })

      setShowAddPrescriptionModal(false)
      notifySuccess("Prescription added successfully")
    },
    onError: (error) => {
      console.error("Error adding prescription:", error)
      notifyError("Error adding prescription")
    }
  })

  let history = useHistory()

  useEffect(() => {
    if (!profile.initialized) return
    init()
  }, [profile])

  async function init() {
    setHealthcareVisitFormData({ ...healthcareVisitFormData })
  }

  async function createHealthcareVisit() {
    if (!healthcareVisitFormData.name) return

    if (!selectedDoctor) {
      console.error('Please select a doctor')
      notifyError("Please select a doctor")
      return
    }

    const requestBody: CreateHealthcareVisitBody = {
      healthcare_visit: {
        name: healthcareVisitFormData.name,
        date: healthcareVisitFormData.date,
        notes: healthcareVisitFormData.notes,
        profile_id: profile.user.id,
      },
      healthcare_visit_doctor: {
        first_name: selectedDoctor.first_name as string, // todo
        last_name: selectedDoctor.last_name as string,
        email: selectedDoctor.email,
        phone: selectedDoctor.phone,
        contact_id: selectedDoctor.id,
        contact_type_id: selectedDoctor.contact_type_id,
        profile_id: profile.user.id,
      }
    }

    const { data, error } = await supabase.functions.invoke<CreateHealthcareVisitBody>('create-healthcare-visit', {
      body: requestBody,
    })

    if (error) {
      console.error("Error creating healthcare visit: ", error)
      notifyError("Error adding healthcare visit")
      return
    }

    // const { data: hcVisitDrData, error: hcVisitDrError } = await supabase
    //   .from('healthcare_visit_doctors')
    //   .insert({
    //     healthcare_visit_id: hcVisitData.id,
    //     contact_id: 1,
    //   })

    notifySuccess("Healthcare visit added successfully")
    setHealthcareVisitFormData({ ...initialHealthcareVisitFormValues })
    queryClient.invalidateQueries({ queryKey: ['healthcare_visits'] })
  }

  async function deleteHealthcareVisit(id: string) {
    const { error } = await supabase
      .from('healthcare_visits')
      .delete()
      .eq('id', id)

    if (error) {
      console.error("Error deleting record: ", error)
    }
    queryClient.invalidateQueries({ queryKey: ['healthcare_visits'] })
    notifySuccess("Record deleted successfully.")
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true

    // Check if valid - first + last name
    if (healthcareVisitFormData.name != "") {
      // Set save flag
      setAddItem(true)
    } else {
      // Set save flag
      setAddItem(false)
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false
    }
  }, [healthcareVisitFormData])

  return (
    <>
      <MedicalAdminHeader name="Healthcare Visits" parentName="Starter" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Healthcare Visit</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            Name (Required)
                          </label>
                          <Input
                            id="input-name"
                            placeholder=""
                            type="text"
                            value={healthcareVisitFormData.name}
                            onChange={e => setHealthcareVisitFormData({ ...healthcareVisitFormData, 'name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Date
                          </label>
                          <ReactDatetime
                            inputProps={{
                              placeholder: "01/01/2024",
                            }}
                            timeFormat={false}
                            onChange={date => {
                              // Check if string (manual entry)
                              if (typeof date == "string") {
                                // Let module parse (todo: what is this?)
                              } else {
                                setHealthcareVisitFormData({ ...healthcareVisitFormData, 'date': date.format('YYYY-MM-DD') })
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* for comprehensive tier, allow them to select/upload a medical record. */}

                    <Row>
                      <Col lg="6" xs="12">
                        <div className="mb-3">
                          <label className="form-control-label" >Doctor</label>
                          <div className="d-flex flex-column flex-lg-row align-items-center">
                            <div className="w-100 flex-grow-1 mb-3 mb-lg-0">
                              {doctors &&
                                <DropdownSelect
                                  options={doctors.map(doctor => {
                                    return { value: doctor.id, label: `${doctor.first_name} ${doctor.last_name} (${doctor.contact_type.name})` }
                                  })}
                                  onChange={(doctor) => {
                                    setSelectedDoctor(doctors.find(d => d.id === (doctor as Option).value))
                                  }}
                                />
                              }
                            </div>
                            <Button
                              style={{ minWidth: 180 }}
                              className="ml-lg-3 py-2 align-self-end"
                              onClick={() => setShowAddDoctorModal(true)}
                            >
                              Add Doctor
                            </Button>
                            <Modal
                              className="modal-dialog-centered"
                              isOpen={showAddDoctorModal}
                              toggle={() => setShowAddDoctorModal(false)}
                            >
                              <AddDoctorModalForm
                                onSubmit={(newDoctor) => { addDoctorMutation.mutate(newDoctor) }}
                                onCancel={() => setShowAddDoctorModal(false)}
                              />
                            </Modal>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-control-label" >Associated Conditions</label>
                          <div className="d-flex flex-column flex-lg-row align-items-center">
                            <div className="w-100 flex-grow-1 mb-3 mb-lg-0">
                              {conditions &&
                                <DropdownSelect
                                  options={conditions.map(condition => {
                                    return { value: condition.id, label: condition.name }
                                  })}
                                  multiple
                                  onChange={(selected) => {
                                    setSelectedConditions(conditions.filter(c => (selected as Option[]).map(o => o.value).includes(c.id)))
                                  }}
                                />
                              }
                            </div>
                            <Button
                              style={{ minWidth: 180 }}
                              className="ml-lg-3 py-2 align-self-end"
                              onClick={() => setShowAddConditionModal(true)}
                            >
                              Add Condition
                            </Button>
                            <Modal
                              className="modal-dialog-centered"
                              isOpen={showAddConditionModal}
                              toggle={() => setShowAddConditionModal(false)}
                            >
                              <AddConditionModalForm
                                onSubmit={(newCondition) => { addConditionMutation.mutate(newCondition) }}
                                onCancel={() => setShowAddConditionModal(false)}
                              />
                            </Modal>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-control-label" >Current Prescriptions</label>
                          <div className="d-flex flex-column flex-lg-row align-items-center">
                            <div className="w-100 flex-grow-1 mb-3 mb-lg-0">
                              {prescriptions &&
                                <DropdownSelect
                                  options={prescriptions?.map(prescription => {
                                    return { value: prescription.id, label: prescription.name }
                                  })}
                                  defaultSelected={prescriptions?.map(prescription => {
                                    return { value: prescription.id, label: prescription.name }
                                  })}
                                  multiple
                                  onChange={(selected) => {
                                    setSelectedPrescriptions(prescriptions.filter(p => (selected as Option[]).map(o => o.value).includes(p.id)))
                                  }}
                                />
                              }
                            </div>
                            <Button
                              style={{ minWidth: 180 }}
                              className="ml-lg-3 py-2 align-self-end"
                              onClick={() => setShowAddPrescriptionModal(true)}
                            >
                              Add Prescription
                            </Button>
                            <Modal
                              className="modal-dialog-centered"
                              isOpen={showAddPrescriptionModal}
                              toggle={() => setShowAddPrescriptionModal(false)}
                            >
                              <AddPrescriptionModalForm
                                onSubmit={(newPrescription) => { addPrescriptionMutation.mutate(newPrescription) }}
                                onCancel={() => setShowAddPrescriptionModal(false)}
                              />
                            </Modal>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6" xs="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-notes"
                          >
                            Notes
                          </label>
                          <Input
                            id="input-notes"
                            rows="5"
                            style={{ height: 210 }}
                            placeholder=""
                            type="textarea"
                            value={healthcareVisitFormData.notes}
                            onChange={e => setHealthcareVisitFormData({ ...healthcareVisitFormData, 'notes': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="success"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault()

                            // Send to account
                            history.push('/admin/home')
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={createHealthcareVisit}
                          size="xl"
                          disabled={!addItem}
                        >
                          Add Healthcare Visit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Mange My Healthcare Visits</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Doctor</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {healthcareVisits?.map((healthcareVisit: any) => (
                    <tr key={healthcareVisit.id}>
                      <td>
                        <Link
                          className="font-weight-bold"
                          to={`/healthcare-visits/healthcare-starter/${healthcareVisit.id}`}
                          onClick={(e) => {
                            // e.preventDefault()
                            // setSelectedData({
                            //   id: healthcareVisit.id,
                            //   name: healthcareVisit.name,
                            //   date: healthcareVisit.date,
                            //   notes: healthcareVisit.notes
                            // })
                            // setShowEditModal(true)
                          }}
                        >
                          {healthcareVisit.name}
                        </Link>
                      </td>
                      <td>
                        <span className="text-muted">
                          {healthcareVisit.date}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          &nbsp;
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-muted"
                          style={{
                            width: 400,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: 'inline-block'
                          }}
                        >
                          {healthcareVisit.notes}
                        </span>
                      </td>
                      <td>
                        <div>
                          <a
                            className="table-action table-action-delete ml-3"
                            href=""
                            id={`tooltip-delete-${healthcareVisit.id}`}
                            onClick={(e) => {
                              e.preventDefault()
                              deleteHealthcareVisit(healthcareVisit.id)
                            }}
                          >
                            <i className="fas fa-trash text-lg" />
                          </a>
                          <UncontrolledTooltip delay={0} target={`tooltip-delete-${healthcareVisit.id}`}>
                            Delete Record
                          </UncontrolledTooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container >
    </>
  )
}

// Connect to store
const ConnectedHealthcareVisitsStarter = connect((state: any) => ({
  profile: state.profile,
  medical: state.medical
}))(HealthcareVisitsStarter)

export default ConnectedHealthcareVisitsStarter
