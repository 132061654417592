// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Default to dev
let firebaseConfig = {
  apiKey: "AIzaSyBi5tNKs8z_PUTr0lOTu9Qi8kUZAJ96Jwg",
  authDomain: "yourhealth-dev.firebaseapp.com",
  projectId: "yourhealth-dev",
  storageBucket: "yourhealth-dev.appspot.com",
  messagingSenderId: "651969946652",
  appId: "1:651969946652:web:9e2128161c2648a34eabd9",
  measurementId: "G-T80RXCXRY1"
};

// Check URL for environment
if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  firebaseConfig = {
    apiKey: "AIzaSyCVJ2jJb_kCekPB0YmCVKflbogJRbE9UIU",
    authDomain: "yourhealth-app-2cda1.firebaseapp.com",
    projectId: "yourhealth-app-2cda1",
    storageBucket: "yourhealth-app-2cda1.appspot.com",
    messagingSenderId: "100872301125",
    appId: "1:100872301125:web:73e5bc251e071d545dd12c",
    measurementId: "G-XFSHK93XSB"
  };

  // console.log("Analytics: Loading production environment ", firebaseConfig);
} else {
  // console.log("Analytics: Loading development environment ", firebaseConfig);
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// console.log("Analytics: ", analytics);
