import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import { getAuthenticatedUser } from '../../../common/auth';
import {
  INITIALIZE_PROFILE
} from '../../../store/actions';
import PhoneInput from 'react-phone-number-input/input';
// import { loadStripe } from '@stripe/stripe-js';
import AccountHeader from "../../../components/Headers/AccountHeader.js";
import { supabase } from '../../../lib/supabase';
import { notifyError, notifySuccess } from '../../../utils/notify';

const initialFormState = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  street_1: '',
  street_2: '',
  city: '',
  state: '',
  zip_code: ''
}

const initialSelectedState = {
  currentPassword: '',
  newPassword1: '',
  newPassword2: ''
}

// this is the component associated with the dropdown account menu in the navbar
function Account({ profile, account, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [selectedData, setSelectedData] = useState(initialSelectedState);

  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    setFormData({
      first_name: profile.user.first_name,
      last_name: profile.user.last_name,
      email: profile.user.email,
      phone: profile.user.phone,
      street_1: profile.user.street_1,
      street_2: profile.user.street_2,
      city: profile.user.city,
      state: profile.user.state,
      zip_code: profile.user.zip_code
    });
  }

  // Update
  async function saveProfile(e) {
    e.preventDefault()

    // Modify profile
    let updatedProfile = profile.user;
    updatedProfile.first_name = formData.first_name;
    updatedProfile.last_name = formData.last_name;
    updatedProfile.phone = formData.phone;
    updatedProfile.street_1 = formData.street_1;
    updatedProfile.street_2 = formData.street_2;
    updatedProfile.city = formData.city;
    updatedProfile.state = formData.state;
    updatedProfile.zip_code = formData.zip_code;

    const { data, error } = await supabase
      .from('profile')
      .update({
        first_name: formData.first_name,
        last_name: formData.last_name,
        street_1: formData.street_1,
        street_2: formData.street_2,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zip_code,
        phone: formData.phone,
      })
      .eq('id', profile.user.id)

    if (error) {
      console.error('error saving profile', error);
      // notify("danger", "Profile did not update");
      notifyError("Unable to update account")
      return;
    }

    // Show notification
    // notify("success", "Your profile was successfully updated!");
    notifySuccess("Account successfully updated");

    // Sync new profile
    dispatch({
      type: INITIALIZE_PROFILE,
      payload: updatedProfile
    });
  }

  // Update password
  async function handleUpdatePassword(passwordData, callback) {
    getAuthenticatedUser()
      .then(user => {
        return updatePassword({
          oldPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword1
        });
      })
      .then(data => callback(data, null))
      .catch(err => callback(null, err));
  }

  // Notification
  const [alert, setalert] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {`${(type == 'success') ? 'Profile Successfully Updated' : 'Profile Did Not Update'}`}
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: `ni ${(type == 'success') ? 'ni-check-bold' : 'ni-bell-55'}`,
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AccountHeader name="Account" parentName="Home" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card id="my-profile">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">My Account</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <div style={{
                      height: '100%',
                      float: 'right'
                    }}>
                      <Button
                        color="secondary"
                        // onClick={(e) => {
                        //   // Stop default behavior
                        //   e.preventDefault();

                        //   // Open Modal
                        //   setdefaultModal(true);
                        // }}
                        href={`${process.env.REACT_APP_BASE_URL}/auth/update-password`}
                        size="xl"
                      >
                        Update Password
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Email
                          </label>
                          <Input
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="email"
                            value={formData.email ?? ''}
                            onChange={e => setFormData({ ...formData, 'email': e.target.value })}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone Number (mobile preferred)
                          </label>
                          <PhoneInput
                            className="form-control"
                            placeholder=""
                            defaultCountry="US"
                            value={formData.phone ?? ''}
                            onChange={phone => {
                              setFormData({ ...formData, phone })
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First Name
                          </label>
                          <Input
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            value={formData.first_name ?? ''}
                            onChange={e => setFormData({ ...formData, first_name: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last Name
                          </label>
                          <Input
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            value={formData.last_name ?? ''}
                            onChange={e => setFormData({ ...formData, last_name: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-street-1"
                          >
                            Street
                          </label>
                          <Input
                            id="input-street-1"
                            placeholder="123 Main St."
                            type="text"
                            value={formData.street_1 ?? ''}
                            onChange={e => setFormData({ ...formData, street_1: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-street-2"
                          >
                            Street (cont)
                          </label>
                          <Input
                            id="input-phone"
                            placeholder=""
                            type="text"
                            value={formData.street_2 ?? ''}
                            onChange={e => setFormData({ ...formData, street_2: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            id="input-city"
                            placeholder="Springfield"
                            type="text"
                            value={formData.city ?? ''}
                            onChange={e => setFormData({ ...formData, city: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-state"
                          >
                            State
                          </label>
                          <Input
                            id="input-state"
                            placeholder="Ohio"
                            type="text"
                            value={formData.state ?? ''}
                            onChange={e => setFormData({ ...formData, state: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-zip-code"
                          >
                            Zip Code
                          </label>
                          <Input
                            id="input-zip-code"
                            placeholder="12345"
                            type="text"
                            value={formData.zip_code ?? ''}
                            onChange={e => setFormData({ ...formData, zip_code: e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="success"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={saveProfile}
                          size="xl"
                        >
                          Update Account
                        </Button>
                      </div>
                      <div style={{
                        height: '100%',
                        float: 'right',
                        width: 200,
                        paddingRight: 20,
                        paddingTop: 5
                      }}>
                        <h6 className="mb-0" style={{
                          opacity: 0.5
                        }}>To cancel your account <br /> please <a href="" style={{ color: '#5B9AA4' }} onClick={(evt) => {
                          evt.preventDefault();

                          // Send email
                          window.open('mailto:support@1yourhealth.com?subject=Cancel%20My%20Account');
                        }}>Contact Support</a>.</h6>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedAccount = connect(state => ({
  profile: state.profile,
  account: state.account
}))(Account);

export default ConnectedAccount;
