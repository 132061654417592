import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Row
} from "reactstrap";

function CareCard({ card, medical, profile, dispatch }) {
  const history = useHistory();
  const { type: cardTypeUrlParam, id: shareKeyUrlParam } = useParams();
  
  let cardNotes = "";
  if (profile.initialized) {
    switch (card.type) {
      case "medical":
        cardNotes = profile.user.medical_notes;
        break;
      case "champion":
        cardNotes = profile.user.champion_notes;
        break;
      case "neighbor":
        cardNotes = profile.user.neighbor_notes;
        break;
      default:
        cardNotes = "";
    }
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="order-xl-1 px-0 py-2">
            <Card className="mt--6 pb-2">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="12">
                    <h2 className="mb-0 text-xl">Emergency Notes</h2>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="my-0 py-0">
                <CardText
                  className="mb-4 mb-lg-5"
                // style={{ maxWidth: '64ch' }}
                >
                  {cardNotes}
                </CardText>
                {card.type == "medical" &&
                  <>
                    <Row className="mb-2">
                      <Button
                        block
                        color="primary"
                        size="lg"
                        // type="button"
                        // onClick={() => history.push('/card/details/contacts')}
                        onClick={() => history.push(`/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}/contacts`)}
                      >
                        Emergency Contacts
                      </Button>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        type="button"
                        // onClick={() => history.push('/card/details/conditions')}
                        onClick={() => history.push(`/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}/conditions`)}
                      >
                        Chronic Conditions
                      </Button>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        // type="button"
                        // onClick={() => history.push('/card/details/prescriptions')}
                        onClick={() => history.push(`/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}/prescriptions`)}
                      >
                        Prescription Medications
                      </Button>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        // type="button"
                        // onClick={() => history.push('/card/details/otc')}
                        onClick={() => history.push(`/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}/otc`)}
                      >
                        OTC Medications
                      </Button>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        // type="button"
                        onClick={() => history.push(`/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}/allergies`)}
                      >
                        Allergies
                      </Button>
                    </Row>
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        // type="button"
                        onClick={() => history.push(`/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}/immunizations`)}
                      // href={`/card/view/${cardTypeUrlParam}/${shareKeyUrlParam}/immunizations`}
                      >
                        Immunizations
                      </Button>
                    </Row>
                  </>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const ConnectedCareCard = connect(state => ({
  card: state.card,
  profile: state.profile,
  medical: state.medical
}))(CareCard)

export default ConnectedCareCard
