import React, { useEffect } from "react";
import { connect } from 'react-redux';
import {
  Card,
  Col,
  Container,
  Row,
  Table
} from "reactstrap";
import MedicalCardDetailsHeader from "../../../components/Headers/MedicalCardDetailsHeader";
import { LOAD_MEDICATIONS } from '../../../store/actions';
import { useSupabase } from '../../../lib/useSupabase';
import { notifyError } from '../../../utils/notify';

function OTCMedications({ profile, medical, dispatch }) {
  const { supabaseInstance } = useSupabase()

  useEffect(() => {
    if (!profile.initialized || !supabaseInstance) return
    getOtcMedications(profile.user.id);
  }, [supabaseInstance, profile]);


  async function getOtcMedications(profileId) {
    const { data, error } = await supabaseInstance
      .from('medication')
      .select(`*, medication_type!inner(*)`)
      .eq('medication_type.name', 'OVERTHECOUNTER')
      .eq('profile_id', profileId)

    if (error) {
      console.error('error getting medications', error)
      notifyError("Error getting medications")
      return
    }
    dispatch({
      type: LOAD_MEDICATIONS,
      payload: {
        type: "OVERTHECOUNTER",
        items: data
      }
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col className="order-xl-1 px-0 py-2">
          <Card className="mt--6 pb-2">
            <MedicalCardDetailsHeader title="OTC Medications" />
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Dose</th>
                  <th>Frequency</th>
                </tr>
              </thead>
              <tbody>
                {medical.medications.otc.map((medication, index) => (
                  <tr key={index}>
                    <td className="table-user">
                      <a
                        className="font-weight-bold"
                        href="#"
                        onClick={(e) => e.preventDefault()}
                      >
                        {medication.name}
                      </a>
                    </td>
                    <td>
                      <span className="text-muted">
                        {medication.dose}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                        {medication.frequency}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const ConnectedOTCMedications = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(OTCMedications);

export default ConnectedOTCMedications;
