import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

type DropzoneProps = {
  accept?: string
  multiple?: boolean
  onFileSelect?: (files: File[]) => void
}
export function Dropzone({ accept, multiple = false, onFileSelect }: DropzoneProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (onFileSelect) {
      onFileSelect(acceptedFiles)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      {...getRootProps()}
      className="rounded p-4 text-center cursor-pointer"
      style={{
        border: '1px dashed #ccc',
        height: 150,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input
        {...getInputProps()}
        accept={accept}
        multiple={multiple}
      />
      {
        isDragActive ?
          <p >Drop the files here ...</p> :
          <p className="text-sm">Drag &amp; drop a file here, or click to select.</p>
      }
    </div>
  )
}