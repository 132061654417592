// Setup config export
const config = {
  api: {
    qr: {
      url: ""
    },
    sms: {
      url: ""
    },
    billing: {
      url: "https://us-central1-yourhealth-app-2cda1.cloudfunctions.net/billing-api"
      //url: "http://localhost:5001/yourhealth-app-2cda1/us-central1/billing-api"
    },
    email: {
      url: "http://localhost:5001/yourhealth-app-2cda1/us-central1/email-api/email-api"
    }
  }
};

export default config;
