import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import YourHealth from '../../../api';
import PhoneInput from 'react-phone-number-input/input';
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader.js";
import {
  LOAD_CONTACTS
} from '../../../store/actions';
import { supabase } from '../../../lib/supabase';
import { notifyError, notifySuccess } from '../../../utils/notify';


const initialFormState = {
  type: 'NEIGHBOR',
  is_champion: false,
  email: '',
  phone: '',
  first_name: '',
  last_name: '',
  profileContactsId: ''
}

const initialSelectedState = {
  id: '',
  type: 'NEIGHBOR',
  is_champion: false,
  email: '',
  phone: '',
  first_name: '',
  last_name: '',
  profileContactsId: ''
}

function Contacts({ profile, medical, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [contactTypes, setContactTypes] = useState([])

  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    setFormData({
      ...formData,
      'profileContactsId': profile.user.id
    });

    getContactTypes()
    getContacts(profile.user.id);
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;

    // Check if valid - first + last name
    if (formData.first_name !== "" && formData.last_name !== "") {

      // Set save flag
      setAddItem(true);
    } else {
      // Set save flag
      setAddItem(false);
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [formData]);

  async function getContactTypes() {
    const { data, error } = await supabase
      .from('contact_type')
      .select('*')

    if (error) {
      console.error('error getting contact types', error)
    }
    setContactTypes(data);
  }

  async function getContacts(profileId) {
    let { data, error } = await supabase
      .from('contact')
      .select('*, contact_type(*)')
      .eq('profile_id', profileId)

    if (error) {
      console.error('error getting contacts', error)
    }

    dispatch({
      type: LOAD_CONTACTS,
      payload: data
    });
  }

  async function saveContact() {
    if (!formData.first_name || !formData.last_name) return;

    let newContact = JSON.parse(JSON.stringify(formData));
    newContact.type = document.getElementById('contactRelation').value;

    // Track event
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (window.posthog) {
        window.posthog.capture('Updated Data', {
          category: "Medical Information",
          label: "Contacts",
          $set: {
            email: profile.user.email,
          }
        });
      }

      // Mark change to profile
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Updated_Profile"
      });
    }

    const { data, error } = await supabase
      .from('contact')
      .insert(
        {
          email: newContact.email,
          phone: newContact.phone,
          first_name: newContact.first_name,
          last_name: newContact.last_name,
          contact_type_id: newContact.type,
          is_champion: newContact.is_champion,
        },
      )

    if (error) {
      console.error('error inserting contact', error)
      notifyError('Error saving contact');
      return
    }

    notifySuccess('Contact saved successfully');

    setFormData({
      ...initialFormState,
      'profileContactsId': profile.user.id
    });

    // Reset flag
    setAddItem(false);

    await getContacts(profile.user.id);
  }

  async function removeContact(contactId) {
    const { error } = await supabase
      .from('contact')
      .delete()
      .eq('id', contactId)

    if (error) {
      console.error("Error deleting contact: ", error);
      notifyError('Error deleting contact');
      return
    }

    notifySuccess('Contact deleted successfully');

    await getContacts(profile.user.id);
  }

  async function modifyContact(contact) {
    let newContact = JSON.parse(JSON.stringify(contact));
    newContact.type = document.getElementById('updatedContactRelation').value;

    // delete newContact.createdAt;

    const { data, error } = await supabase
      .from('contact')
      .update(
        {
          email: newContact.email,
          phone: newContact.phone,
          first_name: newContact.first_name,
          last_name: newContact.last_name,
          contact_type_id: newContact.type,
          is_champion: newContact.is_champion,
        },
      )
      .eq('id', newContact.id)

    if (error) {
      console.error('error updating contact', error)
      notifyError('Error updating contact');
      return
    }

    notifySuccess('Contact updated successfully');

    await getContacts(profile.user.id);
  }

  const formatPhoneNumber = (str) => {
    if (!str) return ''
    //Filter only numbers from the input
    let cleaned = ('' + str.substr(2)).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };

  return (
    <>
      <MedicalAdminHeader name="Contacts" parentName="Medical Information" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Contact</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First Name (Required)
                          </label>
                          <Input
                            id="input-first-name"
                            placeholder=""
                            type="text"
                            value={formData.first_name}
                            onChange={e => setFormData({ ...formData, 'first_name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last Name (Required)
                          </label>
                          <Input
                            id="input-last-name"
                            placeholder=""
                            type="text"
                            value={formData.last_name}
                            onChange={e => setFormData({ ...formData, 'last_name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-type"
                          >
                            Relation
                          </label>
                          {contactTypes?.length && <Input id="contactRelation" type="select">
                            {/* {contactTypes.map(contactType => (
                              <option
                                key={contactType.id}
                                value={contactType.id}
                              >
                                {contactType.name}
                              </option>
                            ))} */}

                            <option value={contactTypes.find(ct => ct.name === 'SPOUSE_PARTNER').id}>Spouse/Partner</option>
                            <option value={contactTypes.find(ct => ct.name === 'NEIGHBOR').id}>Neighbor</option>
                            <option value={contactTypes.find(ct => ct.name === 'DOCTOR').id}>Doctor</option>
                            <option value={contactTypes.find(ct => ct.name === 'ONCOLOGIST').id}>Oncologist</option>
                            <option value={contactTypes.find(ct => ct.name === 'CARDIOLOGIST').id}>Cardiologist</option>
                            <option value={contactTypes.find(ct => ct.name === 'OTHER').id}>Other</option>

                            {/* <option value="SPOUSE_PARTNER">Spouse/Partner</option>
                            <option value="NEIGHBOR">Neighbor</option>
                            <option value="DOCTOR">Doctor</option>
                            <option value="OTHER">Other</option> */}
                          </Input>}
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email Address
                          </label>
                          <Input
                            id="input-email"
                            placeholder=""
                            type="email"
                            value={formData.email}
                            onChange={e => setFormData({ ...formData, 'email': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control"
                            placeholder=""
                            defaultCountry="US"
                            value={setFormData.phone}
                            onChange={phone => {
                              setFormData({ ...formData, phone })
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="success"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          id="button-create-contact"
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={saveContact}
                          size="xl"
                          disabled={!addItem}
                        >
                          Save Contact
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Emergency Contacts</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Contact</th>
                    <th>Phone Number</th>
                    <th>Relation</th>
                    <th>Consent</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {medical.contacts.map((contact, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <img
                          alt="..."
                          className="avatar rounded-circle mr-3"
                          src="/assets/img/profiles/user.png"
                        />
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            // Stop default behavior
                            e.preventDefault();

                            // Set selected data
                            setSelectedData({
                              id: contact.id,
                              type: contact.contact_type.name,
                              email: contact.email,
                              phone: contact.phone,
                              first_name: contact.first_name,
                              last_name: contact.last_name,
                              hasConsent: contact.hasConsent,
                              profileContactsId: formData.profileContactsId
                            });

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          <b>{contact.first_name} {contact.last_name}</b>
                        </a>
                      </td>
                      <td>
                        <a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a>
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            // Stop default behavior
                            e.preventDefault();

                            // Set selected data
                            setSelectedData({
                              id: contact.id,
                              type: contact.type,
                              email: contact.email,
                              phone: contact.phone,
                              first_name: contact.first_name,
                              last_name: contact.last_name,
                              hasConsent: contact.hasConsent,
                              profileContactsId: formData.profileContactsId
                            });

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          {contact.contact_type.name}
                        </a>
                      </td>
                      <td>
                        {contact.hasConsent &&
                          <a
                            className="table-action"
                            href="#"
                            id="tooltip601065234"
                          >
                            <i className="ni ni-check-bold" />
                          </a>
                        }
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href=""
                          id="tooltip564981685"
                          onClick={(e) => {
                            // Stop default behavior
                            e.preventDefault();
                            // Set selected data
                            setSelectedData({
                              id: contact.id,
                              // type: contact.type,
                              type: contact.contact_type.name,
                              email: contact.email,
                              phone: contact.phone,
                              first_name: contact.first_name,
                              last_name: contact.last_name,
                              hasConsent: contact.hasConsent,
                              profileContactsId: formData.profileContactsId
                            });

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          <i className="fas fa-user-edit" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip564981685">
                          Edit Contact
                        </UncontrolledTooltip>
                        <a
                          className="table-action table-action-delete"
                          href="#"
                          id="tooltip601065234"
                          onClick={() => removeContact(contact.id)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip601065234">
                          Delete Contact
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Contact
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      First Name
                    </label>
                    <Input
                      id="input-first-name"
                      type="text"
                      value={selectedData.first_name}
                      onChange={e => setSelectedData({ ...selectedData, 'first_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Last Name
                    </label>
                    <Input
                      id="input-last-name"
                      type="text"
                      value={selectedData.last_name}
                      onChange={e => setSelectedData({ ...selectedData, 'last_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-type"
                    >
                      Relation
                    </label>
                    {contactTypes?.length &&
                      <Input
                        id="updatedContactRelation"
                        type="select"
                        defaultValue={contactTypes.find(ct => ct.name === selectedData.type)?.id}
                      >
                        <option value={contactTypes.find(ct => ct.name === 'SPOUSE_PARTNER').id}>Spouse/Partner</option>
                        <option value={contactTypes.find(ct => ct.name === 'NEIGHBOR').id}>Neighbor</option>
                        <option value={contactTypes.find(ct => ct.name === 'DOCTOR').id}>Doctor</option>
                        <option value={contactTypes.find(ct => ct.name === 'ONCOLOGIST').id}>Oncologist</option>
                        <option value={contactTypes.find(ct => ct.name === 'CARDIOLOGIST').id}>Cardiologist</option>
                        <option value={contactTypes.find(ct => ct.name === 'OTHER').id}>Other</option>
                      </Input>
                    }
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      id="input-phone"
                      className="form-control"
                      defaultCountry="US"
                      value={selectedData.phone}
                      onChange={phone => {
                        setSelectedData({ ...selectedData, 'phone': phone })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Email Address
                    </label>
                    <Input
                      id="input-email"
                      type="email"
                      value={selectedData.email}
                      onChange={e => setSelectedData({ ...selectedData, 'email': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <div className="custom-control custom-checkbox mb-3 pl-5 pr-4">
                      <input
                        className="custom-control-input"
                        id="input-consent"
                        type="checkbox"
                        checked={selectedData.hasConsent}
                        disabled
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="input-consent"
                      >
                        By checking this box, I have gained consent by this contact for me to send them a YourHealth text message. Learn about <a href="/admin/home?tutorial=send-qr-code">sending QR codes via SMS</a>.
                      </label>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <div style={{
            height: '100%',
            float: 'left'
          }}>
            <Button
              color="danger"
              href=""
              onClick={(e) => {
                // Delete contact
                removeContact(selectedData.id)

                // Close modal
                setdefaultModal(false);
              }}
              size="xl"
            >
              Delete Contact
            </Button>
          </div>
          <Button
            color="primary"
            href="#"
            onClick={() => {
              let modifiedContact = JSON.parse(JSON.stringify(selectedData));
              modifiedContact.type = document.getElementById('updatedContactRelation').value;
              modifyContact(modifiedContact);
              setdefaultModal(false);
            }}
            size="xl"
          >
            Update Contact
          </Button>
        </div>
      </Modal>
    </>
  );
}

const ConnectedContacts = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Contacts);

export default ConnectedContacts;
