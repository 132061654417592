import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader"
import {
  LOAD_MEDICATIONS
} from '../../../store/actions'
import { supabase } from '../../../lib/supabase'
import { notifyError, notifySuccess } from '../../../utils/notify'
import ReactDatetime from "react-datetime"

function RecordsManagementAdvanced({ profile, medical, dispatch }: any) {
  return (
    <>
      <MedicalAdminHeader name="Records Management" parentName="Advanced" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Record</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>

              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Mange My Medical Records</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Link</th>
                    <th>Notes</th>
                    <th />
                  </tr>
                </thead>
                <tbody>

                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

// Connect to store
const ConnectedRecordsManagementAdvanced = connect((state: any) => ({
  profile: state.profile,
  medical: state.medical
}))(RecordsManagementAdvanced)

export default ConnectedRecordsManagementAdvanced
