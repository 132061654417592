import * as actionTypes from '../actions';
import { isMobile } from "react-device-detect";

const initialState = {
  initialized: false,
  user: {
    id: null,
    username: null,
    amplifyId: null,
    email: null,
    phone: null,
    first_name: null,
    last_name: null
  },
  welcome: {
    complete: false,
    stage: 'sign-up'
  },
  isAdmin: false,
  isMobile
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INITIALIZE_PROFILE: {
      const profileUser = {
        id: action.payload.id,
        street_1: action.payload.street_1,
        street_2: action.payload.street_2,
        city: action.payload.city,
        state: action.payload.state,
        zip_code: action.payload.zip_code,
        phone: action.payload.phone,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        email: action.payload.email,
        created_at: action.payload.created_at,
        champion_notes: action.payload.champion_notes,
        medical_notes: action.payload.medical_notes,
        neighbor_notes: action.payload.neighbor_notes,
      }

      return {
        ...state,
        initialized: true,
        user: profileUser,
        welcome: {
          complete: true,
          // complete: (action.payload && action.payload.complete) ? action.payload.complete : false, 
          stage: 'sign-up'
        }
      };
    }
    case actionTypes.CLEAR_PROFILE: {
      return {
        ...state,
        initialized: false,
        user: {
          id: null,
          username: null,
          amplifyId: null,
          email: null,
          phone: null,
          first_name: null,
          last_name: null
        },
        welcome: {
          complete: false,
          stage: 'sign-up'
        }
      };
    }
    case actionTypes.UPDATE_PROFILE_USER: {
      return {
        ...state,
        initialized: true,
        user: {
          id: action.payload.id,
          amplifyId: action.payload.amplifyId,
          email: action.payload.email,
          phone: action.payload.phone,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name
        }
      };
    }
    case actionTypes.UPDATE_WELCOME_STAGE: {
      return {
        ...state,
        welcome: action.payload
      };
    }
    case actionTypes.SET_USER_ADMIN: {
      return {
        ...state,
        isAdmin: true
      };
    }
    default:
      return state;
  }
};

export default profileReducer;
