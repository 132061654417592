import React from 'react';
import ReactDOM from 'react-dom';

// Redux
import { Provider } from 'react-redux'
import { store } from './store';

// Google Tag Manager
import TagManager from 'react-gtm-module'

// Import (App)
import App from './App';
//import * as serviceWorker from './serviceWorker';

// AWS Amplify styles
import '@aws-amplify/ui-react/styles.css';

// Style overrides
import './auth.css';

// // Authentication (AWS)
// import Amplify from 'aws-amplify';
// import config from './aws-exports';
// Amplify.configure(config);

// import { Amplify } from 'aws-amplify';
// import config from './amplifyconfiguration.json';
// Amplify.configure(config);

// Configure tag manager
TagManager.initialize({
    gtmId: 'GTM-5QMKL73'
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
