import { Session } from '@supabase/auth-js'
import React, { ReactNode, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { supabase } from '../../lib/supabase'
import { set, useForm } from 'react-hook-form'
import {
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
  fetchUserAttributes as cognitoFetchUserAttributes,
} from 'aws-amplify/auth'
import { Amplify } from 'aws-amplify'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID ?? '',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
    }
  }
})

export default function Login() {
  const [session, setSession] = useState<Session>(null!)
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | null>(null)
  const [migrationMessage, setMigrationMessage] = useState<string | ReactNode | null>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const history = useHistory()

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) return
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) return
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (!session) return
    history.replace('/admin/home')
  }, [session])

  type SignInFormFields = {
    email: string
    password: string
  }
  const { register, handleSubmit } = useForm<SignInFormFields>()

  /**
   * This sign in flow handles three scenarios:
   * 
   * 1. The user exists in Supabase Auth, and are signed in.
   * 
   * 2. The user doesn't exist in Supabase Auth, but does exist in Cognito.
   *    If Cognito accepts their email and password, we create a new user in 
   *    Supabase Auth using the same email and password, then call a server side
   *    function that migrates their data from DynamoDB to Postgres using AppSync.
   * 
   * 3. The user doesn't exist in Supabase Auth or Cognito, so we create a new user
   *    and send them an email confirmation link.
   */
  const handleSignIn = async ({ email, password }: SignInFormFields) => {
    setErrorMessage(null)
    if (!email || !password) {
      setErrorMessage('Please enter your email and password.')
      return
    }

    setIsSubmitting(true)
    console.log('signing in with email:', email)
    setErrorMessage(null)
    /**
     * Attempt to sign in with Supabase Auth
     */

    const { error: supabaseSignInError } = await supabase.auth.signInWithPassword({
      email,
      password
    })

    /**
     * Supabase Auth doesn't throw an error, but instead returns an error object.
     * Anything caught in the catch block below will likely be a network error.
     */

    if (supabaseSignInError) {
      if (supabaseSignInError.message.includes('Email not confirmed')) {
        history.push({
          pathname: '/auth/confirm-email',
          search: `?email=${encodeURIComponent(email)}`
        })
        return
      }

      console.log('unable to sign in with supabase, trying cognito.', supabaseSignInError.message)
      /**
       * Supabase Auth sign in failed, so we check if that user exists in Cognito.
       * Attempt to sign in with Cognito. If successful, we'll create a new
       * user in Supabase and migrate their data from DynamoDB into Postgres.
       */

      /**
       * Clear out any existing cognito browser sessions.
       * Any existing sessions would be from a previous sign in,
       * from this new sign in flow. It won't sign the user out
       * of the exiting Amplify backend version of the app.
       */
      await cognitoSignOut()

      try {
        // Attempt to sign into Cognito to verify the provided credentials.
        const cognitoSignInRes = await cognitoSignIn({
          username: email,
          password
        })

        console.log('cognito sign in successful.')
        /**
        * If Cognito accepts the credentials provided by the user, 
        * we call a server side function that creates a new user in 
        * Supabase Auth with the same email and password, then 
        * copies all their data from DynamoDB into Postgres.
        * This server function will also need to verify the credentials
        * with Cognito before proceeding.
        * 
        * If Cognito rejects the supplied credentials, cognitoSignIn() 
        * will throw an error, which is caught below.
        */

        console.log('migrating user from amplify to supabase.')
        setMigrationMessage('One moment while we update your account...')
        const { data: migrationData, error: migrationError } = await supabase.functions.invoke('migrate-user-from-amplify', {
          body: { email, password },
        })

        if (migrationError) {
          setIsSubmitting(false)
          console.error(migrationError)
          if (migrationError.message.error?.includes('A user with this email address has already been registered')) {
            console.log('User already exists in Supabase, but invalid credentials provided.')
            setErrorMessage('Invalid credentials. Please try again, or click the "Forgot Password" link above to reset your password.')
            return
          } else {
            console.error('Unable to migrate user:', migrationError)
            setErrorMessage('Error signing in. Please try again, or click the "Forgot Password" link above to reset your password.')
            return
          }
        }

        console.log('Supabase function response:', migrationData)

        console.log('user migrated successfully. signing in with supabase.')

        const { error: supabaseSignInError } = await supabase.auth.signInWithPassword({
          email,
          password
        })

        if (supabaseSignInError) {
          setIsSubmitting(false)
          console.error('Unable to sign in with Supabase:', supabaseSignInError)
          setErrorMessage('Error signing in. Please try again, or click the "Forgot Password" link above to reset your password.')
        }

      } catch (error: any) {
        setIsSubmitting(false)
        /**
         * Possible Cognito sign in errors:
         * 1. User does not exist.
         * 2. User does exist, but provided an incorrect password.
         * 
         * In either case, we display the same error message to the user.
         */
        console.log('unable to sign in with cognito.')
        console.error(error)
        setErrorMessage('Invalid credentials. Please try again, or click the "Forgot Password" link above to reset your password.')
      } finally {
        setIsSubmitting(false)
        setMigrationMessage(null)
      }
    }
  }

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100 h-100 px-3 pb-8"
        style={{
          paddingTop: 100,
        }}
      >
        <div>
          <img
            src="/logo.white.png"
            alt="YourHealth Logo"
            style={{
              width: 300,
              marginBottom: '2rem',
            }}
          />
        </div>
        <div
          className="p-4 shadow-lg bg-white"
          style={{
            backgroundColor: '#181A1B',
            width: 480,
            maxWidth: '100%',
            borderRadius: 6,
          }}
        >
          <form onSubmit={handleSubmit(handleSignIn)}>
            <div className="d-flex flex-column mb-3">
              <label
                htmlFor="email"
                className="mb-1"
              >
                Email
              </label>
              <input
                {...register("email")}
                className="form-input"
                type="email"
                placeholder="Your email address"
                id="email"
                style={{
                  borderRadius: 4,
                  border: '1px solid rgb(131, 140, 149)',
                }}
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="password">Password</label>
              <div className="password-input-container">
                <input
                  {...register("password")}
                  id="password"
                  type={showPassword ? "text" : "password"}
                  className="form-input"
                  placeholder="Your password"
                />
                <button
                  type="button"
                  className="toggle-password-button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ?
                    <i className="fas fa-eye-slash text-sm" />
                    :
                    <i className="fas fa-eye text-sm" />
                  }
                </button>
              </div>
            </div>
            <div className="d-flex flex-column mt-3">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn mb-3"
                style={{
                  backgroundColor: '#047D95',
                  color: '#fff',
                  border: 'none',
                }}
              >
                {isSubmitting &&
                  <>
                    <div
                      className="spinner-border mr-2"
                      role="status"
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                    <span>Signing In</span>
                  </>
                }

                {!isSubmitting &&
                  <span>Sign in</span>
                }
              </button>

              <button
                type="button"
                className="form-button my-1 text-sm"
                onClick={() => history.push('/auth/create-account')}
              >
                Create Account
              </button>

              <button
                type="button"
                className="form-button my-1 text-sm"
                onClick={() => history.push('/auth/request-password-reset')}
              >
                Forgot Password
              </button>
            </div>
          </form>
        </div>
        {/* {migrationMessage && (
          <div className="alert alert-info mt-4" role="alert">
            {migrationMessage}
          </div>
        )} */}
        {errorMessage && (
          <div className="alert alert-danger mt-4" role="alert">
            {errorMessage}
          </div>
        )}
      </div>
    </>
  )
}
