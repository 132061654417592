/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { pdfjs, Document, Page } from 'react-pdf';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

// React Phone Input
import PhoneInput from 'react-phone-number-input/input';

// core components
import SignUpHeader from "../../../../components/Headers/SignUpHeader.js";

// AWS Auth
import { getAuthenticatedUser } from '../../../../common/auth';

// Amplify - API
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import { createProfile } from '../../../../graphql/mutations';
import {
  INITIALIZE_PROFILE,
  UPDATE_PROFILE_USER,
  UPDATE_WELCOME_STAGE
} from '../../../../store/actions';

// API (YourHealth)
import YourHealth from '../../../../api';

// Analytics (Firebase)
import { getAnalytics, logEvent } from "firebase/analytics";

const initialFormState = {
  username: '',
  amplifyId: '',
  billingId: '-1',
  email: '',
  mobile: '',
  firstName: '',
  lastName: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zipCode: '',
  complete: false,
  eccSetup: false,
  medicalNotes: '',
  medicalCardUrl: '',
  activeMedicalECC: '',
  championNotes: '',
  championCardUrl: '',
  activeChampionECC: '',
  neighborNotes: '',
  neighborCardUrl: '',
  activeNeighborECC: ''
}

const initialSelectedState = {
  currentPassword: 'yourhealth',
  newPassword1: '',
  newPassword2: ''
}

// PDF JS (set global worker)
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// not used anymore (but reverted after being deleted)
function SignUp({ profile, dispatch }) {
  // States
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [numPages, setNumPages] = useState(null);
  const [passwordModal, setpasswordModal] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordMessage, setPasswordMessage] = React.useState(null);
  const [optIn, setOptIn] = React.useState(false);
  // Get profile (use effect)
  useEffect(() => {
    // Sync profile
    syncProfile();
  }, []);

  // Sync profile
  async function syncProfile() {
    console.log('syncing profile!')
    // Set email form data
    setFormData({
      ...formData,
      'username': profile.user.username,
      'amplifyId': profile.user.amplifyId,
      'email': profile.user.email
    });

    // Subscribe user to email
    YourHealth.api.email.subscribe({
      email: profile.user.email,
      list: "all_members"
    });

    if (window.posthog && process.env.REACT_APP_ENVIRONMENT === 'prod') {
      // Log initial sign-up
      const analytics = getAnalytics();
      logEvent(analytics, 'sign_up', {
        username: profile.user.username,
        amplifyId: profile.user.amplifyId,
        email: profile.user.email
      });
    }
  }

  // Creat profile
  async function saveProfile() {
    console.log(formData);

    // TODO: Reset profile search index
    formData.firstName = "User";
    formData.lastName = "";

    // Force mobile value (if left null)
    if (!formData.mobile) {
      formData.mobile = "";
    }

    // Set persistent URLs
    formData.medicalCardUrl = `https://portal.yourhealth.app/card/view/medical/${profile.user.username}`;
    formData.championCardUrl = `https://portal.yourhealth.app/card/view/champion/${profile.user.username}`;
    formData.neighborCardUrl = `https://portal.yourhealth.app/card/view/neighbor/${profile.user.username}`;

    // Create mutation
    const mutation = await client.graphql({ query: createProfile, variables: { input: formData } });
    const newProfile = (mutation.data && mutation.data.createProfile) ? mutation.data.createProfile : null;
    console.log("New Profile: ", mutation);

    // Update profile user
    if (newProfile) {
      dispatch({
        type: UPDATE_PROFILE_USER,
        payload: newProfile
      });

      // Update profile user
      dispatch({
        type: UPDATE_WELCOME_STAGE,
        payload: {
          complete: false,
          stage: 'profile'
        }
      });

      // Update user object
      const user = await getAuthenticatedUser();
      await updateUserAttributes({
        'custom:profileId': newProfile.id
      });


      // Track sign-up (Axon - PostHog)
      if (window.posthog && process.env.REACT_APP_ENVIRONMENT === 'prod') {
        // Log initial profile creation
        const analytics = getAnalytics();
        logEvent(analytics, 'created_profile', {
          profileId: newProfile.id
        });

        // Track signup
        window.posthog.capture('Created Profile', {
          category: "Profile",
          $set: {
            email: profile.user.email,
          }
        });
      }

      // Update user mobile data
      if (newProfile.mobile) {
        YourHealth.api.email.update({
          email: profile.user.email,
          list: "all_members",
          attributes: {
            mobile: newProfile.mobile
          }
        });
      }
    } else {
      console.log("ERROR: Could not create new profile");
    }
  }

  // Update password
  async function handleUpdatePassword(passwordData, callback) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, passwordData.currentPassword, passwordData.newPassword1);
      })
      .then(data => callback(data, null))
      .catch(err => callback(null, err));
  }

  // Helper - pagination
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  // Helper - handle opt-in
  async function handleOptIn() {
    // Toggle state
    setOptIn(!optIn);
  }

  return (
    <>
      <SignUpHeader
        headline="Welcome!"
        message="Welcome to taking control of your health and wellness with YourHealth."
      />
      <Container id="profile" className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Your Account</h3>
                  </Col>
                  <Col xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email Address
                          </label>
                          <Input
                            id="input-email"
                            placeholder="name@example.com"
                            type="email"
                            value={formData.email}
                            onChange={e => setFormData({ ...formData, 'email': e.target.value })}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            id="input-phone"
                            className="form-control"
                            defaultCountry="US"
                            placeholder="512-877-1104"
                            value={formData.mobile}
                            onChange={mobile => {
                              setFormData({ ...formData, 'mobile': mobile })
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password-1"
                          >
                            New Password (Required)
                          </label>
                          <InputGroup>
                            <Input
                              id="input-new-password-1"
                              placeholder=""
                              type={showPassword ? "text" : "password"}
                              value={selectedData.newPassword1}
                              onChange={e => setSelectedData({ ...selectedData, 'newPassword1': e.target.value })}
                            />
                            <InputGroupAddon
                              addonType="append"
                              onClick={() => { setShowPassword(!showPassword); }}
                              style={{ cursor: 'pointer' }}
                            >
                              <InputGroupText>
                                {showPassword ?
                                  <i className="fas fa-eye-slash" />
                                  :
                                  <i className="fas fa-eye" />
                                }
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {passwordMessage &&
                            <div className="new-password-error" style={{
                              fontSize: '80%',
                              color: '#B03B70',
                              paddingLeft: 10
                            }}>
                              {passwordMessage}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password-2"
                          >
                            Verify New Password (Required)
                          </label>
                          <InputGroup>
                            <Input
                              id="input-new-password-2"
                              placeholder=""
                              type={showPassword ? "text" : "password"}
                              value={selectedData.newPassword2}
                              onChange={e => setSelectedData({ ...selectedData, 'newPassword2': e.target.value })}
                            />
                            <InputGroupAddon
                              addonType="append"
                              onClick={() => { setShowPassword(!showPassword); }}
                              style={{ cursor: 'pointer' }}
                            >
                              <InputGroupText>
                                {showPassword ?
                                  <i className="fas fa-eye-slash" />
                                  :
                                  <i className="fas fa-eye" />
                                }
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col md="4">

                    </Col>
                    <Col className="text-right" md="8">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          href=""
                          onClick={() => {
                            // Update contact
                            console.log("Updating password: ", selectedData);

                            // (1) Check if passwords match
                            if (selectedData.newPassword1 !== selectedData.newPassword2) {
                              // Set message
                              setPasswordMessage("Passwords must match.")

                              // break
                              return;
                            }

                            // (1) Check if password minimum length
                            if (selectedData.newPassword1.length < 8) {
                              // Set message
                              setPasswordMessage("Password must be at least 8 characters.")

                              // break
                              return;
                            }

                            // Update password
                            handleUpdatePassword(selectedData, function (success, error) {
                              if (success) {
                                // Finish setting up profile
                                console.log(" -- setting up profile");
                                saveProfile();

                              } else {
                                console.log(error);

                                // Set message
                                setPasswordMessage("An error has occured - please contact support@1yourhealth.com.");
                              }
                            });
                          }}
                          size="xl"
                          disabled={!optIn}
                        >
                          Setup Profile
                        </Button>
                      </div>
                      <div style={{
                        height: '100%',
                        float: 'right',
                        width: 300,
                        paddingRight: 20,
                        paddingTop: 5
                      }}>
                        <h6 className="mb-0" style={{
                          opacity: 0.5
                        }}><span style={{
                          position: 'relative',
                          right: 5,
                          top: 3
                        }}><input type="checkbox" checked={optIn} onClick={handleOptIn} /></span> I agree to YourHealth Data Corp.'s '<br /> <a href="" style={{ color: '#5B9AA4' }} onClick={(evt) => {
                          evt.preventDefault();

                          // Open TOS
                          window.open('https://go.yourhealth.app/TermsOfService');
                        }}>Terms of Service</a> and <a href="" style={{ color: '#5B9AA4' }} onClick={(evt) => {
                          evt.preventDefault();

                          // Open TOS
                          window.open('https://go.yourhealth.app/PrivacyPolicy');
                        }}>Privacy Policy</a>.</h6>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={passwordModal}
        toggle={() => setpasswordModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Update Password
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setpasswordModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-current-password"
                    >
                      Current Password
                    </label>
                    <Input
                      id="input-current-password"
                      placeholder=""
                      type="password"
                      value={selectedData.currentPassword}
                      onChange={e => setSelectedData({ ...selectedData, 'currentPassword': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-new-password-1"
                    >
                      New Password
                    </label>
                    <Input
                      id="input-new-password-1"
                      placeholder=""
                      type="password"
                      value={selectedData.newPassword1}
                      onChange={e => setSelectedData({ ...selectedData, 'newPassword1': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-new-password-2"
                    >
                      Verify New Password
                    </label>
                    <Input
                      id="input-new-password-2"
                      placeholder=""
                      type="password"
                      value={selectedData.newPassword2}
                      onChange={e => setSelectedData({ ...selectedData, 'newPassword2': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Update contact
              console.log("Updating password: ", selectedData);

              // Update password
              handleUpdatePassword(selectedData, function (success, error) {
                if (success) {
                  // Close modal
                  setpasswordModal(false);
                } else {
                  console.log(error);
                }
              })
            }}
            size="xl"
          >
            Update Password
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Terms of Service
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body text-center"
          style={{
            overflow: 'auto'
          }}
        >
          <Document
            file="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/YourHealth%20Terms%20of%20Service_05-05-22.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                width={750}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedSignUp = connect(state => ({
  profile: state.profile
}))(SignUp);

export default ConnectedSignUp;
