/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  CardText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// API (YourHealth)
import YourHealth from '../../api';

// React Phone Input
import PhoneInput from 'react-phone-number-input/input';

// core components
import ConsentHeader from "../../components/Headers/ConsentHeader.js";

// Amplify - API
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import { createConsent, updateContact } from '../../graphql/mutations';
import {
  LOAD_CONTACTS
} from '../../store/actions';

// Contacts Query
const getContactsQuery = /* GraphQL */ `
  query GetContacts($profileId: ID!) {
    getProfile(id: $profileId) {
      id
      contacts {
        items {
          id
          type
          email
          mobile
          firstName
          lastName
          isChampion
          hasConsent
          profileContactsId
        }
        nextToken
      }
    }
  }
`;

const initialFormState = {
  mobile: '',
  date: '',
  smsAllowed: true
};

const initialContactState = {
  id: '',
  type: 'NEIGHBOR',
  isChampion: false,
  hasConsent: false,
  email: '',
  mobile: '',
  firstName: '',
  lastName: '',
  profileContactsId: ''
}

function Consent({ profile, medical, dispatch }) {
  // Local state
  const [formData, setFormData] = useState(initialFormState);
  const [contactData, setContactData] = useState(initialContactState);

  // History
  const history = useHistory();

  // Get and sync profile ID
  const { id } = useParams();
  console.log("Profile ID: ", id);

  // Get contacts
  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  // Find contacts
  async function init() {
    console.log("Initializing Contacts: ", id);

    // Set format data
    setContactData({
      ...contactData,
      'profileContactsId': id
    });

    // Update contacts
    getContacts(id);
  }

  // Grant consent
  async function grantConsent() {
    if (!formData.mobile) return;

    // Copy data
    let newConsent = JSON.parse(JSON.stringify(formData));
    newConsent.date = new Date(Date.now()).toLocaleString();
    console.log("Granting Consent: ", newConsent);

    // Track event
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (window.posthog) {
        // Track signup
        window.posthog.capture('Contact Consent', {
          category: "Granted Consent",
          label: formData.mobile,
          $set: {
            email: profile.user.email,
          }
        });
      }
    }

    // Iterate over Contacts
    let targetContact = null;
    for (var i = 0; i < medical.contacts.length; i++) {
      // Check phone
      if (formData.mobile == medical.contacts[i].mobile) {
        console.log("Found contact!: ", medical.contacts[i]);

        // Set targe contact
        targetContact = medical.contacts[i];
      }
    }

    // Copy data
    if (targetContact) {
      let newContact = JSON.parse(JSON.stringify(targetContact));
      newContact.hasConsent = true;
      console.log(newContact);

      // Remove created at
      delete newContact.createdAt;

      await client.graphql({ query: updateContact, variables: { input: newContact } });
    }

    await client.graphql({ query: createConsent, variables: { input: newConsent } });

    // Send SMS
    YourHealth.api.ecc.confirmation({
      sender: {
        name: ""
      },
      recipient: {
        mobile: `${formData.mobile}`
      }
    });

    // Send to YourHealth homepage
    history.push('/join');
  }

  // Get Contacts
  async function getContacts(profileId) {
    // Create mutation
    const apiData = await client.graphql({
      query: getContactsQuery,
      variables: {
        profileId: profileId
      }
    });

    if (apiData.data.getProfile.contacts.items && apiData.data.getProfile.contacts.items.length > 0) {
      // Dispatch update
      dispatch({
        type: LOAD_CONTACTS,
        payload: apiData.data.getProfile.contacts.items
      });

    }
  }

  return (
    <>
      <ConsentHeader
        title="Contact Consent"
        description="Enter your mobile number below to consent to having a YourHealth member to be able to send their Essential Care Card. Thank you."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card id="ecc-notes">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="12">
                    <h3 className="mb-0">Grant Consent to Contact</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col lg="12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-phone"
                      >
                        Phone Number
                      </label>
                      <PhoneInput
                        id="input-phone"
                        className="form-control"
                        placeholder=""
                        defaultCountry="US"
                        value={formData.mobile}
                        onChange={mobile => {
                          setFormData({ ...formData, 'mobile': mobile })
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col lg="12">
                    <Button block color="primary" size="lg" type="button"
                      onClick={grantConsent}>
                      Grant Consent
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedConsent = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Consent);

export default ConnectedConsent;
