import React, { useEffect } from "react";
import { connect } from 'react-redux';
import {
  Card,
  Col,
  Container,
  Row,
  Table
} from "reactstrap";
import MedicalCardDetailsHeader from "../../../components/Headers/MedicalCardDetailsHeader";
import { LOAD_ALLERGIES } from '../../../store/actions';
import { useSupabase } from '../../../lib/useSupabase';
import { notifyError } from '../../../utils/notify';

function Allergies({ profile, medical, dispatch }) {
  const { supabaseInstance } = useSupabase()

  useEffect(() => {
    if (!profile.initialized || !supabaseInstance) return
    getAllergies(profile.user.id);
  }, [supabaseInstance, profile]);

  async function getAllergies(profileId) {
    let { data, error } = await supabaseInstance
      .from('allergy')
      .select('*')
      .eq('profile_id', profileId);

    if (error) {
      console.error("Error getting allergies: ", error)
      notifyError("Error getting allergies")
      return
    }

    dispatch({
      type: LOAD_ALLERGIES,
      payload: data
    })
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="order-xl-1 px-0 py-2">
            <Card className="mt--6 pb-2">
              <MedicalCardDetailsHeader title="Allergies" />
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {medical.allergies.map((allergy, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <span className="font-weight-bold">
                          {allergy.name}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {allergy.description}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {allergy.notes}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const ConnectedAllergies = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Allergies)

export default ConnectedAllergies
