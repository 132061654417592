import React from "react";
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from './Loading';
import Create from './create';
import Home from './Home';

function Profile({ profile, dispatch }) {
  profile.welcome.complete = true
  profile.complete = true
  let location = useLocation();

  // Trigger getting started
  const searchParams = new URLSearchParams(location.search);
  const tutorial = searchParams.get("tutorial");

  // Wait for initialization
  if(profile.initialized) {
    // Check if welcome complete
    if(profile.welcome.complete) {
      return <Home displayTutorial={tutorial} />;
    } else {
      return <Create />;
    }
  } else {
    return <Loading />;
  }
}

const ConnectedProfile = connect(state => ({
  profile: state.profile
}))(Profile);

export default ConnectedProfile;
