/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// API (YourHealth)
import YourHealth from '../../api';

// PWA Install Prompt
import usePWA from 'react-pwa-install-prompt';
import { AddToHomeScreen } from 'react-pwa-add-to-homescreen';

function InstallHeader({ profile, title, description }) {
  // Grab history
  const history = useHistory();

  // PWA Install
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();

  // PWA Install
  const handleInstall = async () => {
    const didInstall = await promptInstall()
    if (didInstall) {
      // User accepted PWA install
    }
  }

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage: 'url("/assets/img/profile/hero-1.png")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="align-items-center" fluid>
          <Row className="text-center">
            <Col lg="12">
              <h1 className="display-2 text-white">Mobile Install</h1>
              <p className="text-white mt-0 mb-5">Now you can access YourHealth from your mobile phone!</p>

              <Button
                className="mt-4"
                color="warning"
                onClick={handleInstall}
              >
                Add to My Homescreen
              </Button>
              <AddToHomeScreen />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default InstallHeader;
