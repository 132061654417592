import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import MedicalAdminHeader from '../../../components/Headers/MedicalAdminHeader'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getHealthcareVisit } from '../../../supabase-api/healthcare_visits'
import { useState } from 'react'

export default function HealthcareVisitsStarterDetails() {
  const [aiPromptTitle, setAiPromptTitle] = useState<string>()
  const [aiResponse, setAiResponse] = useState<string>()
  const [isPrompting, setIsPrompting] = useState<boolean>(false)

  const { id: healthcareVisitId } = useParams<{ id: string }>()

  const { data: healthcareVisit } = useQuery({
    queryKey: ['healthcare_visits', healthcareVisitId],
    queryFn: () => getHealthcareVisit(healthcareVisitId),
  })

  if (!healthcareVisit) {
    return <div>Loading...</div>
  }

  async function getHealthRecommendations() {
    console.log('Getting health recommendations...')
  }

  return (
    <>
      <MedicalAdminHeader name="Healthcare Visits" parentName="Starter" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <div className="d-flex align-items-center">
                      <Link to="/healthcare-visits/healthcare-starter">
                        <Button
                          color="secondary"
                          style={{
                            fontSize: '.75rem',
                            marginRight: '1.5rem'
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <svg className="mr-2" style={{ width: 10, height: 10 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                            </svg>
                            Back
                          </div>
                        </Button>
                      </Link>
                      <h1 className="mb-0">{healthcareVisit.name}</h1>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <pre>
                  {JSON.stringify(healthcareVisit, null, 2)}
                </pre>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader className="mb-0 pb-2">
                <h3>YourHealth Life Insights</h3>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="d-flex flex-wrap">
                      <Button color="primary" className="mb-2" onClick={getHealthRecommendations}>Are there things I can be doing to improve my health?</Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" className="p-3">
                    <div className="p-0 p-lg-4 rounded" style={{ backgroundColor: '#fcfcfc' }}>
                      {aiPromptTitle && !isPrompting && <h3 className="mb-4">{aiPromptTitle}</h3>}
                      {isPrompting &&
                        <div className="w-100 d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border mr-2"
                            role="status"
                            style={{
                              width: 20,
                              height: 20,
                            }}
                          />
                          <span>Loading...</span>
                        </div>
                      }
                      {!isPrompting && <div
                        style={{ maxWidth: '64ch' }}
                        dangerouslySetInnerHTML={{ __html: aiResponse || "" }}
                      />}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}