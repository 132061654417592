import { supabase } from '../../../lib/supabase'

type CardType = 'champion' | 'medical' | 'neighbor'

export async function getCardUrl(profileId: string, cardType: CardType) {
  const { data: cardTypeData, error: cardTypeError } = await supabase
    .from('card_type')
    .select('*')
    .eq('name', cardType)
    .single()

  if (cardTypeError) {
    console.error('error getting champion card type', cardTypeError)
    return
  }
  if (!cardTypeData) return
  
  const { data: shareKeyData, error: cardError } = await supabase
    .from('card')
    .select('share_key')
    .eq('profile_id', profileId)
    .eq('card_type_id', cardTypeData.id)
    .eq('is_active', true)
    .order('created_at', { ascending: false })
    .single()

  if (cardError) {
    console.error('error getting champion card', cardError)
    return
  }

  if (!shareKeyData?.share_key) {
    console.error('no share key found')
    return
  }

  const cardUrl = `${process.env.REACT_APP_BASE_URL}/card/view/${cardType.toLowerCase()}/${shareKeyData.share_key}`
  return cardUrl
}
