import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { pdfjs, Document, Page } from 'react-pdf';
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";
import PhoneInput from 'react-phone-number-input/input';
import { getAuthenticatedUser } from '../../../common/auth';
import YourHealth from '../../../api';
import { INITIALIZE_PROFILE } from '../../../store/actions';
import ProfileHeader from "../../../components/Headers/ProfileHeader.js";
import { supabase } from '../../../lib/supabase';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const initialFormState = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  street_1: '',
  street_2: '',
  city: '',
  state: '',
  zip_code: ''
}

const initialSelectedState = {
  currentPassword: '',
  newPassword1: '',
  newPassword2: ''
}

const onboarding = [
  {
    target: '#my-emergency-care-cards',
    content: `Below you see three Care Cards – Medical, Care Champion, Neighbor. These cards can hold important information and directions for your care, health and wellness. By clicking on the cards, you can easily fill in the information that you want.`,
    placement: 'top'
  },
  {
    target: '#ecc-medical',
    content: `First is your medical information, you can list chronic conditions, prescriptions, allergies, vaccinations, and over the counter drugs and supplements. These are all critic facts that you want a doctor to know. There is also a free form notes section where you can type in other personal information, for example “I have a metal in my hip” These are core elements of the Essential Care Card.`,
    placement: 'bottom'
  },
  {
    target: '#ecc-champion',
    content: `Second is your Care Champion. Here is the place where you not only designate the person that you trust to make sure your wishes and needs are followed in case of medical emergency or crisis, but you can also store or link important information such as a Do Not Resuscitate (DNI) or your Living Will. Your Care Champion will also work closely with the next individual, your Neighbor.`,
    placement: 'bottom'
  },
  {
    target: '#ecc-neighbor',
    content: `Third is your Neighbor. If you are unable, this is the person who can collect your mail, water your plants, take care of your pets.`,
    placement: 'bottom'
  },
  {
    target: '#my-emergency-care-cards',
    content: `For each of the Care Circle members there is a specific QR code. Each QR code can be sent or printed, authoring access to the information pertaining to that member of the Care Circle. For the Medical QR code, we strongly encourage you to print a bunch of them out. You can put them in your wallet, your purse, your kitchen, the glove box in your car, even on the back of your mobile phone. This way critical, lifesaving information is immediately available to a medical professional even when you can’t explain everything.`,
    placement: 'right'
  },
  {
    target: '#my-medical-information',
    content: `After filling out the Care Cards, return to the My Profile and start entering the My Medical Information that you see below. By clicking on each section you will directed to that element to fill in. Remember you can add, change or delete anything that you enter any time.`,
    placement: 'top'
  },
  {
    target: '#my-resources',
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    placement: 'left'
  },
];

function ClipboardCopy({ profile }) {
  const [isCopied, setIsCopied] = useState(false);
  const [consentUrl, setConsentUrl] = useState('');
  const consentLink = `https://portal.yourhealth.app/consent/contact/${profile.user.id}`;
  const copyText = `Hi! I would like to be able to send you my personalized YourHealth QR code via SMS. Please grant your consent by visiting: ${consentLink}`;

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = async () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });

    // // Set link options
    // const options = {
    //   method: 'POST',
    //   headers: {
    //     accept: 'application/json',
    //     'content-type': 'application/json',
    //     Authorization: 'sk_BdOFgdrBUdS5rMIm'
    //   },
    //   mode: 'no-cors',
    //   body: JSON.stringify({
    //     domain: 'go.yourhealth.app',
    //     allowDuplicates: false,
    //     originalURL: `https://portal.yourhealth.app/consent/contact/${profile.user.id}`,
    //     title: 'YourHealth Contact Consent'
    //   })
    // };

    // // Create short link
    // await fetch('https://api.short.io/links', options)
    //   .then(response => response.json())
    //   .then(data => {
    //     // Set url
    //     setConsentUrl(data.shortURL);
    //
    //     // Asynchronously call copyTextToClipboard
    //     copyTextToClipboard(`Hi! I would like to be able to send you my personalized YourHealth QR code via SMS. Please grant your consent by visiting: ${data.shortURL}`)
    //       .then(() => {
    //         // If successful, update the isCopied state value
    //         setIsCopied(true);
    //         setTimeout(() => {
    //           setIsCopied(false);
    //         }, 1500);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   })
    //   .catch(err => console.error(err));
  }

  return (
    <Row className="mb-3">
      <Col lg="12">
        <Input
          type="textarea"
          resize="none"
          rows={profile.isMobile ? 9 : 3}
          value={copyText}
          readOnly
          style={{
            paddingRight: 50
          }}
        ></Input>
        {/* Bind our handler function to the onClick button property */}
        <Button color="primary" onClick={handleCopyClick} style={{
          position: 'absolute',
          top: 2,
          right: 17
        }}>
          <span>{isCopied ? 'Copied!' : 'Copy'}</span>
        </Button>
      </Col>
    </Row>
  );
}

function Home({ displayTutorial, profile, dispatch }) {
  const [eccModal, setECCModal] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(null);

  // Local states
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [profileModal, setprofileModal] = React.useState(false);
  const [pdfModal, setPDFModal] = React.useState(null);
  const [formData, setFormData] = useState(initialFormState);

  // Frequently Asked Questions (Guide)
  const [faqModal, setFaqModal] = React.useState(false);

  // Add to Device (Guide)
  const [addToDeviceModal, setAddToDeviceModal] = React.useState(false);

  // Send QR Code (Guide)
  const [sendQRCodeModal, setSendQRCodeModal] = React.useState(false);

  // Tutorials
  const [onboardingSteps, setOnboardingSteps] = useState(onboarding);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [numPages, setNumPages] = useState(null);
  const [tutorial, setTutorial] = useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const printCard = () => {
    const printWindow = window.open(pdfUrl, 'ecc-print');
    setECCModal(false);
    setTimeout(() => {
      printWindow.print();
    }, 800)
  }
  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    setFormData({
      first_name: profile.user.first_name,
      last_name: profile.user.last_name,
      email: profile.user.email,
      phone: profile.user.phone,
      street_1: profile.user.street_1,
      street_2: profile.user.street_2,
      city: profile.user.city,
      state: profile.user.state,
      zip_code: profile.user.zip_code
    });
  }

  useEffect(() => {
    if (displayTutorial) {
      showTutorial(displayTutorial);
    }
  }, [displayTutorial]);

  async function showTutorial(tutorial) {
    // If tutorial
    // console.log("Show Tutorial: ", tutorial);

    if (tutorial.indexOf("getting-started") >= 0) {
      setPDFModal(true);
    }

    if (tutorial.indexOf("frequently-asked-questions") >= 0) {
      setFaqModal(true);
    }

    if (tutorial.indexOf("add-to-device") >= 0) {
      setAddToDeviceModal(true);
    }

    if (tutorial.indexOf("send-qr-code") >= 0) {
      setSendQRCodeModal(true);
    }
  }

  async function saveProfile() {
    let updatedProfile = profile.user;
    updatedProfile.first_name = formData.first_name;
    updatedProfile.last_name = formData.last_name;
    updatedProfile.phone = formData.phone;
    updatedProfile.street_1 = formData.street_1;
    updatedProfile.street_2 = formData.street_2;
    updatedProfile.city = formData.city;
    updatedProfile.state = formData.state;
    updatedProfile.zip_code = formData.zip_code;

    const { data, error } = await supabase
      .from('profile')
      .update({
        first_name: formData.first_name,
        last_name: formData.last_name,
        street_1: formData.street_1,
        street_2: formData.street_2,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zip_code,
        phone: formData.phone,
      })
      .eq('id', profile.user.id)

    if (error) {
      console.error('Error updating profile:', error)
    }

    notify("success", "Your profile was successfully updated!");

    dispatch({
      type: INITIALIZE_PROFILE,
      payload: updatedProfile
    });
  }

  // Complete tutorial
  async function completeTutorial() {
    // Modify profile
    let updatedProfile = profile.user;
    updatedProfile.eccSetup = true;

    // Create new contact
    await client.graphql({ query: updateProfile, variables: { input: updatedProfile } });

    // Sync new profile
    dispatch({
      type: INITIALIZE_PROFILE,
      payload: updatedProfile
    });
  }

  // Update password
  async function handleUpdatePassword(passwordData, callback) {
    getAuthenticatedUser()
      .then(user => {
        return updatePassword({
          oldPassword: passwordData.currentPassword,
          newPassword: passwordData.newPassword1
        });
      })
      .then(data => callback(data, null))
      .catch(err => callback(null, err));
  }

  // Notification
  const [alert, setalert] = React.useState(false);
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Profile Updated
          </span>
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-check-bold",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  // Helper - pagination
  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  // Helper - step callback
  function tutorialStep(data) {
    // console.log(data);
    if (data.action == "update" && data.lifecycle == "tooltip") {
      // Hide popover
      setPopoverOpen(false);
    }
  }

  return (
    <>
      {alert}
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <ProfileHeader setPDFModal={setPDFModal} />
      <Container className="mt--6" fluid>
        {!profile.isMobile ?
          <Row>
            <Col className="order-xl-2" xl="3">
              <Card
                id="profile-summary"
                className="border-0"
                style={{ backgroundColor: "#B03B70", position: 'relative', top: -300 }}
              >
                <CardBody>
                  <div className="col mb-5">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h1"
                    >
                      {(profile.initialized && profile.user.first_name && profile.user.last_name) ? `${profile.user.first_name} ${profile.user.last_name}` : ''}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-2 text-white">
                      Your Free Essential Plan
                    </span>
                  </div>
                  <div className="col mb-5 text-white">
                    <p><span className="h3 font-weight-bold mb-1 text-white">Here’s everything you get in your Free YourHealth Essential™ subscription.</span></p>
                    <p>YourHealth Essential Care Card™ (ECC) – the heart of your personalized health system with the ability to document your medical information:</p>
                  </div>
                  <div className="col mb-4">
                    <Button block color="primary" size="lg" type="button"
                      onClick={() => history.push('/admin/account')}>
                      Setup Personal Info
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/contacts')}>
                      Contacts
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/conditions')}>
                      Medical Conditions
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/prescriptions')}>
                      Prescriptions
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/otc')}>
                      Over-the-Counter
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/allergies')}>
                      Allergies
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/immunizations')}>
                      Immunizations
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/cards/champion')}>
                      Care Notes
                    </Button>
                  </div>
                  <div className="col mb-2 text-white">
                    <p>Your ECC is instantly available through your medical QR Code whether it is on your phone, printed and in your wallet, purse, car or home. First responders and medical staff can access your ECC through the medical QR Code.</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="9">
              <Card id="my-emergency-care-cards">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My Essential Care Card</h3>
                    </Col>
                    <Col className="text-right" xs="4">

                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <Card className="ml-3 mr-3">
                        <CardBody>
                          <CardText className="mb-4">
                            The YourHealth Essential Care Card™ (ECC) is your comprehensive digital form to manage your health and wellness information so you are prepared before an emergency happens.
                          </CardText>
                          <Row>
                            <Col xs="12">
                              <Button
                                className="mb-4"
                                color="warning"
                                onClick={() => {
                                  // View ECC
                                  YourHealth.api.ecc.view(profile, function (pdfUrl) {
                                    // Set PDF URL
                                    setPdfUrl(pdfUrl);

                                    // Show Modal
                                    setECCModal(true);
                                  });
                                }}
                                block
                              >
                                Print Your Essential Care Card
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col xs="12">
                          <CardImg
                            alt="..."
                            src="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/v8/ecc-template-blank.jpg"
                          />
                          <h3 className="text-center mt-2">Use the buttons on the right to fill out your Essential Care Card.</h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="my-individual-care-cards">
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My Individual Care Cards and Personal QR Codes</h3>
                    </Col>
                    <Col className="text-right" xs="4">

                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Card className="ml-3 mr-3">
                      <CardBody>
                        <div className="mb-4 card-text">
                          <p>YourHealth Care Circle™ – Trusted people to care for you in an emergency and give them access to your specific information instantly with QR Codes.</p>
                          <ul>
                            <li><span style={{ fontWeight: 'bold' }}>Doctor</span> – List your primary care doctor that you wanted contacted in an emergency.</li>
                            <li><span style={{ fontWeight: 'bold' }}>Care Champion</span> – The person you turn to in an emergency to manage your critical care needs.</li>
                            <li><span style={{ fontWeight: 'bold' }}>Neighbor</span> – Someone who can handle things on the home front to keep day to day activities running.</li>
                          </ul>
                        </div>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row>
                    <Col xl="4">
                      <Link to="/admin/cards/medical">
                        <Card
                          id="ecc-medical"
                          className=""
                          style={{ cursor: 'pointer', backgroundColor: "#CB2B2F", border: '0.5px solid #7F8180', boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)' }}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  className="text-uppercase text-muted mb-0 text-white"
                                  tag="h2"
                                >
                                  Medical
                                </CardTitle>
                                <span className="h4 font-weight-bold mb-0 text-white">
                                  Care Card
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    <Col xl="4">
                      <Link to="/admin/cards/champion">
                        <Card
                          id="ecc-champion"
                          className=""
                          style={{ cursor: 'pointer', backgroundColor: "#3F97D6", border: '0.5px solid #7F8180', boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)' }}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  className="text-uppercase text-muted mb-0 text-white"
                                  tag="h2"
                                >
                                  Care Champion
                                </CardTitle>
                                <span className="h4 font-weight-bold mb-0 text-white">
                                  Care Card
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    <Col xl="4">
                      <Link to="/admin/cards/neighbor">
                        <Card
                          id="ecc-neighbor"
                          className=""
                          style={{ cursor: 'pointer', backgroundColor: "#F6BB41", border: '0.5px solid #7F8180', boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)' }}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle
                                  className="text-uppercase text-muted mb-0 text-white"
                                  tag="h2"
                                >
                                  Neighbor
                                </CardTitle>
                                <span className="h4 font-weight-bold mb-0 text-white">
                                  Care Card
                                </span>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="my-resources">
                <CardHeader>
                  <Row>
                    <Col xs="12">
                      <h3 className="mb-0">Additional Resources</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="4">
                      <Button
                        block color="white" size="lg" type="button"
                        style={{
                          text: 'black',
                          borderColor: '#525f7f'
                        }}
                        onClick={() => history.push(`/admin/home?tutorial=getting-started-${Date.now()}`)}>
                        View the Getting Started Guide
                      </Button>
                    </Col>
                    <Col xs="4">
                      <Button
                        block color="white" size="lg" type="button"
                        style={{
                          text: 'black',
                          borderColor: '#525f7f'
                        }}
                        onClick={() => history.push(`/admin/home?tutorial=add-to-device-${Date.now()}`)}>
                        Add the Application to My Device
                      </Button>
                    </Col>
                    <Col xs="4">
                      <Button
                        block color="white" size="lg" type="button"
                        style={{
                          text: 'black',
                          borderColor: '#525f7f'
                        }}
                        onClick={() => history.push('/admin/resources/links')}>
                        Store Links for Your Important Websites
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          :
          <Row>
            <Col xs="12">
              <Card
                id="profile-summary"
                className="border-0"
                style={{ backgroundColor: "#B03B70", position: 'relative', top: -100 }}
              >
                <CardBody>
                  <div className="col mb-5">
                    <CardTitle
                      className="text-uppercase text-muted mb-0 text-white"
                      tag="h1"
                    >
                      {(profile.initialized) ? `${profile.user.first_name} ${profile.user.last_name}` : ''}
                    </CardTitle>
                    <span className="h2 font-weight-bold mb-2 text-white">
                      Your Free Essential Plan
                    </span>
                  </div>
                  <div className="col mb-5 text-white">
                    <p><span className="h3 font-weight-bold mb-1 text-white">Here’s everything you get in your Free YourHealth Essential™ subscription.</span></p>
                    <p>YourHealth Essential Care Card™ (ECC) – the heart of your personalized health system with the ability to document your medical information:</p>
                  </div>
                  <div className="col mb-4">
                    <Button block color="primary" size="lg" type="button"
                      onClick={() => history.push('/admin/account')}>
                      Setup Personal Info
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/contacts')}>
                      Contacts
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/conditions')}>
                      Medical Conditions
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/prescriptions')}>
                      Prescriptions
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/otc')}>
                      Over-the-Counter
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/allergies')}>
                      Allergies
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/medical/immunizations')}>
                      Immunizations
                    </Button>
                    <br />
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => history.push('/admin/cards/champion')}>
                      Care Notes
                    </Button>
                  </div>
                  <div className="col mb-2 text-white">
                    <p>Your ECC is instantly available through your medical QR Code whether it is on your phone, printed and in your wallet, purse, car or home. First responders and medical staff can access your ECC through the medical QR Code.</p>
                  </div>
                </CardBody>
              </Card>
              <Card
                id="my-emergency-care-cards"
                style={{ position: 'relative', top: -100 }}
              >
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">My Emergency Card Card</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <Card className="ml-3 mr-3">
                        <CardBody>
                          <CardText className="mb-4">
                            The YourHealth Essential Care Card™ (ECC) is your comprehensive digital form to manage your health and wellness information so you are prepared before an emergency happens.
                          </CardText>
                        </CardBody>
                      </Card>
                      <Row>
                        <Col xs="12">
                          <Button
                            className="mb-4"
                            color="warning"
                            onClick={() => {
                              // View ECC
                              YourHealth.api.ecc.view(profile, function (pdfUrl) {
                                // Set PDF URL
                                setPdfUrl(pdfUrl);

                                // Show Modal
                                setECCModal(true);
                              });
                            }}
                            block
                          >
                            Print Your Essential Care Card
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card
                id="my-individual-care-cards"
                style={{ position: 'relative', top: -100 }}
              >
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="12">
                      <h3 className="mb-0">My Individual Care Cards and Personal QR Codes</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Card className="ml-3 mr-3">
                      <CardBody>
                        <CardText className="mt-1">
                          These are you your Essential Care Cards. You can add key people and notes by clicking on each card.
                        </CardText>
                      </CardBody>
                    </Card>
                  </Row>
                  <Row>
                    <Col xl="4">
                      <Link to="/admin/cards/medical">
                        <Card
                          id="ecc-medical"
                          className=""
                          style={{ cursor: 'pointer', backgroundColor: "#CB2B2F", border: '0.5px solid #7F8180', boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)' }}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <span className="h2 font-weight-bold mb-0 text-white">
                                  Medical
                                </span>
                                <CardTitle
                                  className="text-uppercase text-muted mb-0 text-white"
                                  tag="h5"
                                >
                                  Care Card
                                </CardTitle>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    <Col xl="4">
                      <Link to="/admin/cards/champion">
                        <Card
                          id="ecc-champion"
                          className=""
                          style={{ cursor: 'pointer', backgroundColor: "#3F97D6", border: '0.5px solid #7F8180', boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)' }}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <span className="h2 font-weight-bold mb-0 text-white">
                                  Care Champion
                                </span>
                                <CardTitle
                                  className="text-uppercase text-muted mb-0 text-white"
                                  tag="h5"
                                >
                                  Care Card
                                </CardTitle>

                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                    <Col xl="4">
                      <Link to="/admin/cards/neighbor">
                        <Card
                          id="ecc-neighbor"
                          className=""
                          style={{ cursor: 'pointer', backgroundColor: "#F6BB41", border: '0.5px solid #7F8180', boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)' }}
                        >
                          <CardBody>
                            <Row>
                              <div className="col">
                                <span className="h2 font-weight-bold mb-0 text-white">
                                  Neighbor
                                </span>
                                <CardTitle
                                  className="text-uppercase text-muted mb-0 text-white"
                                  tag="h5"
                                >
                                  Care Card
                                </CardTitle>
                              </div>
                            </Row>
                          </CardBody>
                        </Card>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card
                id="my-resources"
                style={{ top: -100 }}
              >
                <CardHeader>
                  <Row>
                    <Col xs="12">
                      <h3 className="mb-0">Additional Resources</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Button
                    block color="white" size="lg" type="button"
                    style={{
                      text: 'black',
                      borderColor: '#525f7f'
                    }}
                    onClick={() => history.push(`/admin/home?tutorial=getting-started-${Date.now()}`)}>
                    View the Getting Started Guide
                  </Button>
                  <br />
                  <Button
                    block color="white" size="lg" type="button"
                    style={{
                      text: 'black',
                      borderColor: '#525f7f'
                    }}
                    onClick={() => history.push(`/admin/home?tutorial=add-to-device-${Date.now()}`)}>
                    Add the Application to My Device
                  </Button>
                  <br />
                  <Button
                    block color="white" size="lg" type="button"
                    style={{
                      text: 'black',
                      borderColor: '#525f7f'
                    }}
                    onClick={() => history.push('/admin/resources/links')}>
                    Store Links for Your Important Websites
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={eccModal}
        toggle={() => setECCModal(false)}
        size="lg"
        style={(profile.isMobile) ?
          { height: '100%', width: '100%', maxWidth: '100%', maxHeight: '100%', marginTop: 0, marginLeft: 0 }
          :
          {}
        }
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Your Essential Care Card
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setECCModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div
          className="modal-body text-center"
          style={(profile.isMobile) ?
            { height: 'calc(100vh - 159px)', overflow: 'auto' }
            :
            { overflow: 'auto' }
          }
        >
          <Document file={pdfUrl}>
            <Page
              width={700}
              pageNumber={1}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto'
              }}
            />
          </Document>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={printCard}
            size="xl"
          >
            Print
          </Button>
        </div>
      </Modal>

      {/* The only code that opens this modal is uncallable as well, as it resides in a modal that has no path to being opened. */}
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Update Password
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-current-password"
                    >
                      Current Password
                    </label>
                    <Input
                      id="input-current-password"
                      placeholder=""
                      type="password"
                      value={selectedData.currentPassword}
                      onChange={e => setSelectedData({ ...selectedData, 'currentPassword': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-new-password-1"
                    >
                      New Password
                    </label>
                    <Input
                      id="input-new-password-1"
                      placeholder=""
                      type="password"
                      value={selectedData.newPassword1}
                      onChange={e => setSelectedData({ ...selectedData, 'newPassword1': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-new-password-2"
                    >
                      Verify New Password
                    </label>
                    <Input
                      id="input-new-password-2"
                      placeholder=""
                      type="password"
                      value={selectedData.newPassword2}
                      onChange={e => setSelectedData({ ...selectedData, 'newPassword2': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Update contact
              // console.log("Updating password: ", selectedData);

              // Update password
              handleUpdatePassword(selectedData, function (success, error) {
                if (success) {
                  // Notification
                  notify("success", "Your password was successfully updated!");

                  // Close modal
                  setdefaultModal(false);
                } else {
                  console.error(error);
                }
              })
            }}
            size="xl"
          >
            Update Password
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={pdfModal}
        toggle={() => setPDFModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Getting Started Guide
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setPDFModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body text-center"
          style={{
            overflow: 'auto'
          }}
        >
          <Document
            file="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/Getting_Started_10-09-23-a.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                width={700}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            href=""
            onClick={() => {
              // Print Guide
              YourHealth.api.tutorial.print(profile);
            }}
            size="xl"
          >
            Print this Guide
          </Button>
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Complete tutorial
              if (profile.initialized && !profile.user.eccSetup) {
                completeTutorial();
              }

              // Start tutorial
              setPopoverOpen(true);
              setTutorial(true);

              // Close modal
              setPDFModal(false);
            }}
            size="xl"
          >
            View My Essential Care Cards
          </Button>
        </div>
      </Modal>

      {/* no code is ever called that opens this modal */}
      <Modal
        className="modal-dialog-centered"
        isOpen={profileModal}
        toggle={() => setprofileModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Update Your Profile
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setprofileModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Email
                    </label>
                    <Input
                      id="input-email"
                      placeholder=""
                      type="email"
                      value={formData.email}
                      onChange={e => setFormData({ ...formData, 'email': e.target.value })}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Phone
                    </label>
                    <PhoneInput
                      className="form-control"
                      placeholder=""
                      defaultCountry="US"
                      value={formData.phone}
                      onChange={phone => {
                        setFormData({ ...formData, phone })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="5">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      First Name
                    </label>
                    <Input
                      id="input-first-name"
                      placeholder=""
                      type="text"
                      value={formData.first_name}
                      onChange={e => setFormData({ ...formData, first_name: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="5">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Last Name
                    </label>
                    <Input
                      id="input-last-name"
                      placeholder=""
                      type="text"
                      value={formData.last_name}
                      onChange={e => setFormData({ ...formData, last_name: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="2">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-zip-code"
                    >
                      Zip Code
                    </label>
                    <Input
                      id="input-zip-code"
                      placeholder=""
                      type="text"
                      value={formData.zip_code}
                      onChange={e => setFormData({ ...formData, zip_code: e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <hr className="my-4" />
            <Row>
              <Col xs="6">
                <div style={{
                  height: '100%',
                  float: 'left'
                }}>
                  <Button
                    color="secondary"
                    href=""
                    onClick={(e) => {
                      // Stop default behavior
                      e.preventDefault();

                      // Close profile
                      setprofileModal(false);

                      // Open Modal
                      setdefaultModal(true);
                    }}
                    size="xl"
                  >
                    Update Password
                  </Button>
                </div>
              </Col>
              <Col className="text-right" xs="6">
                <div style={{
                  height: '100%',
                  float: 'right'
                }}>
                  <Button
                    color="success"
                    href=""
                    onClick={saveProfile}
                    size="xl"
                  >
                    Save Profile
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
      {/* The code that opens this modal is uncallable, as it resides in another modal that has no path in the code to being opened. */}
      <Modal
        className="modal-dialog-centered"
        isOpen={faqModal}
        toggle={() => setFaqModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Frequently Asked Questions
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setFaqModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body text-center"
          style={{
            overflow: 'auto'
          }}
        >
          <Document
            file="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/faq/v3/yourhealth.faq.pdf"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                width={700}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
        <div className="modal-footer">
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={addToDeviceModal}
        toggle={() => setAddToDeviceModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Adding YourHealth to Your Mobile Phone or Laptop
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAddToDeviceModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="col">
            <p>You can add the YourHealth app to the home screen of your mobile phone or on your computer.</p>
            <br />
            <h3>iOS and Safari</h3>
            <p>You must use Safari.</p>
            <ol>
              <li>Go to the website (type in portal.yourhealth.app).</li>
              <li>At the bottom of your screen tap on <span><img src="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/icons/ios-save-1.png" style={{ height: 30 }} /></span></li>
              <li>Scroll down to Add to Home Screen</li>
              <li>Tap on that icon <span><img src="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/icons/ios-save-2.png" style={{ height: 30 }} /></span></li>
            </ol>
            <br />
            <h3>Android and Chrome</h3>
            <p>You must use Chrome.</p>
            <ol>
              <li>Go to the website (type in portal.yourhealth.app).</li>
              <li>Click on the three vertical dots at the upper right corner.</li>
              <li>Scroll down.</li>
              <li>Click on Add to Home screen.</li>
              <li>In the pop up, click add.</li>
            </ol>
          </div>
        </div>
        <div className="modal-footer">

        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={sendQRCodeModal}
        toggle={() => setSendQRCodeModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Steps to take before sending your QR code via SMS
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setSendQRCodeModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="col">
            <p>To be able to send your QR code to someone in your Care Circle, you must follow these easy steps:</p>
            <br />
            <ol>
              <li>
                <p>From your mobile phone, send a text message to the person in your Care Circle with the following message that you can copy and paste.</p>
                <ClipboardCopy profile={profile} />
              </li>
              <li>When they respond to the message, YourHealth will automatically update your contact section.</li>
            </ol>
            <p>Now when you go to send a QR code you will see those Care Circle contacts that have responded and agreed to receive your message.</p>
            <p>If you need to know more, you can go to the section in your <a href="/admin/home?tutorial=getting-started">Getting Started Guide</a> or email us at <a href="mailto:support@1yourhealth.com?subject=Help%20Sending%20QR%20via%20SMS">support@1yourhealth.com</a></p>
          </div>
        </div>
        <div className="modal-footer">

        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedHome = connect(state => ({
  profile: state.profile
}))(Home);

export default ConnectedHome;
