import React, { useState } from 'react'
import { set, useForm } from 'react-hook-form'
import { supabase } from '../../lib/supabase'
import { useHistory } from 'react-router'

const REDIRECT_URL = process.env.REACT_APP_BASE_URL ?? ''

export default function RequestPasswordReset() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const history = useHistory()

  type RequestPasswordResetFormFields = {
    email: string
  }
  const { register, handleSubmit } = useForm<RequestPasswordResetFormFields>()

  const onSubmit = async ({ email }: RequestPasswordResetFormFields) => {
    setErrorMessage(null)
    setSuccessMessage(null)

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${REDIRECT_URL}/auth/update-password`,
    })

    if (error) {
      console.error('reset password for email error:', error)
      setErrorMessage(error.message)
      return
    }

    const { data: passwordResetRequestTableData, error: passwordResetReqeustTableError } = await supabase
      .from('password_reset_request')
      .insert({ email })

    if (passwordResetReqeustTableError) {
      console.error('error inserting request.', error)
    }

    setSuccessMessage('Password reset link sent. Please check your email inbox.')
  }

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100 h-100 px-3 pb-8"
      style={{
        paddingTop: 100,
      }}
    >
      <div>
        <img
          src="/logo.white.png"
          alt="YourHealth Logo"
          style={{
            width: 300,
            marginBottom: '2rem',
          }}
        />
      </div>
      <div
        className="p-4 shadow-lg bg-white"
        style={{
          backgroundColor: '#181A1B',
          width: 480,
          maxWidth: '100%',
          borderRadius: 6,
        }}
      >
        <h1>Reset your password</h1>
        <p className="text-sm">Enter your email address below to request a password reset link.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column mb-3">
            {/* <label
              htmlFor="email"
              className="mb-1"
            >
              Email
            </label> */}
            <input
              {...register("email")}
              className="form-input"
              type="email"
              placeholder="Your email address"
              id="email"
              style={{
                borderRadius: 4,
                border: '1px solid rgb(131, 140, 149)',
              }}
            />
          </div>
          <div className="d-flex flex-column mt-4">
            <button
              type="submit"
              className="btn mb-3"
              style={{
                backgroundColor: '#047D95',
                color: '#fff',
                border: 'none',
              }}
            >
              Request Password Reset Link
            </button>

            <button
              type="button"
              className="form-button my-1 text-sm"
              onClick={() => history.push('/auth/login')}
            >
              Back to Sign in
            </button>
          </div>
        </form>
      </div>
      {errorMessage && (
        <div className="alert alert-danger mt-4" role="alert">
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="alert alert-success mt-4" role="alert">
          {successMessage}
        </div>
      )}
    </div>
  )
}
