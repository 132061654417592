import React, { useState } from "react"
import { Builder } from '@builder.io/react'
import * as EmailValidator from 'email-validator'
import { useHistory } from 'react-router-dom'
import './SignUp.css'

function SignUp(props: { display: any }) {
  const {
    display
  } = props

  const [email, setEmail] = useState<string>("")
  const [message, setMessage] = useState<string | null>(null)

  const history = useHistory()

  const handleSignup = async (e: any) => {
    e.preventDefault()
    setMessage(null)
    // Check if email entered
    // if (email == "") {
    //   console.error("ERROR: Email is empty")
    //   setMessage("Please enter an email address.")
    //   return
    // }

    // // Check if email is valid
    // let isValid = EmailValidator.validate(email.trim())
    // if (!isValid) {
    //   console.error("ERROR: Email is not valid: ", email.trim())
    //   setMessage("Please enter a valid email address.")
    //   return
    // } else {
    //   setMessage(null)
    // }

    // if (email !== "") {
    //   history.push({
    //     pathname: '/auth/create-account',
    //     search: `?email=${encodeURIComponent(email)}`
    //   })
    // } else {
    //   history.push({
    //     pathname: '/auth/create-account',
    //   })
    // }

    if ((window as any).posthog && process.env.REACT_APP_ENVIRONMENT === 'prod') {
      let source = "(Not Set)"
      let campaign = "(Not Set)"

      const params = new URLSearchParams(window.location.search)
      if ((params as any).utm_source) { source = (params as any).utm_source }
      if ((params as any).utm_campaign) { campaign = (params as any).utm_campaign }
      (window as any).posthog.capture('Sign Up', {
        category: "Profile",
        label: source,
        value: campaign
      })
    }


    history.push({
      pathname: '/auth/create-account',
    })
  }

  return (
    <div className="portal-signup">
      <form onSubmit={handleSignup}>
        {/* <input
          className="portal-signup-email"
          type="email"
          placeholder="Enter your email address..."
          autoComplete="username"
          value={email}
          onChange={e => setEmail(e.target.value)}
        /> */}
        <button
          type="submit"
          className="portal-signup-button w-100"
        >
          {display}
        </button>
      </form>
      {message &&
        <div className="portal-signup-error mt-4">
          {message}
        </div>
      }
    </div>
  )
}

Builder.registerComponent(SignUp, {
  name: 'SignUp',
  inputs: [
    {
      name: "display",
      type: "text",
      defaultValue: "Sign Up"
    }
  ]
})
