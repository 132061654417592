import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import YourHealth from '../../../api';
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader.js";
import {
  LOAD_MEDICATIONS
} from '../../../store/actions';
import { supabase } from '../../../lib/supabase';
import { notifyError, notifySuccess } from '../../../utils/notify';

const initialFormState = {
  type: 'OVERTHECOUNTER',
  name: '',
  dose: '',
  frequency: ''
}

const initialSelectedState = {
  id: '',
  type: 'OVERTHECOUNTER',
  name: '',
  dose: '',
  frequency: '',
  profileMedicationsId: ''
}

function OTCMedications({ profile, medical, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [singleSelections, setSingleSelections] = useState([]);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [singleSelectedSelections, setSelectedSingleSelections] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    setFormData({
      ...formData,
      'profileMedicationsId': profile.user.id
    });

    getOTCMedications(profile.user.id);
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;

    // Check if valid - first + last name
    if (formData.name != "") {
      // Set save flag
      setAddItem(true);
    } else {
      // Set save flag
      setAddItem(false);
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [formData]);

  async function getOTCMedications(profileId) {
    const { data, error } = await supabase
      .from('medication')
      .select(`*, medication_type!inner(*)`)
      .eq('medication_type.name', 'OVERTHECOUNTER')
      .eq('profile_id', profileId)

    if (error) {
      console.error("Error getting otc medications: ", error);
    }

    dispatch({
      type: LOAD_MEDICATIONS,
      payload: {
        type: "OVERTHECOUNTER",
        items: data
      }
    });
  }

  async function saveOTCMedication() {
    if (!formData.name) return;

    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (window.posthog) {
        // Track signup
        window.posthog.capture('Updated Data', {
          category: "Medical Information",
          label: "OTC Medications",
          $set: {
            email: profile.user.email,
          }
        });
      }

      // Mark change to profile
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Updated_Profile"
      });
    }

    const { data: medicationTypeData, error: medicationTypeError } = await supabase
      .from('medication_type')
      .select('id')
      .eq('name', 'OVERTHECOUNTER')
      .single()

    if (medicationTypeError) {
      console.error('Error fetching medication type id:', error);
      return null
    }

    const { data, error } = await supabase
      .from('medication')
      .insert({
        name: formData.name,
        dose: formData.dose,
        frequency: formData.frequency,
        medication_type_id: medicationTypeData.id
      })

    if (error) {
      console.error("Error creating medication: ", error)
      notifyError("Error adding medication")
      return
    }

    notifySuccess("Medication added successfully")

    // Clear form data
    setFormData({
      ...initialFormState,
      'profileMedicationsId': profile.user.id
    });

    // Clear selection
    setSingleSelections([]);

    // Reset flag
    setAddItem(false);

    await getOTCMedications(profile.user.id);
  }

  async function removeMedication(medicationId) {
    const { error } = await supabase
      .from('medication')
      .delete()
      .eq('id', medicationId)

    if (error) {
      console.error("Error deleting medication: ", error)
      notifyError("Error deleting medication")
      return
    }

    notifySuccess("Medication deleted successfully")

    await getOTCMedications(profile.user.id);
  }

  async function modifyMedication(medication) {
    const { data, error } = await supabase
      .from('medication')
      .update({
        name: medication.name,
        dose: medication.dose,
        frequency: medication.frequency
      })
      .eq('id', medication.id)

    if (error) {
      console.error("Error updating medication: ", error)
      notifyError("Error updating medication")
      return
    }

    notifySuccess("Medication updated successfully")

    await getOTCMedications(profile.user.id);
  }

  return (
    <>
      <MedicalAdminHeader name="Over the Counter Medications" parentName="Medical Information" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Over the Counter Medication</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-name"
                          >
                            Name (Required)
                          </label>
                          <Input
                            id="input-condition"
                            placeholder=""
                            type="text"
                            value={formData.name}
                            onChange={e => setFormData({ ...formData, 'name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-dose"
                          >
                            Dose
                          </label>
                          <Input
                            id="input-prescription-dose"
                            placeholder=""
                            type="text"
                            value={formData.dose}
                            onChange={e => setFormData({ ...formData, 'dose': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-prescription-frequency"
                          >
                            Frequency
                          </label>
                          <Input
                            id="input-prescription-frequency"
                            placeholder=""
                            type="text"
                            value={formData.frequency}
                            onChange={e => setFormData({ ...formData, 'frequency': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="success"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          onClick={saveOTCMedication}
                          size="xl"
                          disabled={!addItem}
                        >
                          Add Medicine
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">My Over the Counter Medications</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Dose</th>
                    <th>Frequency</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {medical.medications.otc.map((medication, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            e.preventDefault();

                            setSelectedData({
                              id: medication.id,
                              type: medication.type,
                              name: medication.name,
                              dose: medication.dose,
                              frequency: medication.frequency,
                              profileMedicationsId: formData.profileMedicationsId
                            });

                            // Set modal selected
                            setSelectedSingleSelections([medication.name]);

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          {medication.name}
                        </a>
                      </td>
                      <td>
                        <span className="text-muted">
                          {medication.dose}
                        </span>
                      </td>
                      <td>
                        <span className="text-muted">
                          {medication.frequency}
                        </span>
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href=""
                          id="tooltip564981685"
                          onClick={(e) => {
                            e.preventDefault();

                            // Set selected data
                            setSelectedData({
                              id: medication.id,
                              type: medication.type,
                              name: medication.name,
                              dose: medication.dose,
                              frequency: medication.frequency,
                              profileMedicationsId: formData.profileMedicationsId
                            });

                            // Set modal selected
                            setSelectedSingleSelections([medication.name]);

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          <i className="fas fa-user-edit" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip564981685">
                          Edit Medication
                        </UncontrolledTooltip>
                        <a
                          className="table-action table-action-delete"
                          href="#"
                          id="tooltip601065234"
                          onClick={() => removeMedication(medication.id)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip601065234">
                          Delete Medication
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Prescription
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-name"
                    >
                      Name
                    </label>
                    <Input
                      id="input-condition"
                      placeholder=""
                      type="text"
                      value={selectedData.name}
                      onChange={e => setSelectedData({ ...selectedData, 'name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-dose"
                    >
                      Dose
                    </label>
                    <Input
                      id="input-prescription-dose"
                      placeholder=""
                      type="text"
                      value={selectedData.dose}
                      onChange={e => setSelectedData({ ...selectedData, 'dose': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-prescription-frequency"
                    >
                      Frequency
                    </label>
                    <Input
                      id="input-prescription-frequency"
                      placeholder=""
                      type="text"
                      value={selectedData.frequency}
                      onChange={e => setSelectedData({ ...selectedData, 'frequency': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href="#"
            onClick={() => {
              modifyMedication(selectedData);
              // Close modal
              setdefaultModal(false);
            }}
            size="xl"
          >
            Update Condition
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedOTCMedications = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(OTCMedications);

export default ConnectedOTCMedications;
