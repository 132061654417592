import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    links: []
};

const resourcesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_LINKS: {
          return {
              ...state,
              links: action.payload
          };
        }
        default:
            return state;
    }
};

export default resourcesReducer;
