import { E164Number } from 'libphonenumber-js/core'
import { FormEvent, useState } from "react"
import PhoneInput from "react-phone-number-input/input"
import { useHistory } from 'react-router-dom'
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap"
import { supabase } from '../../lib/supabase'
import SignUpHeader from "../Headers/SignUpHeader"
import '../../auth.css'
import { Amplify } from 'aws-amplify'
import { getAnalytics, logEvent } from "firebase/analytics"
import YourHealth from '../../api';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID ?? '',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
    }
  }
})


type CreateAccountFormFields = {
  email: string
  password: string
  confirmPassword: string
  phone: E164Number | undefined
  first_name: string
  last_name: string
}

export default function CreateAccount() {
  const history = useHistory()
  const params = window.location.search
  const prepopulatedEmailParam = new URLSearchParams(params).get('email')

  const [createAccountFormData, setCreateAccountFormData] = useState<CreateAccountFormFields>({
    email: prepopulatedEmailParam ? decodeURIComponent(prepopulatedEmailParam) : '',
    password: '',
    confirmPassword: '',
    phone: '',
    first_name: '',
    last_name: ''
  })

  const [showPassword, setShowPassword] = useState(false)
  const [passwordMessage, setPasswordMessage] = useState<string | null>(null)
  const [firstNameMessage, setFirstNameMessage] = useState<string | null>(null)
  const [lastNameMessage, setLastNameMessage] = useState<string | null>(null)
  const [emailMessage, setEmailMessage] = useState<string | null>(null)
  const [optIn, setOptIn] = useState(false)
  const [optInMessage, setOptInMessage] = useState<string | null>(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const analytics = getAnalytics()

  const handleSubmit = async (e: FormEvent) => {
    console.log('Creating account', createAccountFormData.email)

    setFirstNameMessage(null)
    setLastNameMessage(null)
    setEmailMessage(null)
    setPasswordMessage(null)
    setOptInMessage(null)

    setIsSubmitting(true)
    e.preventDefault()

    // if (!createAccountFormData.first_name) {
    //   setFirstNameMessage("First name is required.")
    //   setIsSubmitting(false)
    //   return
    // }

    // if (!createAccountFormData.last_name) {
    //   setLastNameMessage("Last name is required.")
    //   setIsSubmitting(false)
    //   return
    // }

    if (!createAccountFormData.email) {
      setEmailMessage("Please enter your email address")
      setIsSubmitting(false)
      return
    }

    // Check if password minimum length
    if (createAccountFormData.password.length < 8) {
      setPasswordMessage("Password must be at least 8 characters")
      setIsSubmitting(false)
      return
    }


    // Check if passwords match
    if (createAccountFormData.password !== createAccountFormData.confirmPassword) {
      setPasswordMessage("Passwords must match")
      setIsSubmitting(false)
      return
    }


    // Check if the user has checked the opt-in checkbox
    if (!optIn) {
      setOptInMessage("Please check the box to agree to the terms and conditions.")
      setIsSubmitting(false)
      return
    }

    try {

      // const { data: legacyAccountData, error: legacyAccountError } = await supabase.functions.invoke('check-for-legacy-account', {
      //   body: { email: createAccountFormData.email },
      // })

      // if (legacyAccountError) {
      //   console.error(legacyAccountError)
      //   setEmailMessage('Unable to create account. Please contact support@1yourhealth.com')
      //   setIsSubmitting(false)
      //   return
      // }

      // const hasLegacyAccount = legacyAccountData?.has_legacy_account

      // if (hasLegacyAccount) {
      //   setEmailMessage("User already registered")
      //   setIsSubmitting(false)
      //   return
      // }


      const { data, error } = await supabase.auth.signUp({
        email: createAccountFormData.email,
        password: createAccountFormData.password,
        options: {
          data: {
            first_name: createAccountFormData.first_name,
            last_name: createAccountFormData.last_name,
            phone: createAccountFormData.phone,
          }
        }
      })


      if (error) {
        console.error(error)
        setIsSubmitting(false)
        setEmailMessage(error.message)
        return
      }



      if ((window as any).posthog && process.env.REACT_APP_ENVIRONMENT === 'prod') {
        logEvent(analytics, 'sign_up', {
          username: `${createAccountFormData.first_name} ${createAccountFormData.last_name}`,
          amplifyId: '-1',
          email: createAccountFormData.email,
        })

        logEvent(analytics, 'created_profile', {
          profileId: data.user?.id,
        });

        (window as any).posthog.capture('Created Profile', {
          category: "Profile",
          $set: {
            email: createAccountFormData.email,
          }
        });

        YourHealth.api.email.subscribe({
          email: createAccountFormData.email,
          list: "all_members"
        });
      }

      history.push({
        pathname: '/auth/confirm-email',
        search: `?email=${encodeURIComponent(createAccountFormData.email)}`
      })

      // dispatch({
      //     type: UPDATE_WELCOME_STAGE,
      //     payload: {
      //       complete: true,
      //       stage: ''
      //     }
      //   })

    } catch (err: any) {
      console.error(err)
      setIsSubmitting(false)
      setEmailMessage(err.message)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <SignUpHeader
        headline="Welcome!"
        message="Welcome to taking control of your health and wellness with YourHealth."
      />
      <Container
        id="profile"
        className="mt--6"
        fluid
      >
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Create Account</h3>
                  </Col>
                  <Col xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <div className="pl-md-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email Address
                          </label>
                          <Input
                            autoComplete="username"
                            id="input-email"
                            type="email"
                            placeholder="Your email address"
                            value={createAccountFormData.email}
                            onChange={e => setCreateAccountFormData({ ...createAccountFormData, email: e.target.value })}
                          // disabled={!!prepopulatedEmailParam}
                          />
                          {emailMessage &&
                            <div className="text-danger pt-2">
                              {emailMessage}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      {/* <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mobile"
                          >
                            Phone Number (mobile preferred)
                          </label>
                          <PhoneInput
                            id="input-mobile"
                            className="form-control"
                            defaultCountry="US"
                            placeholder="(512) 877-1104"
                            onChange={phone => {
                              setCreateAccountFormData({ ...createAccountFormData, phone })
                            }}
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                    {/* <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="first-name"
                          >
                            First Name
                          </label>
                          <InputGroup>
                            <Input
                              id="first-name"
                              autoComplete="given-name"
                              placeholder="John"
                              type="text"
                              onChange={e => {
                                setCreateAccountFormData({ ...createAccountFormData, first_name: e.target.value })
                              }}
                            />
                          </InputGroup>
                          {firstNameMessage &&
                            <div className="new-password-error" style={{
                              fontSize: '80%',
                              color: '#B03B70',
                              paddingLeft: 10
                            }}>
                              {firstNameMessage}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="first-name"
                          >
                            Last Name
                          </label>
                          <InputGroup>
                            <Input
                              id="last-name"
                              autoComplete="family-name"
                              placeholder="Doe"
                              type="text"
                              onChange={e => {
                                setCreateAccountFormData({ ...createAccountFormData, last_name: e.target.value })
                              }}
                            />
                          </InputGroup>
                          {lastNameMessage &&
                            <div className="new-password-error" style={{
                              fontSize: '80%',
                              color: '#B03B70',
                              paddingLeft: 10
                            }}>
                              {lastNameMessage}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password-1"
                          >
                            Password
                          </label>
                          <InputGroup>
                            <Input
                              id="input-new-password-1"
                              autoComplete="new-password"
                              placeholder="Enter a password"
                              type={showPassword ? "text" : "password"}
                              onChange={e => {
                                setCreateAccountFormData({ ...createAccountFormData, password: e.target.value })
                              }}
                            />
                            <InputGroupAddon
                              addonType="append"
                              onClick={() => {
                                setShowPassword(prev => !prev)
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <InputGroupText>
                                {showPassword ?
                                  <i className="fas fa-eye-slash" />
                                  :
                                  <i className="fas fa-eye" />
                                }
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {passwordMessage &&
                            <div className="text-danger pt-2">
                              {passwordMessage}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password-2"
                          >
                            Confirm Password
                          </label>
                          <InputGroup>
                            <Input
                              id="input-new-password-2"
                              autoComplete="new-password"
                              placeholder="Confirm your password"
                              type={showPassword ? "text" : "password"}
                              onChange={e => {
                                setCreateAccountFormData({ ...createAccountFormData, confirmPassword: e.target.value })
                              }}
                            />
                            <InputGroupAddon
                              addonType="append"
                              onClick={() => {
                                setShowPassword(prev => !prev)
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              <InputGroupText>
                                {showPassword ?
                                  <i className="fas fa-eye-slash" />
                                  :
                                  <i className="fas fa-eye" />
                                }
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {optInMessage &&
                    <Row>
                      <Col xs="12">
                        <div className="opt-in-message text-danger pb-3">
                          {optInMessage}
                        </div>
                      </Col>
                    </Row>
                  }
                  <Row className="d-flex justify-content-end align-items-center px-md-3">
                    <Col
                      xs="12"
                      md="10"
                      className="d-md-flex justify-content-end mb-3 mb-md-0"
                    >
                      <Row className="text-sm mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                        <Col xs="2" md="6" className="d-flex flex-col justify-content-center justify-content-md-end align-items-center ml-1 p-0 p-md-2">
                          <input
                            type="checkbox"
                            id="opt-in-checkbox"
                            defaultChecked={optIn}
                            onChange={e => {
                              setOptIn(e.target.checked)
                            }}
                          />
                        </Col>

                        <Col xs="10" md="6" className="text-left ml-n2">
                          <Row>
                            <Col xs="12">
                              <label
                                htmlFor="opt-in-checkbox"
                                className="d-inline leading-none"
                              >
                                I agree to YourHealth Data Corp.&apos;s
                              </label>
                            </Col>
                            <Col xs="12">
                              <a
                                href="https://go.yourhealth.app/TermsOfService"
                                target="_blank"
                                style={{ color: '#5B9AA4' }}
                              >
                                Terms of Service
                              </a>
                              <span> &amp; </span>
                              <a
                                href="https://go.yourhealth.app/PrivacyPolicy"
                                target="_blank"
                                style={{ color: '#5B9AA4' }}
                              >
                                Privacy Policy
                              </a>
                              .
                            </Col>
                          </Row>

                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs="12"
                      md="2"
                      className="text-right p-0"
                    >
                      <Button
                        type="submit"
                        color="primary"
                        block
                        size="xl"
                        className="py-3"
                        disabled={isSubmitting}
                      >
                        {isSubmitting &&
                          <>
                            <div
                              className="spinner-border mr-2"
                              role="status"
                              style={{
                                width: 20,
                                height: 20,
                              }}
                            />
                            <span>Creating Account</span>
                          </>
                        }
                        {!isSubmitting &&
                          <span>Sign Up</span>
                        }
                      </Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </>
  )
}
