// Setup config export
const config = {
  api: {
    qr: {
      url: ""
    },
    sms: {
      url: ""
    },
    billing: {
      url: "https://us-central1-yourhealth-app-2cda1.cloudfunctions.net/billing-api"
    },
    email: {
      url: "https://us-central1-yourhealth-app-2cda1.cloudfunctions.net/email-api"
      //url: "http://localhost:5001/yourhealth-app-2cda1/us-central1/email-api"
    },
    admin: {
      url: "https://us-central1-yourhealth-app-2cda1.cloudfunctions.net/admin-api"
      // url: "http://localhost:5001/yourhealth-app-2cda1/us-central1/admin-api"
    }
  },
  stripe: {
    test: {
      apiKey: "pk_test_51LG84pIApNDQ077uusJhed0k6eVXK4wSr6UV9NLjZ4PuYdJix9bYV13gwUVcVgqjoGQbpjPdZR4NjiI1A06e1sVP00enySb8j5"
    },
    prod: {
      apiKey: "pk_live_51LG84pIApNDQ077uFCJbIKxol4BGRNDGBV7bFs9VVmrbS4dhCOnTfMWKc2Ptv5V178gefweXy3evmVb7nrPIafR2008OqYTIoG"
    }
  }
};

export default config;
