import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    payments: {
      stripe: null
    },
    subscription: {
      active: true,
      tier: ""
    },
    billing: {
      externalCustomerId: ""
    }
};

// ===========================|| ACCOUNT REDUCER ||=========================== //

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_ACCOUNT: {
          return {
              ...state,
              initialized: true,
              payments: {
                stripe: (action.payload.stripe) ? action.payload.stripe : null
              }
          };
        }
        case actionTypes.SET_ACCOUNT_BILLING: {
          return {
              ...state,
              billing: action.payload
          };
        }
        default:
            return state;
    }
};

export default accountReducer;
