
import CareCard from "./views/card"
import Contacts from "./views/card/details/Contacts.js"
import Conditions from "./views/card/details/Conditions.js"
import Prescriptions from "./views/card/details/Prescriptions.js"
import OTCMedications from "./views/card/details/OTCMedications.js"
import Allergies from "./views/card/details/Allergies.js"
import Immunizations from "./views/card/details/Immunizations.js"
import Install from "./views/card/install"

const routes = [
  {
    collapse: true,
    medical: false,
    name: "Emergency Care Card",
    icon: "ni ni-ambulance text-primary",
    state: "homeCollapse",
    views: [
      {
        path: "/view/:type/:id/contacts",
        name: "Emergency Contacts",
        miniName: "",
        component: Contacts,
        layout: "/card"
      },
      {
        path: "/view/:type/:id/conditions",
        name: "Chronic Conditions",
        miniName: "",
        component: Conditions,
        layout: "/card"
      },
      {
        path: "/view/:type/:id/prescriptions",
        name: "Prescription Medications",
        miniName: "",
        component: Prescriptions,
        layout: "/card"
      },
      {
        path: "/view/:type/:id/otc",
        name: "OTC Medications",
        miniName: "",
        component: OTCMedications,
        layout: "/card"
      },
      {
        path: "/view/:type/:id/allergies",
        name: "Allergies",
        miniName: "",
        component: Allergies,
        layout: "/card"
      },
      {
        path: "/view/:type/:id/immunizations",
        name: "Immunizations",
        miniName: "",
        component: Immunizations,
        layout: "/card"
      },
      {
        path: "/view/:type/:id",
        name: "Emergency Care Card",
        miniName: "",
        component: CareCard,
        layout: "/card"
      },
      {
        path: "/install/:id",
        name: "Mobile Install",
        miniName: "",
        component: Install,
        layout: "/card"
      },
    ],
  },
  // {
  //   collapse: true,
  //   medical: true,
  //   name: "Medical Information",
  //   icon: "ni ni-badge text-primary",
  //   state: "medicalCollapse",
  //   views: [
  //     {
  //       path: "/details/contacts",
  //       name: "Contacts",
  //       miniName: "",
  //       component: Contacts,
  //       layout: "/card",
  //     },
  //     {
  //       path: "/details/conditions",
  //       name: "Chronic Conditions",
  //       miniName: "",
  //       component: Conditions,
  //       layout: "/card",
  //     },
  //     {
  //       path: "/details/prescriptions",
  //       name: "Prescriptions",
  //       miniName: "",
  //       component: Prescriptions,
  //       layout: "/card",
  //     },
  //     {
  //       path: "/details/otc",
  //       name: "OTC Medications",
  //       miniName: "",
  //       component: OTCMedications,
  //       layout: "/card",
  //     },
  //     {
  //       path: "/details/allergies",
  //       name: "Allergies",
  //       miniName: "",
  //       component: Allergies,
  //       layout: "/card",
  //     },
  //     {
  //       path: "/details/immunizations",
  //       name: "Immunizations",
  //       miniName: "",
  //       component: Immunizations,
  //       layout: "/card",
  //     }
  //   ],
  // }
]

export default routes
