/*
    Email API
 */
import config from '../config';

const Email = {
  subscribe: async function(user) {
    const url = `${config.api.email.url}/email-api/subscribe?ts=${Date.now()}`;
    const data = user;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
    .then((response) => {
      // console.log(response)
    })
    .catch((error) => {
      console.error(error);
    });
  },

  update: async function(user) {
    const url = `${config.api.email.url}/email-api/update?ts=${Date.now()}`;
    const data = user;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
    .then((response) => {
      // console.log(response)
    })
    .catch((error) => {
      console.error(error);
    });
  },

  tag: async function(user) {
    const url = `${config.api.email.url}/email-api/tag?ts=${Date.now()}`;
    const data = user;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
    .then((response) => {
      // console.log(response)
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export default Email;
