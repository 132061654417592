import React, { useRef, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "../routes.auth";

function AuthLayout() {
  const mainContentRef = useRef(null);

  useEffect(() => {
    // Clear loading
    const loading = document.getElementById('loading')
    if (loading) {
      loading.style = "display: none;";
    }
  }, [])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/auth/login" />
        </Switch>
      </div>
    </>
  );
}

export default AuthLayout;
