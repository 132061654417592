import React, { useEffect, useState, useRef } from "react";
import { Document, Page } from 'react-pdf';
import { Button, Col, Container, Modal, Row } from "reactstrap";
import YourHealth from '../../api';
import { useCardAutoDisplay } from "../../store/useCardAutoDisplay";

function CareCardHeader({ display, profile, card, title, description, supabaseInstance }) {
  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const { hasOpened, setHasOpened } = useCardAutoDisplay()

  const printCard = () => {
    const printWindow = window.open(pdfUrl, 'ecc-print');
    setShowModal(false)
    setTimeout(() => {
      printWindow.print();
    }, 800)
  }

  useEffect(() => {
    if (!profile?.initialized || hasOpened) return
    if (display && card.type == "medical" && !showModal) {
      YourHealth.api.ecc.view(profile, (pdfUrl) => {
        setPdfUrl(pdfUrl)
        if (!profile.isMobile) {
          setShowModal(true)
          setHasOpened(true)
        }
      }, supabaseInstance)
    }
  }, [display, card, profile, hasOpened, showModal]);

  return (
    <>
      <div
        className="header pb-6 d-flex flex-column align-items-center medical-care-card-header"
        style={{
          minHeight: "400px",
          backgroundImage: 'url("/assets/img/profile/hero-1.png")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <div className="py-3 w-100 px-3" style={{ maxWidth: 1200 }}>
          <Button
            color="secondary"
            href="/admin/home"
            style={{
              fontSize: '.75rem',
            }}
          >
            <div className="d-flex align-items-center">
              <svg className="mr-2" style={{ width: 10, height: 10 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
              </svg>
              Back to YourHealth
            </div>
          </Button>
        </div>

        <Container className="align-items-center" fluid>
          <>
            {display && card.initialized && profile ?
              <Row className="text-center">
                <Col lg="12" >
                  <div className={card.type && card.type === "medical" ? "mt-4" : "mt-6"}>
                    <h1 className="display-2 text-white">{`${profile.user.first_name} ${profile.user.last_name}`}</h1>
                    <p className="text-white">{formatCardDescription(card?.type)}</p>
                  </div>

                  {(card.type && card.type === "medical") &&
                    <Button
                      className="my-3"
                      color="warning"
                      onClick={() => {
                        YourHealth.api.ecc.view(profile, function (pdfUrl) {
                          setPdfUrl(pdfUrl);
                          setShowModal(true);
                        }, supabaseInstance); // make sure to pass a shareKeySupabase instance here when viewing a medical card.
                      }}
                    >
                      View My Essential Care Card
                    </Button>
                  }
                </Col>
              </Row>
              :
              <Row className="text-center">
                <Col lg="12">
                  <h1 className="display-2 text-white">Your Health</h1>
                  <p className="text-white mt-0 mb-5">Essential Care Card</p>

                  <Button
                    className="mt-4"
                    color="warning"
                    onClick={() => {
                      // Visit corporate site
                      window.location = "https://www.1yourhealth.com";
                    }}
                  >
                    Get Your Free Care Card
                  </Button>
                </Col>
              </Row>
            }
          </>
        </Container>
        <Modal
          className="modal-dialog-centered"
          id="ecc-modal"
          isOpen={showModal}
          toggle={() => setShowModal(false)}
          size="lg"
          style={(profile.isMobile) ?
            { height: '100%', width: '100%', maxWidth: '100%', maxHeight: '100%', marginTop: 0, marginLeft: 0 }
            :
            {}
          }
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Your Essential Care Card
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowModal(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div
            className="modal-body text-center"
            style={(profile.isMobile) ?
              { height: 'calc(100vh - 159px)', overflow: 'auto' }
              :
              { overflow: 'auto' }
            }
          >
            <Document file={pdfUrl}>
              <Page
                width={700}
                pageNumber={1}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
            </Document>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              href=""
              onClick={printCard}
              size="xl"
            >
              Print
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default CareCardHeader;

function formatCardDescription(cardType) {
  if (cardType === 'medical') {
    return 'Medical Essential Care Card';
  } else if (cardType === 'champion') {
    return 'Care Champion Essential Care Card';
  } else if (cardType === 'neighbor') {
    return 'Neighbor Essential Care Card';
  } else {
    console.warn('Unknown card type: ', cardType);
    return 'Essential Care Card'
  }
}
