import { supabase } from '../lib/supabase'
import { Database } from '../types'

const MEDICATIONS_TABLE_NAME = 'medication'

type MedicationsTable = Database["public"]["Tables"]["medication"]
type MedicationTypeTable = Database["public"]["Tables"]["medication_type"]
type Medication = MedicationsTable["Row"]
type MedicationType = MedicationTypeTable["Row"]

export async function getPrescriptions(profileId: string): Promise<(Medication & { medication_type: MedicationType })[]> {
  const { data, } = await (supabase as any)
    .from(MEDICATIONS_TABLE_NAME)
    .select(`*, medication_type!inner(*)`)
    .eq('medication_type.name', 'PRESCRIPTION')
    .throwOnError()
    .eq('profile_id', profileId)

  return data
}

export async function createMedication(medication: Database["public"]["Tables"]["medication"]["Insert"]) {
  const { data } = await supabase
    .from(MEDICATIONS_TABLE_NAME)
    .insert(medication)
    .throwOnError()

  return data
}