import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminLayout from "./layouts/Admin.js";
import CardLayout from "./layouts/Card.js";
import AuthLayout from "./layouts/Auth.js";
import ConsentLayout from "./layouts/Consent.js";
import IndexView from "./views/Index.js";
import { supabase } from './lib/supabase';
import { Helmet } from "react-helmet";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import '@stripe/stripe-js';
import "./utils/analytics";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@aws-amplify/ui-react/styles.css';
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
import 'react-phone-number-input/style.css';
import 'react-toastify/dist/ReactToastify.css';
import './globals.css'

const queryClient = new QueryClient()

function App() {
  const [errorDescription, setErrorDescription] = useState(null)

  useEffect(() => {
    // display an error notification if present in the URL (i.e. password reset link has expired.)
    const urlHash = window.location.hash
    const queryString = urlHash.split('#')[1]
    const params = new URLSearchParams(queryString)
    const urlErrorDescription = decodeURIComponent(params.get('error_description') ?? '')
    if (urlErrorDescription) {
      console.error(urlErrorDescription)
      setErrorDescription(urlErrorDescription)
      setTimeout(() => {
        setErrorDescription(null)
      }, 5000)
    }
  }, [])

  useEffect(() => {
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      if (
        !session
        || !window.posthog
        || process.env.REACT_APP_ENVIRONMENT !== 'prod'
      ) {
        return
      }

      console.log('logged in as', session.user.email)

      const { data: profileData, error: profileError } = await supabase
        .from('profile')
        .select("*")
        .eq('id', session.user.id)
        .single()

      if (profileError) {
        console.error("Error getting profile: ", profileError)
      }
      if (!profileData) {
        console.error('No profile data found for user', session.user.id)
      }

      window.posthog.identify(
        session.user.id,
        { email: session.user.email }
      )
    })
  }, [])

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== 'prod' &&
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      }
      {errorDescription &&
        <div
          style={{
            position: 'absolute',
            top: 15,
            left: 0,
            right: 0,
            zIndex: 1000,
            padding: 20,
            background: 'red',
            width: 400,
            color: 'white',
            margin: '0 auto',
            borderRadius: 6,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg style={{ width: 25, height: 25, marginRight: 10 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z" />
          </svg>
          {errorDescription}
        </div>
      }
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <Route path="/card" render={(props) => <CardLayout {...props} />} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Route path="/records-management" render={(props) => <AdminLayout {...props} />} />
            <Route path="/medication-management" render={(props) => <AdminLayout {...props} />} />
            <Route path="/healthcare-visits" render={(props) => <AdminLayout {...props} />} />
            <Route path="/enhanced-communications" render={(props) => <AdminLayout {...props} />} />
            <Route path="/consent" render={(props) => <ConsentLayout {...props} />} />
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/join" render={(props) => <IndexView {...props} />} />
            <Route path="/" render={(props) => <IndexView {...props} />} />
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  )
}

export default App
