import { supabase } from '../lib/supabase'

const MEDICATION_TYPES_TABLE = 'medication_type'

export async function getMedicationTypes() {
  const { data } = await supabase
    .from(MEDICATION_TYPES_TABLE)
    .select('*')
    .throwOnError()

  return data
}

export async function getPrescriptionMedicationType() {
  const { data } = await supabase
    .from(MEDICATION_TYPES_TABLE)
    .select()
    .eq('name', 'PRESCRIPTION')
    .single()
    .throwOnError()

  return data
}

export async function getOTCMedicationType() {
  const { data } = await supabase
    .from(MEDICATION_TYPES_TABLE)
    .select()
    .eq('name', 'OVERTHECOUNTER')
    .single()
    .throwOnError()

  return data
}
