import React, { useState } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { pdfjs, Document, Page } from 'react-pdf';
import YourHealth from '../../api';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function CardHeader({ image, profile, title, description }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const printCard = () => {
    const printWindow = window.open(pdfUrl, 'ecc-print');
    setIsModalOpen(false);
    setTimeout(() => {
      printWindow.print();
    }, 800)
  }

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/assets/img/profile/${image}.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="d-flex align-items-center" fluid>
          <Row style={{
            textAlign: (profile.isMobile) ? 'center' : 'left'
          }}>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">{title}</h1>
              <p className="text-white mt-0 mb-5">{description}</p>

              <Button
                className="mt-4"
                color="warning"
                onClick={() => {
                  YourHealth.api.ecc.view(profile, function (pdfUrl) {
                    setPdfUrl(pdfUrl);
                    setIsModalOpen(true);
                  });
                }}
              >
                Print My Essential Care Card
              </Button>
            </Col>
          </Row>
        </Container>
        <Modal
          className="modal-dialog-centered"
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(false)}
          size="lg"
          style={(profile.isMobile) ?
            { height: '100%', width: '100%', maxWidth: '100%', maxHeight: '100%', marginTop: 0, marginLeft: 0 }
            :
            {}
          }
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">
              Your Essential Care Card
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setIsModalOpen(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div
            className="modal-body text-center"
            style={(profile.isMobile) ?
              { height: 'calc(100vh - 159px)', overflow: 'auto' }
              :
              { overflow: 'auto' }
            }
          >
            <Document file={pdfUrl}>
              <Page
                width={700}
                pageNumber={1}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
            </Document>
          </div>
          <div className="modal-footer">
            <Button
              color="primary"
              href=""
              onClick={printCard}
              size="xl"
            >
              Print
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default CardHeader;
