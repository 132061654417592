import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    users: []
};


const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_ADMIN: {

          // Process users
          // let processedUsers = [];
          // for(var i = 0; i < action.payload.length; i++) {
          //   // Grab user
          //   let user = {
          //     "email": "",
          //     "createdDate": action.payload[i].UserCreateDate.substr(0, 10)
          //   }
          //
          //   // Grab email
          //   for(var j = 0; j < action.payload[i].Attributes.length; j++) {
          //     // Check email
          //     if(action.payload[i].Attributes[j].Name == "email") {
          //       user.email = action.payload[i].Attributes[j].Value;
          //     }
          //   }
          // }

          return {
              ...state,
              initialized: true,
              users: action.payload.slice(0, 20)
          };
        }
        default:
            return state;
    }
};

export default adminReducer;
