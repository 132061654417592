import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    type: ""
};

const cardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_CARD: {
          return {
              ...state,
              initialized: true,
              type: action.payload
          };
        }
        default:
            return state;
    }
};

export default cardReducer;
