import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import MedicalAdminHeader from "../../../components/Headers/MedicalAdminHeader.js";
import { supabase } from '../../../lib/supabase';
import {
  LOAD_LINKS
} from '../../../store/actions';

const initialFormState = {
  name: '',
  url: ''
}

const initialSelectedState = {
  id: '',
  name: '',
  url: '',
  profileLinksId: ''
}

function Links({ profile, resources, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [addItem, setAddItem] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);

  const [selectedData, setSelectedData] = useState(initialSelectedState);

  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    setFormData({
      ...formData,
      'profileLinksId': profile.user.id
    });

    getLinks(profile.user.id);
  }

  // Validate on form data change
  useEffect(() => {
    // 👇️ set isMounted to true
    let isMounted = true;

    // Check if valid - first + last name
    if (formData.name != "" && formData.url != "") {

      // Set save flag
      setAddItem(true);
    } else {
      // Set save flag
      setAddItem(false);
    }

    return () => {
      // 👇️ when component unmounts, set isMounted to false
      isMounted = false;
    };
  }, [formData]);

  async function getLinks(profileId) {
    let { data, error } = await supabase
      .from('link')
      .select('*')
      .eq('profile_id', profileId)

    if (error) {
      console.error('Error getting links', error)
    }

    dispatch({
      type: LOAD_LINKS,
      payload: data
    });
  }

  async function saveLink() {
    if (!formData.name || !formData.url) return;

    const { data, error } = await supabase
      .from('link')
      .insert({
        name: formData.name,
        url: formData.url,
      })

    if (error) {
      console.error('Error saving link', error)
    }

    setFormData({
      ...initialFormState,
      'profileLinksId': profile.user.id
    });

    // Reset flag
    setAddItem(false);

    await getLinks(profile.user.id);
  }

  async function removeLink(linkId) {

    const { error } = await supabase
      .from('link')
      .delete()
      .eq('id', linkId)

    if (error) {
      console.error('Error removing link', error)
    }

    await getLinks(profile.user.id);
  }

  async function modifyLink(link) {
    const { data, error } = await supabase
      .from('link')
      .update({
        name: link.name,
        url: link.url
      })
      .eq('id', link.id)

    if (error) {
      console.error('Error updating link', error)
    }

    await getLinks(profile.user.id);
  }

  return (
    <>
      <MedicalAdminHeader name="Links" parentName="Healthcare Resources" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add New Link</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-link-name"
                          >
                            Name (Required)
                          </label>
                          <Input
                            id="input-link-name"
                            placeholder=""
                            type="text"
                            value={formData.name}
                            onChange={e => setFormData({ ...formData, 'name': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-link-url"
                          >
                            Website URL (Required)
                          </label>
                          <Input
                            id="input-link-url"
                            placeholder=""
                            type="text"
                            value={formData.url}
                            onChange={e => setFormData({ ...formData, 'url': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="success"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '64px' : '100%',
                            width: (profile.isMobile) ? '141px' : '100%'
                          }}
                          href=""
                          onClick={saveLink}
                          size="xl"
                          disabled={!addItem}
                        >
                          Add Link
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Links</h3>
                  </Col>
                  <Col className="text-right" xs="6">

                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Link</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {resources.links.map((link, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => e.preventDefault()}
                        >
                          {link.name}
                        </a>
                      </td>
                      <td>
                        <Button
                          color="primary"
                          href={link.url}
                          target="_blank"
                          // onClick={() => {
                          //   console.log("Loading Link: ", link.url);
                          //   window.open(link.url, '_blank');
                          // }}
                          size="sm"
                        >
                          Visit Website
                        </Button>
                      </td>
                      <td className="table-actions">
                        <a
                          className="table-action"
                          href="#"
                          id="tooltip564981685"
                          onClick={(e) => {
                            e.preventDefault();

                            setSelectedData({
                              id: link.id,
                              name: link.name,
                              url: link.url,
                              profileLinksId: formData.profileLinksId
                            });

                            // Open Modal
                            setdefaultModal(true);
                          }}
                        >
                          <i className="fas fa-user-edit" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip564981685">
                          Edit Link
                        </UncontrolledTooltip>
                        <a
                          className="table-action table-action-delete"
                          href="#"
                          id="tooltip601065234"
                          onClick={() => removeLink(link.id)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip601065234">
                          Delete Link
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Link
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-link-name"
                    >
                      Name
                    </label>
                    <Input
                      id="input-link-name"
                      placeholder=""
                      type="text"
                      value={selectedData.name}
                      onChange={e => setSelectedData({ ...selectedData, 'name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-link-url"
                    >
                      Website URL
                    </label>
                    <Input
                      id="input-link-url"
                      placeholder=""
                      type="text"
                      value={selectedData.url}
                      onChange={e => setSelectedData({ ...selectedData, 'url': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href="#"
            onClick={() => {
              modifyLink(selectedData);
              // Close modal
              setdefaultModal(false);
            }}
            size="xl"
          >
            Update Link
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedLinks = connect(state => ({
  profile: state.profile,
  resources: state.resources
}))(Links);

export default ConnectedLinks;
