import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import YourHealth from '../../../../api';
import SignUpHeader from "../../../../components/Headers/SignUpHeader.js";
import { updateProfile } from '../../../../graphql/mutations';
import {
  UPDATE_WELCOME_STAGE
} from '../../../../store/actions';
const initialFormState = {
  firstName: '',
  lastName: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zipCode: ''
}

function Profile({ profile, dispatch }) {
  // Local States
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);

  // Get profile (use effect)
  useEffect(() => {
    syncProfile();
  }, []);

  async function syncProfile() {
    // Set email form data
    setFormData({
      ...formData,
      'profileContactsId': profile.user.id
    });
  }

  // Update
  async function saveProfile() {
    console.log(formData);
    console.log(" -- Bypassing Billing (10/1/2022)");

    // Modify profile
    let updatedProfile = profile.user;
    updatedProfile.firstName = formData.firstName;
    updatedProfile.lastName = formData.lastName;
    updatedProfile.street1 = formData.street1;
    updatedProfile.street2 = formData.street2;
    updatedProfile.city = formData.city;
    updatedProfile.state = formData.state;
    updatedProfile.zipCode = formData.zipCode;
    updatedProfile.complete = true; // TODO: Remove when billing returns

    // Create new contact
    await client.graphql({ query: updateProfile, variables: { input: updatedProfile } });

    // Sync profile
    YourHealth.api.profile.sync({ profile, dispatch });

    // Update profile user
    dispatch({
      type: UPDATE_WELCOME_STAGE,
      payload: {
        complete: true,
        stage: ''
      }
    });




    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      // Update profile data
      YourHealth.api.email.update({
        email: profile.user.email,
        list: "all_members",
        attributes: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          address: {
            street1: formData.street1,
            street2: formData.street2,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode
          }
        }
      });

      // Mark Profile complete
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Completed_Profile"
      });
    }

    // // Sync new profile
    // dispatch({
    //   type: INITIALIZE_PROFILE,
    //   payload: updatedProfile
    // });
    //
    // // Update profile user
    // dispatch({
    //   type: UPDATE_WELCOME_STAGE,
    //   payload: {
    //     complete: false,
    //     stage: 'billing'
    //   }
    // });
  }

  return (
    <>
      <SignUpHeader
        headline="My Profile"
        message="Let’s start by creating your Profile. Add important details about yourself."
      />
      <Container id="profile" className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Please Fill In Details About Yourself</h3>
                  </Col>
                  <Col className="text-right" xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First Name
                          </label>
                          <Input
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            value={formData.firstName}
                            onChange={e => setFormData({ ...formData, 'firstName': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last Name
                          </label>
                          <Input
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            value={formData.lastName}
                            onChange={e => setFormData({ ...formData, 'lastName': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-street-1"
                          >
                            Street
                          </label>
                          <Input
                            id="input-street-1"
                            placeholder="123 Main St."
                            type="text"
                            value={formData.street1}
                            onChange={e => setFormData({ ...formData, 'street1': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-street-2"
                          >
                            Street (cont)
                          </label>
                          <Input
                            id="input-phone"
                            placeholder=""
                            type="text"
                            value={formData.street2}
                            onChange={e => setFormData({ ...formData, 'street2': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            id="input-city"
                            placeholder="Springfield"
                            type="text"
                            value={formData.city}
                            onChange={e => setFormData({ ...formData, 'city': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-state"
                          >
                            State
                          </label>
                          <Input
                            id="input-state"
                            placeholder="Ohio"
                            type="text"
                            value={formData.state}
                            onChange={e => setFormData({ ...formData, 'state': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-zip-code"
                          >
                            Zip Code
                          </label>
                          <Input
                            id="input-zip-code"
                            placeholder="12345"
                            type="text"
                            value={formData.zipCode}
                            onChange={e => setFormData({ ...formData, 'zipCode': e.target.value })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col md="4">

                    </Col>
                    <Col className="text-right" md="8">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          href="#profile"
                          onClick={saveProfile}
                          size="xl"
                        >
                          Next
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedProfile = connect(state => ({
  profile: state.profile
}))(Profile);

export default ConnectedProfile;
