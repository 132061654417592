/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      complete
      email
      username
      amplifyId
      billingId
      billing {
        id
        originalCreatedAt
        originalUpdatedAt
        active
        type
        externalCustomerId
        createdAt
        updatedAt
        __typename
      }
      version
      mobile
      firstName
      lastName
      street1
      street2
      city
      state
      zipCode
      contacts {
        nextToken
        __typename
      }
      conditions {
        nextToken
        __typename
      }
      medications {
        nextToken
        __typename
      }
      allergies {
        nextToken
        __typename
      }
      immunizations {
        nextToken
        __typename
      }
      links {
        nextToken
        __typename
      }
      notes {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      eccSetup
      emergencyCareCards {
        nextToken
        __typename
      }
      medicalNotes
      medicalCardUrl
      activeMedicalECC
      championNotes
      championCardUrl
      activeChampionECC
      neighborNotes
      neighborCardUrl
      activeNeighborECC
      createdAt
      updatedAt
      billingProfilesId
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      complete
      email
      username
      amplifyId
      billingId
      billing {
        id
        originalCreatedAt
        originalUpdatedAt
        active
        type
        externalCustomerId
        createdAt
        updatedAt
        __typename
      }
      version
      mobile
      firstName
      lastName
      street1
      street2
      city
      state
      zipCode
      contacts {
        nextToken
        __typename
      }
      conditions {
        nextToken
        __typename
      }
      medications {
        nextToken
        __typename
      }
      allergies {
        nextToken
        __typename
      }
      immunizations {
        nextToken
        __typename
      }
      links {
        nextToken
        __typename
      }
      notes {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      eccSetup
      emergencyCareCards {
        nextToken
        __typename
      }
      medicalNotes
      medicalCardUrl
      activeMedicalECC
      championNotes
      championCardUrl
      activeChampionECC
      neighborNotes
      neighborCardUrl
      activeNeighborECC
      createdAt
      updatedAt
      billingProfilesId
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      complete
      email
      username
      amplifyId
      billingId
      billing {
        id
        originalCreatedAt
        originalUpdatedAt
        active
        type
        externalCustomerId
        createdAt
        updatedAt
        __typename
      }
      version
      mobile
      firstName
      lastName
      street1
      street2
      city
      state
      zipCode
      contacts {
        nextToken
        __typename
      }
      conditions {
        nextToken
        __typename
      }
      medications {
        nextToken
        __typename
      }
      allergies {
        nextToken
        __typename
      }
      immunizations {
        nextToken
        __typename
      }
      links {
        nextToken
        __typename
      }
      notes {
        nextToken
        __typename
      }
      documents {
        nextToken
        __typename
      }
      eccSetup
      emergencyCareCards {
        nextToken
        __typename
      }
      medicalNotes
      medicalCardUrl
      activeMedicalECC
      championNotes
      championCardUrl
      activeChampionECC
      neighborNotes
      neighborCardUrl
      activeNeighborECC
      createdAt
      updatedAt
      billingProfilesId
      __typename
    }
  }
`;
export const createBilling = /* GraphQL */ `
  mutation CreateBilling(
    $input: CreateBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    createBilling(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      active
      type
      profiles {
        nextToken
        __typename
      }
      externalCustomerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBilling = /* GraphQL */ `
  mutation UpdateBilling(
    $input: UpdateBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    updateBilling(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      active
      type
      profiles {
        nextToken
        __typename
      }
      externalCustomerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBilling = /* GraphQL */ `
  mutation DeleteBilling(
    $input: DeleteBillingInput!
    $condition: ModelBillingConditionInput
  ) {
    deleteBilling(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      active
      type
      profiles {
        nextToken
        __typename
      }
      externalCustomerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      email
      mobile
      firstName
      lastName
      isChampion
      hasConsent
      consents {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      profileContactsId
      __typename
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      email
      mobile
      firstName
      lastName
      isChampion
      hasConsent
      consents {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      profileContactsId
      __typename
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      email
      mobile
      firstName
      lastName
      isChampion
      hasConsent
      consents {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      profileContactsId
      __typename
    }
  }
`;
export const createConsent = /* GraphQL */ `
  mutation CreateConsent(
    $input: CreateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    createConsent(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      contactId
      contact {
        id
        originalCreatedAt
        originalUpdatedAt
        profileId
        type
        email
        mobile
        firstName
        lastName
        isChampion
        hasConsent
        createdAt
        updatedAt
        profileContactsId
        __typename
      }
      date
      mobile
      smsAllowed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConsent = /* GraphQL */ `
  mutation UpdateConsent(
    $input: UpdateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    updateConsent(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      contactId
      contact {
        id
        originalCreatedAt
        originalUpdatedAt
        profileId
        type
        email
        mobile
        firstName
        lastName
        isChampion
        hasConsent
        createdAt
        updatedAt
        profileContactsId
        __typename
      }
      date
      mobile
      smsAllowed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConsent = /* GraphQL */ `
  mutation DeleteConsent(
    $input: DeleteConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    deleteConsent(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      contactId
      contact {
        id
        originalCreatedAt
        originalUpdatedAt
        profileId
        type
        email
        mobile
        firstName
        lastName
        isChampion
        hasConsent
        createdAt
        updatedAt
        profileContactsId
        __typename
      }
      date
      mobile
      smsAllowed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCondition = /* GraphQL */ `
  mutation CreateCondition(
    $input: CreateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    createCondition(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      createdAt
      updatedAt
      profileConditionsId
      __typename
    }
  }
`;
export const updateCondition = /* GraphQL */ `
  mutation UpdateCondition(
    $input: UpdateConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    updateCondition(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      createdAt
      updatedAt
      profileConditionsId
      __typename
    }
  }
`;
export const deleteCondition = /* GraphQL */ `
  mutation DeleteCondition(
    $input: DeleteConditionInput!
    $condition: ModelConditionConditionInput
  ) {
    deleteCondition(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      createdAt
      updatedAt
      profileConditionsId
      __typename
    }
  }
`;
export const createMedication = /* GraphQL */ `
  mutation CreateMedication(
    $input: CreateMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    createMedication(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      name
      dose
      frequency
      createdAt
      updatedAt
      profileMedicationsId
      __typename
    }
  }
`;
export const updateMedication = /* GraphQL */ `
  mutation UpdateMedication(
    $input: UpdateMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    updateMedication(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      name
      dose
      frequency
      createdAt
      updatedAt
      profileMedicationsId
      __typename
    }
  }
`;
export const deleteMedication = /* GraphQL */ `
  mutation DeleteMedication(
    $input: DeleteMedicationInput!
    $condition: ModelMedicationConditionInput
  ) {
    deleteMedication(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      name
      dose
      frequency
      createdAt
      updatedAt
      profileMedicationsId
      __typename
    }
  }
`;
export const createAllergy = /* GraphQL */ `
  mutation CreateAllergy(
    $input: CreateAllergyInput!
    $condition: ModelAllergyConditionInput
  ) {
    createAllergy(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      description
      notes
      createdAt
      updatedAt
      profileAllergiesId
      __typename
    }
  }
`;
export const updateAllergy = /* GraphQL */ `
  mutation UpdateAllergy(
    $input: UpdateAllergyInput!
    $condition: ModelAllergyConditionInput
  ) {
    updateAllergy(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      description
      notes
      createdAt
      updatedAt
      profileAllergiesId
      __typename
    }
  }
`;
export const deleteAllergy = /* GraphQL */ `
  mutation DeleteAllergy(
    $input: DeleteAllergyInput!
    $condition: ModelAllergyConditionInput
  ) {
    deleteAllergy(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      description
      notes
      createdAt
      updatedAt
      profileAllergiesId
      __typename
    }
  }
`;
export const createImmunization = /* GraphQL */ `
  mutation CreateImmunization(
    $input: CreateImmunizationInput!
    $condition: ModelImmunizationConditionInput
  ) {
    createImmunization(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      notes
      createdAt
      updatedAt
      profileImmunizationsId
      __typename
    }
  }
`;
export const updateImmunization = /* GraphQL */ `
  mutation UpdateImmunization(
    $input: UpdateImmunizationInput!
    $condition: ModelImmunizationConditionInput
  ) {
    updateImmunization(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      notes
      createdAt
      updatedAt
      profileImmunizationsId
      __typename
    }
  }
`;
export const deleteImmunization = /* GraphQL */ `
  mutation DeleteImmunization(
    $input: DeleteImmunizationInput!
    $condition: ModelImmunizationConditionInput
  ) {
    deleteImmunization(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      notes
      createdAt
      updatedAt
      profileImmunizationsId
      __typename
    }
  }
`;
export const createLink = /* GraphQL */ `
  mutation CreateLink(
    $input: CreateLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    createLink(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      url
      createdAt
      updatedAt
      profileLinksId
      __typename
    }
  }
`;
export const updateLink = /* GraphQL */ `
  mutation UpdateLink(
    $input: UpdateLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    updateLink(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      url
      createdAt
      updatedAt
      profileLinksId
      __typename
    }
  }
`;
export const deleteLink = /* GraphQL */ `
  mutation DeleteLink(
    $input: DeleteLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    deleteLink(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      name
      url
      createdAt
      updatedAt
      profileLinksId
      __typename
    }
  }
`;
export const createEmergencyCareCard = /* GraphQL */ `
  mutation CreateEmergencyCareCard(
    $input: CreateEmergencyCareCardInput!
    $condition: ModelEmergencyCareCardConditionInput
  ) {
    createEmergencyCareCard(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      date
      notes
      version
      data
      qrCodeUrl
      createdAt
      updatedAt
      profileEmergencyCareCardsId
      __typename
    }
  }
`;
export const updateEmergencyCareCard = /* GraphQL */ `
  mutation UpdateEmergencyCareCard(
    $input: UpdateEmergencyCareCardInput!
    $condition: ModelEmergencyCareCardConditionInput
  ) {
    updateEmergencyCareCard(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      date
      notes
      version
      data
      qrCodeUrl
      createdAt
      updatedAt
      profileEmergencyCareCardsId
      __typename
    }
  }
`;
export const deleteEmergencyCareCard = /* GraphQL */ `
  mutation DeleteEmergencyCareCard(
    $input: DeleteEmergencyCareCardInput!
    $condition: ModelEmergencyCareCardConditionInput
  ) {
    deleteEmergencyCareCard(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      date
      notes
      version
      data
      qrCodeUrl
      createdAt
      updatedAt
      profileEmergencyCareCardsId
      __typename
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      content
      createdAt
      updatedAt
      profileNotesId
      __typename
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      content
      createdAt
      updatedAt
      profileNotesId
      __typename
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      content
      createdAt
      updatedAt
      profileNotesId
      __typename
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      storageUrl
      createdAt
      updatedAt
      profileDocumentsId
      __typename
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      storageUrl
      createdAt
      updatedAt
      profileDocumentsId
      __typename
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      originalCreatedAt
      originalUpdatedAt
      profileId
      profile {
        id
        originalCreatedAt
        originalUpdatedAt
        complete
        email
        username
        amplifyId
        billingId
        version
        mobile
        firstName
        lastName
        street1
        street2
        city
        state
        zipCode
        eccSetup
        medicalNotes
        medicalCardUrl
        activeMedicalECC
        championNotes
        championCardUrl
        activeChampionECC
        neighborNotes
        neighborCardUrl
        activeNeighborECC
        createdAt
        updatedAt
        billingProfilesId
        __typename
      }
      type
      storageUrl
      createdAt
      updatedAt
      profileDocumentsId
      __typename
    }
  }
`;
