import { supabase } from '../lib/supabase'
import { Database } from '../types'

const CONTACTS_TABLE = 'contact'

type ContactsTable = Database["public"]["Tables"]["contact"]
type ContactTypeTable = Database["public"]["Tables"]["contact_type"]
type Contact = ContactsTable["Row"]
type ContactType = ContactTypeTable["Row"]

export async function getDoctors(profileId: string): Promise<(Contact & { contact_type: ContactType })[]> {
  const { data } = await (supabase as any)
    .from(CONTACTS_TABLE)
    .select(`*, contact_type!inner(*)`)
    .in('contact_type.name', ['DOCTOR', 'ONCOLOGIST', 'CARDIOLOGIST'])
    .throwOnError()
    .eq('profile_id', profileId)
  return data
}

export async function createContact(contact: ContactsTable["Insert"]) {
  const { data } = await supabase
    .from(CONTACTS_TABLE)
    .insert(contact)
    .throwOnError()
  return data
}
