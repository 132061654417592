import CreateAccount from './components/SignupV2/CreateAccount'
import ConfirmEmail from './components/SignupV2/ConfirmEmail'
import Login from "./views/auth/Login"
import RequestPasswordReset from './views/auth/RequestPasswordReset'
import UpdatePassword from './views/auth/UpdatePassword'

const routes = [
  {
    collapse: true,
    admin: false,
    name: "Home",
    icon: "ni ni-shop text-primary",
    state: "homeCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/create-account",
        name: "Create Account",
        miniName: "L",
        component: CreateAccount,
        layout: "/auth",
      },
      {
        path: "/confirm-email",
        name: "Confirm Email",
        miniName: "L",
        component: ConfirmEmail,
        layout: "/auth",
      },
      {
        path: "/request-password-reset",
        name: "Request Password Reset",
        miniName: "L",
        component: RequestPasswordReset,
        layout: "/auth",
      },
      {
        path: "/update-password",
        name: "Update Password",
        miniName: "L",
        component: UpdatePassword,
        layout: "/auth",
      },
    ],
  }
]

export default routes
