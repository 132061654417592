/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react library for routing
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

function AdminNavbar({ profile }) {
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark bg-info pt-4"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="YourHealth Logo"
              src="/logo.white.png"
              style={{ height: 50 }}
            />
          </NavbarBrand>
          <Link to="/auth/login">
            <Button
              className="btn-icon"
              color="warning"
              href=""
            >
              {!profile.isMobile ?
                <span id="member-login" style={{ color: 'white' }}>Member Login</span>
                :
                <span id="member-login" className="btn-inner--icon">
                  <i style={{ color: 'white' }} className="fas fa-user" />
                </span>
              }
            </Button>
          </Link>
        </Container>
      </Navbar>
    </>
  );
}

//export default AdminNavbar;

// Connect to store
const ConnectedAdminNavbar = connect(state => ({
  profile: state.profile
}))(AdminNavbar);

export default ConnectedAdminNavbar;
