import { Button } from "reactstrap"
import { supabase } from '../../lib/supabase'
import { useState } from 'react'
import { useHistory } from "react-router"

export default function ConfirmEmail() {
  const params = window.location.search
  const emailParam = new URLSearchParams(params).get('email')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const history = useHistory()

  const resendVerificationEmail = async () => {
    setErrorMessage(null)
    setSuccessMessage(null)

    if (!emailParam) {
      console.error('No email provided.')
      return
    }
    const { data, error } = await supabase.auth.resend({
      type: 'signup',
      email: emailParam,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_BASE_URL}/admin/home`
      }
    })

    if (error) {
      console.error('Error resending verification email:', error)
      setErrorMessage(error.message)
      return
    }

    setSuccessMessage('Verification email sent. Please check your email inbox.')
  }

  return (
    <>
      <div
        className="d-flex flex-column justify-content-center align-items-center w-100 h-100 px-3 pb-8"
        style={{
          paddingTop: 100,
        }}
      >
        <div>
          <img
            src="/logo.white.png"
            alt="YourHealth Logo"
            style={{
              width: 300,
              marginBottom: '2rem',
            }}
          />
        </div>
        <div
          className="p-4 shadow-lg bg-white"
          style={{
            backgroundColor: '#181A1B',
            width: 480,
            maxWidth: '100%',
            borderRadius: 6,
          }}
        >
          <h1>Please confirm your email address</h1>
          <p className="text-sm">
            We&apos;ve sent an email to <span style={{ fontWeight: 'bold', color: '#047D95' }}>{emailParam}</span> with a link to verify your account. If you don&apos;t see it, please check your spam folder, or click the button below to resend the email.
          </p>
          <div className="d-flex flex-column mt-3">
            <Button
              type="submit"
              color="primary"
              size="xl"
              onClick={resendVerificationEmail}
              className="mb-3 py-3"
            >
              Resend Verification Email
            </Button>

            <button
              type="button"
              className="form-button my-1 text-sm"
              onClick={() => history.push('/')}
            >
              Back Home
            </button>
          </div>
        </div>
        {errorMessage && (
          <div className="alert alert-danger mt-4" role="alert">
            {errorMessage}
          </div>
        )}
        {successMessage && (
          <div className="alert alert-success mt-4" role="alert">
            {successMessage}
          </div>
        )}
      </div>
    </>
  )
}