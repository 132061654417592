import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function CardFooter() {
  return (
    <Container
      className="p-0 flex-1"
      style={{
        width: '100%',
        maxWidth: '100%',
        // background: '#45296F'
      }}
    >
      <footer
        className="p-3 footer mx-auto mt-3 mt-lg-5"
        style={{
          maxWidth: 1500,
          // background: '#45296F'
        }}
      >
        <Row className="align-items-center justify-content-lg-between">
          <Col lg="6" className="mb-3">
            <div className="copyright text-center text-lg-left text-muted">
              © {new Date().getFullYear()}{" "}
              <a
                className="font-weight-bold ml-1 "
                href="https://1yourhealth.com"
                target="_blank"
              >
                YourHealth Data Corp.
              </a>
            </div>
          </Col>
          <Col lg="6">
            <Nav className="nav-footer justify-content-center justify-content-lg-end">
              <NavItem>
                <NavLink
                  className="card-footer-nav-item"
                  href="https://1yourhealth.com/overview"
                  target="_blank"
                >
                  About
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="card-footer-nav-item"
                  href="https://1yourhealth.com/contact"
                  target="_blank"
                >
                  Contact
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="card-footer-nav-item"
                  href="https://go.yourhealth.app/TermsOfService"
                  target="_blank"
                >
                  Terms of Service
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="card-footer-nav-item"
                  href="https://go.yourhealth.app/PrivacyPolicy"
                  target="_blank"
                >
                  Privacy Policy
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </footer>
    </Container>
  );
}

export default CardFooter;
