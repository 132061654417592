import Profile from './profile';
import Account from './account';
import ECC from './ecc';
import Tutorial from './tutorial';
import Email from './email';
import Admin from './admin';

const YourHealth = {
  namespace: "@yourhealth",
  api: {
    profile: Profile,
    account: Account,
    ecc: ECC,
    tutorial: Tutorial,
    email: Email,
    admin: Admin
  }
}

export default YourHealth
