import { supabase } from '../lib/supabase'

const CONTACT_TYPES_TABLE = 'contact_type'

export async function getContactTypes() {
  const { data } = await supabase
    .from(CONTACT_TYPES_TABLE)
    .select('*')
    .throwOnError()
  return data
}

export async function getDoctorContactTypes() {
  const { data } = await supabase
    .from(CONTACT_TYPES_TABLE)
    .select()
    .filter('name', 'in', '("DOCTOR","ONCOLOGIST","CARDIOLOGIST","OTHER")')
    .throwOnError()

  return data?.sort((a, b) => {
    if (a.name === 'OTHER') return 1
    if (b.name === 'OTHER') return -1
    return a.name.localeCompare(b.name)
  })
}