import { supabase } from '../lib/supabase'
import { Database } from '../types'

const CONDITIONS_TABLE = 'condition'

export async function getConditions(profileId: string) {
  const { data } = await supabase
    .from(CONDITIONS_TABLE)
    .select(`*`)
    .throwOnError()
    .eq('profile_id', profileId)
  return data
}

export async function createCondition(condition: Database["public"]["Tables"]["condition"]["Insert"]) {
  const { data } = await supabase
    .from(CONDITIONS_TABLE)
    .insert(condition)
    .throwOnError()
  return data
}