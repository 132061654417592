import React, { useEffect } from "react";
import { connect } from 'react-redux';
import {
  Card,
  Col,
  Container,
  Row,
  Table
} from "reactstrap";
import { useSupabase } from '../../../lib/useSupabase';
import { LOAD_IMMUNIZATIONS } from '../../../store/actions';
import { notifyError } from '../../../utils/notify';
import MedicalCardDetailsHeader from "../../../components/Headers/MedicalCardDetailsHeader";

function Immunizations({ profile, medical, dispatch }) {
  const { supabaseInstance } = useSupabase()

  useEffect(() => {
    if (!profile.initialized || !supabaseInstance) return
    getImmunizations(profile.user.id);
  }, [supabaseInstance, profile]);

  async function getImmunizations(profileId) {
    let { data, error } = await supabaseInstance
      .from('immunization')
      .select('*')
      .eq('profile_id', profileId)

    if (error) {
      console.error('error getting immunizations', error)
      notifyError("Error getting immunizations")
      return
    }

    dispatch({
      type: LOAD_IMMUNIZATIONS,
      payload: data
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col className="order-xl-1 px-0 py-2">
          <Card className="mt--6 pb-2">
            <MedicalCardDetailsHeader title="Immunizations" />
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {medical.immunizations.map((immunization, index) => (
                  <tr key={index}>
                    <td className="table-user">
                      <span className="font-weight-bold">
                        {immunization.name}
                      </span>
                    </td>
                    <td>
                      <span className="text-muted">
                        {immunization.notes}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const ConnectedImmunizations = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Immunizations)

export default ConnectedImmunizations
