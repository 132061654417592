import _ from 'lodash';
import config from '../config';
import { getAnalytics, logEvent } from "firebase/analytics";
import { createBilling, updateProfile } from '../../graphql/mutations';
import {
  INITIALIZE_PROFILE,
  INITIALIZE_ACCOUNT,
  UPDATE_WELCOME_STAGE,
  SET_ACCOUNT_BILLING
} from '../../store/actions';
import { loadStripe } from '@stripe/stripe-js';

const Account = {
  init: async function ({ environment, profile, dispatch }) {
    // Get API key
    let apiKey = (environment == "prod") ? config.stripe.prod.apiKey : config.stripe.test.apiKey;

    // Load stripe
    const stripe = await loadStripe(apiKey);

    // Dispatch provider
    dispatch({
      type: INITIALIZE_ACCOUNT,
      payload: {
        stripe: stripe
      }
    });
  },

  setup: async function ({ environment, sessionId, profile, dispatch }) {
    // Create Axios post
    const url = `${config.api.billing.url}/billing-api/lookup-checkout-session?ts=${Date.now()}`;
    const data = { environment, sessionId };

    // Fetch
    const _this = this;
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((data) => {
        // Create new billing object
        if (data && data.session) {
          const billingObject = {
            active: true,
            type: 'ESSENTIAL',
            externalCustomerId: data.session.customer
          };

          // Create new billing object
          (async () => {
            // await _this.setupBilling({ billingObject, dispatch });
            console.log("Creating New Billing Object: ", billingObject);
            const apiData = await client.graphql({
              query: createBilling,
              variables: {
                input: billingObject
              }
            });
            console.log(" --> ", apiData);

            // Check billing object
            if (apiData.data.createBilling) {
              // Update profile
              let updatedProfile = profile;
              updatedProfile.billingId = apiData.data.createBilling.id;
              updatedProfile.complete = true;

              // Update profile
              await client.graphql({
                query: updateProfile,
                variables: {
                  input: {
                    id: profile.id,
                    billingId: apiData.data.createBilling.id,
                    complete: true
                  }
                }
              });

              // Sync new profile
              dispatch({
                type: INITIALIZE_PROFILE,
                payload: updatedProfile
              });

              // Set account billing
              dispatch({
                type: SET_ACCOUNT_BILLING,
                payload: apiData.data.createBilling
              });

              // Prepare subscription metadata
              const newSubscription = {
                transaction_id: sessionId,
                affiliation: 'YourHealth',
                currency: 'USD',
                value: 0.99, // Total Revenue
                tax: 0.00,
                shipping: 0.00,
                items: [{
                  plan: "Essential"
                }]
              };

              // Log event
              const analytics = getAnalytics();
              logEvent(analytics, 'purchase', newSubscription);

              // Update welcome
              dispatch({
                type: UPDATE_WELCOME_STAGE,
                payload: {
                  complete: true,
                  stage: ''
                }
              });
            }
          })();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // Load billing data
  load: async function ({ profile, dispatch }) {
    // // Get billing data
    // const apiData = await client.graphql({
    //   query: getBilling,
    //   variables: {
    //     id: profile.billingId
    //   }
    // });
    // console.log(" --> ", apiData);

    // // Set account billing
    // dispatch({
    //   type: SET_ACCOUNT_BILLING,
    //   payload: apiData.data.getBilling
    // });
  }
}

// Export module
export default Account;
