/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Modal } from "reactstrap";
import { pdfjs, Document, Page } from 'react-pdf';

// API (YourHealth)
import YourHealth from '../../api';

function ConsentHeader({ title, description }) {
  // Local states
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [defaultPDFUrl, setdefaultPDFUrl] = React.useState(null);

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "500px",
          backgroundImage: 'url("/assets/img/profile/hero-1.png")',
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-8" />

        <Container className="align-items-center" fluid>
          <Row className="text-center">
            <Col lg="12">
              <h1 className="display-2 text-white">{title}</h1>
              <p className="text-white mt-0 mb-5">{description}</p>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ConsentHeader;
