import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import YourHealth from '../api';
import { getCurrentUserInfo } from '../common/auth';
import AdminFooter from "../components/Footers/AdminFooter.js";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminSidebar from "../components/Sidebar/AdminSidebar.js";
import CreateAccount from "../components/SignupV2/CreateAccount";
import ConfirmEmail from "../components/SignupV2/ConfirmEmail";
import routes from "../routes.admin.js";
import { ToastContainer } from 'react-toastify';

function AdminLayout({ profile, account, dispatch }) {
  const [sidenavOpen, setSidenavOpen] = React.useState(false);
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const history = useHistory();

  useEffect(() => {
    getCurrentUserInfo()
      .then((userInfo) => {
        if (userInfo?.id) {
          if (profile && !profile.initialized) {
            YourHealth.api.profile.sync({ profile, dispatch });
          }

          if (account && !account.initialized) {
            YourHealth.api.account.init({
              environment: "dev",
              profile: profile,
              dispatch: dispatch
            });
          }
        } else {
          YourHealth.api.profile.logout({ profile, dispatch }, function () {
            history.push("/auth/login");
          });
        }
      })
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.style = "background-color: #FFFFFF;";

    // Clear loading
    const loading = document.getElementById('loading')
    if (loading) {
      loading.style = "display: none;";
    }
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (
        prop.layout === "/admin" ||
        prop.layout === "/records-management" ||
        prop.layout === "/medication-management" ||
        prop.layout === "/healthcare-visits" ||
        prop.layout === "/enhanced-communications"
      ) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      <AdminSidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: "/assets/img/brand/logo.png",
          imgAlt: "YourHealth Logo",
        }}
      />
      <div className="main-content" ref={mainContentRef}
        style={{
          marginLeft: (profile.welcome.complete) ? 62 : 0
        }}
      >
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
          profile={profile}
          dispatch={dispatch}
        />
        <Switch>
          {getRoutes(routes)}
          <Route
            path={'/auth/create-account'}
            component={CreateAccount}
          />
          <Route
            path={'/auth/confirm-email'}
            component={ConfirmEmail}
          />
          <Redirect from="*" to="/admin/home" />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}

      <ToastContainer position="top-center" />
    </>
  );
}

const ConnectedAdminLayout = connect(state => ({
  profile: state.profile,
  account: state.account
}))(AdminLayout);

export default ConnectedAdminLayout;
