import { useEffect, useState } from 'react'
import Select from 'react-select'

export type Option = { value: string, label: string }
type DropdownSelectProps = {
  options: Option[]
  multiple?: boolean
  defaultSelected?: Option[]
  onChange?: (selected: Option | Option[]) => void
}
export function DropdownSelect({ options, multiple = false, defaultSelected = [], onChange }: DropdownSelectProps) {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(defaultSelected)

  useEffect(() => {
    onChange?.(selectedOptions)
  }, [selectedOptions])

  return (
    <Select
      className="w-100"
      defaultValue={selectedOptions}
      // @ts-ignore
      onChange={setSelectedOptions}
      options={options}
      isMulti={multiple}
    />
  )
}
