
import _ from 'lodash';
import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";
import ReactToPrint from 'react-to-print';
import PhoneInput from 'react-phone-number-input/input';
import {
  LOAD_CONTACTS
} from '../../../store/actions';
import CardEditHeader from "../../../components/Headers/CardEditHeader.js";
import QrCodeStylingComponent from "../../../components/QRCode";
import { supabase } from '../../../lib/supabase';
import YourHealth from '../../../api';
import { getCardUrl } from './utils';

const initialFormState = {
  type: 'SPOUSE_PARTNER',
  is_champion: true,
  email: '',
  phone: '',
  first_name: '',
  last_name: '',
  profileContactsId: ''
}

const initialSelectedState = {
  type: 'SPOUSE_PARTNER',
  email: '',
  phone: '',
  first_name: '',
  last_name: '',
  profileContactsId: ''
}

const initialSmsState = {
  name: '',
  phone: ''
}

function ChampionCard({ profile, medical, dispatch }) {
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [contactModal, setcontactModal] = React.useState(false);
  const [careChampionModal, setcareChampionModal] = useState(false);
  const [qrModal, setqrModal] = React.useState(false);
  const [showQRCode, setShowQRCode] = React.useState(false);
  const [qrCodeSize, setqrCodeSize] = useState("2x2");
  const [smsModal, setsmsModal] = useState(false);
  const [activeNav, setActiveNav] = useState(1);
  const [careChampion, setCareChampion] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [selectedData, setSelectedData] = useState(initialSelectedState);
  const [notesData, setNotesData] = useState("");
  const [smsData, setSmsData] = useState(initialSmsState);
  const [contactTypes, setContactTypes] = useState([])
  const [cardUrl, setCardUrl] = useState(null)

  const qrRef = useRef(null);

  let history = useHistory();

  useEffect(() => {
    if (!profile.initialized) return
    init();
  }, [profile]);

  async function init() {
    setNotesData(profile.user.champion_notes ?? "");

    setFormData({
      ...formData,
      'profileContactsId': profile.user.id
    });

    const cardUrl = await getCardUrl(profile.user.id, 'champion');
    setCardUrl(cardUrl);

    await getContactTypes()
    await getContacts(profile.user.id);
  }

  async function getContactTypes() {
    const { data, error } = await supabase
      .from('contact_type')
      .select('*')

    if (error) {
      console.error('error getting contact types', error)
    }
    setContactTypes(data);
  }

  async function getContacts(profileId) {
    let { data, error } = await supabase
      .from('contact')
      .select('*, contact_type(*)')
      .eq('profile_id', profileId)

    if (error) {
      console.error('error getting contacts', error)
    }

    const champion = data.find(contact => contact.is_champion);
    if (champion) {
      setCareChampion([champion]);
    }

    dispatch({
      type: LOAD_CONTACTS,
      payload: data
    });
  }

  const formatPhoneNumber = (str) => {
    if (!str) return "";
    //Filter only numbers from the input
    let cleaned = ('' + str.substr(2)).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  };

  async function updateNotes() {
    const { data, error } = await supabase
      .from('profile')
      .update({
        champion_notes: notesData,
      })
      .eq('id', profile.user.id)

    if (error) {
      console.error('error saving profile', error);
    }

    // Sync profile
    YourHealth.api.profile.sync({ profile, dispatch });
  }

  async function saveContact() {
    if (!formData.first_name || !formData.last_name) return;

    // Track event
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      if (window.posthog) {
        window.posthog.capture('Updated Data', {
          category: "Essential Care Cards",
          label: "Champion",
          $set: {
            email: profile.user.email,
          },
        });
      }

      // Mark change to profile
      YourHealth.api.email.tag({
        email: profile.user.email,
        list: "all_members",
        tag: "Updated_Profile"
      });
    }

    // set is_champion to false for existing champion
    const existingChampion = careChampion[0];

    if (existingChampion) {
      const { data: exitingChampionData, error: existingChampionError } = await supabase
        .from('contact')
        .update(
          {
            is_champion: false,
          },
        )
        .eq('id', existingChampion.id)

      if (existingChampionError) {
        console.error('error updating existig care champion', error)
      }
    }

    let newContact = JSON.parse(JSON.stringify(formData));
    newContact.type = document.getElementById('contactRelation').value;

    const { data, error } = await supabase
      .from('contact')
      .insert(
        {
          email: newContact.email,
          phone: newContact.phone,
          first_name: newContact.first_name,
          last_name: newContact.last_name,
          contact_type_id: newContact.type,
          is_champion: true,
        },
      )

    if (error) {
      console.error('error inserting contact', error)
    }

    // Clear form data
    setFormData({
      ...initialFormState,
      'profileContactsId': profile.user.id
    });
    // Update contacts
    await getContacts(profile.user.id);
  }

  async function modifyContact(contact) {
    delete contact.createdAt;

    const { data, error } = await supabase
      .from('contact')
      .update(
        {
          email: contact.email,
          phone: contact.phone,
          first_name: contact.first_name,
          last_name: contact.last_name,
          contact_type_id: contact.type,
          is_champion: contact.is_champion,
        },
      )
      .eq('id', contact.id)

    if (error) {
      console.error('error updating contact', error)
    }

    await getContacts(profile.user.id);
  }

  return (
    <>
      <CardEditHeader
        image="hero-champion"
        profile={profile}
        title="Care Champion"
        description="Selected and trusted by you to ensure your personal needs and directives are followed."
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-sm-1 order-xl-2" sm="12" xl="4">
            <Card style={(showQRCode) ?
              {
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                zIndex: 1000,
                transition: 'all 1s ease-out'
              }
              :
              {
                transition: 'all 1s ease-out'
              }
            }>
              {showQRCode &&
                <CardHeader style={(showQRCode && profile.isMobile) ?
                  {
                    height: "calc(100vh - 220px)",
                    backgroundColor: "#3F97D6",
                    paddingTop: 50
                  }
                  :
                  {
                    backgroundColor: "#3F97D6"
                  }
                }>
                  <div style={{
                    width: '100%',
                    textAlign: 'center',
                    backgroundColor: '#3F97D6'
                  }}>
                    {/* displays when profile.isMobile = true */}
                    <h3 className="text-center mt-2 mb-3 px-3" style={{ color: "#FFFFFF" }}>
                      This is the QR code that contains the information that you would provide to your Care Champion.
                    </h3>
                    <hr className="my-4" />
                    <QrCodeStylingComponent
                      //data={(profile.user.championCardUrl) ? profile.user.championCardUrl : ''}
                      // data={(profile.user.championCardUrl) ? ((profile.user.version && profile.user.version.length > 0) ? `${profile.user.championCardUrl + '#' + profile.user.version}` : profile.user.championCardUrl) : ''}
                      data={cardUrl}
                      bgColor="white"
                      fgColor="#45296F"
                      eyeColor="#3F97D6"
                      dotColor="#3F97D6"
                      centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/logo.md.png"
                    ></QrCodeStylingComponent>
                    <h1 className="text-center mt-4" style={{ color: "#FFFFFF" }}>View My Care</h1>
                    <h1 className="text-center mt-1" style={{ color: "#FFFFFF" }}>Instructions Here</h1>
                  </div>
                </CardHeader>
              }
              {!profile.isMobile &&
                <CardHeader style={{ backgroundColor: "#3F97D6" }}>
                  <div style={{
                    width: '100%',
                    textAlign: 'center',
                    backgroundColor: '#3F97D6'
                  }}>
                    {/* main qr code on the right side */}
                    <h3 className="text-center mt-2 mb-3 px-3" style={{ color: "#FFFFFF" }}>
                      This is the QR code that contains the information that you would provide to your Care Champion.
                    </h3>
                    <hr className="my-4" />
                    <QrCodeStylingComponent
                      //data={(profile.user.championCardUrl) ? profile.user.championCardUrl : ''}
                      // data={(profile.user.championCardUrl) ? ((profile.user.version && profile.user.version.length > 0) ? `${profile.user.championCardUrl + '#' + profile.user.version}` : profile.user.championCardUrl) : ''}
                      data={cardUrl}
                      bgColor="white"
                      fgColor="#45296F"
                      eyeColor="#3F97D6"
                      dotColor="#3F97D6"
                      centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/logo.md.png"
                    ></QrCodeStylingComponent>
                    <h1 className="text-center mt-4" style={{ color: "#FFFFFF" }}>View My Care</h1>
                    <h1 className="text-center mt-1" style={{ color: "#FFFFFF" }}>Instructions Here</h1>
                  </div>
                </CardHeader>
              }
              {profile.isMobile ?
                <CardBody style={(showQRCode) ?
                  {
                    height: 220
                  }
                  :
                  {}
                }>
                  <Row className="mb-2">
                    <Button block color="primary" size="lg" type="button"
                      onClick={() => {
                        // Load QR Modal
                        setShowQRCode(!showQRCode);
                      }}
                    >
                      {(showQRCode) ? 'Hide QR Code' : 'Show QR Code'}
                    </Button>
                  </Row>
                  <Row className="mb-2">
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => {
                        // Load QR Modal
                        setqrModal(true);
                      }}
                    >
                      Print QR Code
                    </Button>
                  </Row>
                  <Row className="mb-2">
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => {
                        setsmsModal(true);
                      }}
                    >
                      Send QR Code
                    </Button>
                  </Row>
                  {cardUrl &&
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        type="button"
                        href={cardUrl}
                        target="_blank"
                      >
                        View Card Details
                      </Button>
                    </Row>
                  }
                </CardBody>
                :
                <CardBody>
                  <Row className="mb-2">
                    <Button block color="primary" size="lg" type="button"
                      onClick={() => {
                        // Load QR Modal
                        setqrModal(true);
                      }}
                    >
                      Print QR Code
                    </Button>
                  </Row>
                  <Row className="mb-2">
                    <Button block color="secondary" size="lg" type="button"
                      onClick={() => {
                        setsmsModal(true);
                      }}
                    >
                      Send QR Code
                    </Button>
                  </Row>
                  {cardUrl &&
                    <Row className="mb-2">
                      <Button
                        block
                        color="secondary"
                        size="lg"
                        type="button"
                        href={cardUrl}
                        target="_blank"
                      >
                        View Card Details
                      </Button>
                    </Row>
                  }
                </CardBody>
              }
            </Card>
          </Col>
          <Col className="order-sm-2 order-xl-1" sm="12" xl="8">
            <Card id="qr-code-print-instructions">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Printing Your QR Codes</h3>
                  </Col>
                  <Col className="text-right" xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <div className="col mb-4 card-text">
                    <p>It is easy to print out the QR codes for your medical information. Once you have filled out the information you want for your Essential Care Card (ECC), Scroll down until you see the blue QR code for your champion information; click on it. Just below you will see “Print QR Code” and by clicking on that button, you will be taken to the familiar print dialogue that you use for other documents. You can have one on your refrigerator, in your car’s glove compartment, in your purse or wallet or insurance care so you are always prepared.</p>
                  </div>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">My Care Champion</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      href=""
                      id="tooltip969372949"
                      onClick={(e) => {
                        // Stop default behavior
                        e.preventDefault();

                        // Populate initial data
                        setFormData({
                          ...formData,
                          'profileContactsId': profile.user.id
                        });

                        // Open Modal
                        setdefaultModal(true);
                      }}
                      size="md"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Create</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip969372949">
                      Add New Champion Contact
                    </UncontrolledTooltip>
                    <Button
                      className="btn-round btn-icon"
                      color="secondary"
                      href=""
                      id="tooltip969372949"
                      onClick={(e) => {
                        e.preventDefault();

                        // Load modal
                        setcareChampionModal(true);
                      }}
                      size="md"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-user" />
                      </span>
                      <span className="btn-inner--text">Assign</span>
                    </Button>
                    <UncontrolledTooltip delay={0} target="tooltip969372949">
                      Assign to Existing Contacts
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Contact</th>
                    <th>Phone Number</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {careChampion.map((contact, index) => (
                    <tr key={index}>
                      <td className="table-user">
                        <img
                          alt="..."
                          className="avatar rounded-circle mr-3"
                          src="/assets/img/profiles/user.png"
                        />
                        <a
                          className="font-weight-bold"
                          href=""
                          onClick={(e) => {
                            // Stop default behavior
                            e.preventDefault();

                            // Set selected data
                            setSelectedData({
                              id: contact.id,
                              type: contact.contact_type.name,
                              email: contact.email,
                              phone: contact.phone,
                              first_name: contact.first_name,
                              last_name: contact.last_name,
                              profileContactsId: formData.profileContactsId
                            });

                            // Open Modal
                            setcontactModal(true);
                          }}
                        >
                          <b>{contact.first_name} {contact.last_name}</b>
                        </a>
                      </td>
                      <td>
                        <a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a>
                      </td>
                      <td className="table-actions">
                        <Button
                          color="primary"
                          href=""
                          onClick={() => {
                            // Load modal
                            setcareChampionModal(true);
                          }}
                          size="sm"
                        >
                          Select Different Champion
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add Notes</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Champion Notes</label>
                          <Input
                            placeholder=""
                            value={notesData ?? ''}
                            rows="7"
                            type="textarea"
                            onChange={e => setNotesData(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <Row>
                    <Col xs="6">
                      <div style={{
                        height: '100%',
                        float: 'left'
                      }}>
                        <Button
                          color="success"
                          style={{
                            height: (profile.isMobile) ? '85px' : '100%'
                          }}
                          href=""
                          onClick={(e) => {
                            // Prevent default
                            e.preventDefault();

                            // Send to account
                            history.push('/admin/home');
                          }}
                          size="xl"
                        >
                          Return to Profile
                        </Button>
                      </div>
                    </Col>
                    <Col className="text-right" xs="6">
                      <div style={{
                        height: '100%',
                        float: 'right'
                      }}>
                        <Button
                          color="primary"
                          style={{
                            height: (profile.isMobile) ? '85px' : '100%'
                          }}
                          href=""
                          onClick={updateNotes}
                          size="xl"
                        >
                          Update Champion Notes
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Add Contact
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      First Name
                    </label>
                    <Input
                      id="input-first-name"
                      placeholder=""
                      type="text"
                      value={formData.first_name}
                      onChange={e => setFormData({ ...formData, 'first_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Last Name
                    </label>
                    <Input
                      id="input-last-name"
                      placeholder=""
                      type="text"
                      value={formData.last_name}
                      onChange={e => setFormData({ ...formData, 'last_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-type"
                    >
                      Relation
                    </label>
                    {contactTypes?.length &&
                      <Input
                        id="contactRelation"
                        type="select"
                        defaultValue={formData.type}
                      >
                        <option value={contactTypes.find(ct => ct.name === 'SPOUSE_PARTNER').id}>Spouse/Partner</option>
                        <option value={contactTypes.find(ct => ct.name === 'NEIGHBOR').id}>Neighbor</option>
                        <option value={contactTypes.find(ct => ct.name === 'DOCTOR').id}>Doctor</option>
                        <option value={contactTypes.find(ct => ct.name === 'ONCOLOGIST').id}>Oncologist</option>
                        <option value={contactTypes.find(ct => ct.name === 'CARDIOLOGIST').id}>Cardiologist</option>
                        <option value={contactTypes.find(ct => ct.name === 'OTHER').id}>Other</option>
                      </Input>
                    }
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      className="form-control"
                      defaultCountry="US"
                      value={formData.phone}
                      onChange={phone => {
                        setFormData({ ...formData, 'phone': phone })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Email Address
                    </label>
                    <Input
                      id="input-email"
                      placeholder=""
                      type="email"
                      value={formData.email}
                      onChange={e => setFormData({ ...formData, 'email': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // 1: Remove current champion (if set)
              if (careChampion && careChampion.length > 0) {
                let oldChampion = careChampion[0];
                oldChampion.is_champion = false;
                modifyContact(oldChampion);
              }

              // 2: Create New Update Contact (default as champion)
              saveContact(formData);

              // Close modal
              setdefaultModal(false);
            }}
            size="xl"
          >
            Add Contact
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={contactModal}
        toggle={() => setcontactModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit Contact
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setcontactModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      First Name
                    </label>
                    <Input
                      id="input-first-name"
                      placeholder=""
                      type="text"
                      value={selectedData.first_name}
                      onChange={e => setSelectedData({ ...selectedData, 'first_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Last Name
                    </label>
                    <Input
                      id="input-last-name"
                      placeholder=""
                      type="text"
                      value={selectedData.last_name}
                      onChange={e => setSelectedData({ ...selectedData, 'last_name': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-type"
                    >
                      Relation
                    </label>
                    {contactTypes?.length &&
                      <Input
                        id="updatedContactRelation"
                        type="select"
                        defaultValue={contactTypes.find(ct => ct.name === selectedData.type)?.id}
                      >
                        <option value={contactTypes.find(ct => ct.name === 'SPOUSE_PARTNER').id}>Spouse/Partner</option>
                        <option value={contactTypes.find(ct => ct.name === 'NEIGHBOR').id}>Neighbor</option>
                        <option value={contactTypes.find(ct => ct.name === 'DOCTOR').id}>Doctor</option>
                        <option value={contactTypes.find(ct => ct.name === 'ONCOLOGIST').id}>Oncologist</option>
                        <option value={contactTypes.find(ct => ct.name === 'CARDIOLOGIST').id}>Cardiologist</option>
                        <option value={contactTypes.find(ct => ct.name === 'OTHER').id}>Other</option>
                      </Input>
                    }
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-phone"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      id="input-phone"
                      className="form-control"
                      defaultCountry="US"
                      value={selectedData.phone}
                      onChange={phone => {
                        setSelectedData({ ...selectedData, 'phone': phone })
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-email"
                    >
                      Email Address
                    </label>
                    <Input
                      id="input-email"
                      placeholder=""
                      type="email"
                      value={selectedData.email}
                      onChange={e => setSelectedData({ ...selectedData, 'email': e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href="#"
            onClick={() => {
              // Copy data
              let modifiedContact = JSON.parse(JSON.stringify(selectedData));
              modifiedContact.type = document.getElementById('updatedContactRelation').value;

              // Trigger modify
              modifyContact(modifiedContact);

              // Close modal
              setcontactModal(false);
            }}
            size="xl"
          >
            Update Contact
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={careChampionModal}
        toggle={() => setcareChampionModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Select New Care Champion
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setcareChampionModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Contact</th>
                <th>Relation</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {medical?.contacts.map((contact, index) => (
                <tr key={index}>
                  <td className="table-user">
                    <img
                      alt="..."
                      className="avatar rounded-circle mr-3"
                      src="/assets/img/profiles/user.png"
                    />
                    <b>{contact.first_name} {contact.last_name}</b>
                  </td>
                  <td>
                    {contact.contact_type.name}
                  </td>
                  <td className="table-actions">
                    {!contact.is_champion &&
                      <Button
                        color="primary"
                        href=""
                        onClick={() => {
                          // 1: Remove current champion
                          if (careChampion && careChampion.length > 0) {
                            let oldChampion = careChampion[0];
                            oldChampion.is_champion = false;
                            modifyContact(oldChampion);
                          }

                          // 2: Update new champion
                          let newChampion = contact;
                          newChampion.is_champion = true;
                          modifyContact(newChampion);

                          // Load modal
                          setcareChampionModal(false);
                        }}
                        size="sm"
                      >
                        Select
                      </Button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={smsModal}
        toggle={() => setsmsModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Send Essential Care Card
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setsmsModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th>Contact</th>
                <th>Consent</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {_.filter(medical?.contacts, function (c) { return c.has_consent; }).map((contact, index) => (
                <tr key={index}>
                  <td className="table-user">
                    <img
                      alt="..."
                      className="avatar rounded-circle mr-3"
                      src="/assets/img/profiles/user.png"
                    />
                    <b>{contact.first_name} {contact.last_name}</b>
                  </td>
                  <td>
                    {contact.has_consent &&
                      <a
                        className="table-action"
                        href="#"
                        id="tooltip601065234"
                      >
                        <i className="ni ni-check-bold" />
                      </a>
                    }
                  </td>
                  <td className="table-actions">
                    <Button
                      color="primary"
                      href=""
                      onClick={() => {
                        // Generate QR code
                        YourHealth.api.ecc.generate({
                          environment: "prod",
                          card: "medical",
                          username: profile.user.username
                        });

                        // Send SMS
                        YourHealth.api.ecc.send({
                          sender: {
                            name: (profile.user) ? `${profile.user.first_name} ${profile.user.last_name}` : ''
                          },
                          recipient: {
                            name: `${contact.first_name} ${contact.last_name}`,
                            phone: `${contact.phone}`
                          },
                          ecc: {
                            image: `https://storage.googleapis.com/yourhealth-cdn/ecc/prod/${profile.user.username}/medical.png`,
                            url: cardUrl,
                            //url: profile.user.championCardUrl
                            // url: (profile.user.version && profile.user.version.length > 0) ? `${profile.user.championCardUrl + '#' + profile.user.version}` : profile.user.championCardUrl
                          }
                        });

                        // Close modal
                        setsmsModal(false);
                      }}
                      size="sm"
                    >
                      Send Care Card
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="modal-footer" style={{
          paddingTop: 0,
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem'
        }}>
          <p>Don't see your contact? Learn about <a href="/admin/home?tutorial=send-qr-code">sending QR codes vis SMS</a>.</p>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered"
        isOpen={qrModal}
        toggle={() => setqrModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Print QR Code
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setqrModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="qrCodeSize"
              >
                Select Size
              </label>
              <Input
                id="qrCodeSize"
                type="select"
                value={qrCodeSize}
                onChange={(evt) => {
                  // Set QR Code Size
                  setqrCodeSize(evt.target.value);
                }}
              >
                <option value="1x1">1" x 1" (Sticker)</option>
                <option value="2x2">2" x 2" (Wallet / Purse)</option>
                <option value="3x3">3" x 3" (Display)</option>
              </Input>
            </FormGroup>
          </Form>
          <div ref={qrRef} style={{
            width: `${parseInt(qrCodeSize[0])}in`,
            height: `${parseInt(qrCodeSize[0]) * 1.5}in`,
            textAlign: 'center',
            backgroundColor: '#3F97D6',
            paddingTop: `${parseInt(qrCodeSize[0]) * 10}px`,
            borderRadius: '15px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            <style type="text/css" media="print">{`
              @page {
                margin: 2in 2in 2in 2in;
                backgroundColor: #FFFFFF;
              }
            `}</style>
            {/* displays in the print modal */}
            <QrCodeStylingComponent
              data={cardUrl}
              // data={(profile.user.championCardUrl) ? ((profile.user.version && profile.user.version.length > 0) ? `${profile.user.championCardUrl + '#' + profile.user.version}` : profile.user.championCardUrl) : ''}
              width={75 * parseInt(qrCodeSize[0])}
              height={75 * parseInt(qrCodeSize[0])}
              bgColor="white"
              fgColor="#45296F"
              eyeColor="#3F97D6"
              dotColor="#3F97D6"
              centerImageSrc="https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/logo.md.png"
            ></QrCodeStylingComponent>
            <h4 className="text-center" style={{
              marginTop: `${parseInt(qrCodeSize[0]) * 0.75}em`,
              fontSize: `${parseInt(qrCodeSize[0]) * 50}%`,
              color: "#FFFFFF"
            }}>View My Care</h4>
            <h4 className="text-center" style={{
              marginTop: `${parseInt(qrCodeSize[0]) * 0.125}em`,
              fontSize: `${parseInt(qrCodeSize[0]) * 50}%`,
              color: "#FFFFFF"
            }}>Instructions Here</h4>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Update contact
              //console.log("Adding new doctor contact: ", formData);
            }}
            size="xl"
          >
            <ReactToPrint
              trigger={() => <span>Print</span>}
              content={() => qrRef.current}
            />
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedChampionCard = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(ChampionCard);

export default ConnectedChampionCard;
