import { create } from 'zustand'

type CardAutoDisplayState = {
  hasOpened: boolean
  setHasOpened: (hasOpened: boolean) => void
}
export const useCardAutoDisplay = create<CardAutoDisplayState>((set) => ({
  hasOpened: false,
  setHasOpened: (hasOpened) => set({ hasOpened })
}))
