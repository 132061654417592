/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import ReactDOM from "react-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardImg,
  CardTitle,
  CardFooter,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

// API (YourHealth)
import YourHealth from '../../../../api';

// core components
import SignUpHeader from "../../../../components/Headers/SignUpHeader.js";
import Checkout from "../../../../components/SignUp/Checkout";

// AWS Auth
import { Auth } from 'aws-amplify';

// Amplify - API
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

import { listProfiles } from '../../../../graphql/queries';
import { createContact, updateProfile } from '../../../../graphql/mutations';
import {
  INITIALIZE_PROFILE,
  UPDATE_PROFILE_USER,
  UPDATE_WELCOME_STAGE
} from '../../../../store/actions';

const initialFormState = {
  type: 'NEIGHBOR',
  email: '',
  mobile: '',
  firstName: '',
  lastName: '',
  profileContactsId: ''
}

function Billing({ profile, dispatch }) {
  // Local States
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [formData, setFormData] = useState(initialFormState);

  // Get profile (use effect)
  useEffect(() => {
    syncProfile();
  }, []);

  async function syncProfile() {
    // Set email form data
    setFormData({
      ...formData,
      'profileContactsId': profile.user.id
    });
  }

  async function completeProfile() {
    // Mark profile as complete
    await client.graphql({
      query: updateProfile,
      variables: {
        input: {
          id: profile.user.id,
          complete: true
        }
      }
    });

    // Sync profile
    YourHealth.api.profile.sync({ profile, dispatch });

    // Update profile user
    dispatch({
      type: UPDATE_WELCOME_STAGE,
      payload: {
        complete: true,
        stage: ''
      }
    });
  }

  return (
    <>
      <SignUpHeader
        headline="YourHealth"
        message="Please select your plan."
      />
      <Container id="profile" className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Checkout />
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={defaultModal}
        toggle={() => setdefaultModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Terms of Service
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Far far away, behind the word mountains, far from the
            countries Vokalia and Consonantia, there live the
            blind texts. Separated they live in Bookmarksgrove
            right at the coast of the Semantics, a large language
            ocean.
          </p>
          <p>
            A small river named Duden flows by their place and
            supplies it with the necessary regelialia. It is a
            paradisematic country, in which roasted parts of
            sentences fly into your mouth.
          </p>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setdefaultModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedBilling = connect(state => ({
  profile: state.profile
}))(Billing);

export default ConnectedBilling;
