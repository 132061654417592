/*
    Email API
 */

// Imports (external)
import axios from 'axios';
import _ from 'lodash';

// Import Configuration
import config from '../config';

// Admin
const Admin = {
  // Reset
  reset: async function(user) {
    console.log("Reseting: ", user);

    // Create Axios post (Email subscri)
    const url = `${config.api.admin.url}/admin-api/reset?ts=${Date.now()}`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify(user)
    })
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      console.log(error);
    });
  },

  // Delete
  delete: async function(user) {
    console.log("Deleting: ", user);

    // Create Axios post (Email subscri)
    const url = `${config.api.admin.url}/admin-api/delete?ts=${Date.now()}`;
    const data = {
      "email": user.email
    };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
    .then((response) => {
      console.log(response)
    })
    .catch((error) => {
      console.log(error);
    });
  }
}

// Export module
export default Admin;
