/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Consent
import Consent from "./views/consent";

const routes = [
  {
    collapse: true,
    admin: false,
    name: "Home",
    icon: "ni ni-shop text-primary",
    state: "homeCollapse",
    views: [
      {
        path: "/contact/:id",
        name: "Consent",
        miniName: "C",
        component: Consent,
        layout: "/consent",
      }
    ],
  }
];

export default routes;
