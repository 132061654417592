import { supabase } from "../lib/supabase"

/**
 * The functions in this file were originally intended to be used with Amplify Auth,
 * per this migration guide: https://docs.amplify.aws/gen1/javascript/build-a-backend/auth/auth-migration-guide/
 *
 * They connect to Supabase Auth now, but their implementations throughout the app remain the same.
 */
export const getCurrentUserInfo = async () => {
  const { data: sessionData, error: sessionError } = await supabase.auth.getSession()

  if (sessionError) {
    console.error("Error fetching session: ", sessionError)
    throw new Error("Error fetching session")
  }

  if (!sessionData) {
    console.error("No session data found")
    throw new Error("No session data found")
  }

  return {
    id: sessionData.session?.user?.id,
    username: sessionData.session?.user?.user_metadata.sub,
    // attributes
  }
}

export const getAuthenticatedUser = async () => {
  const { data: sessionData, error: sessionError } = await supabase.auth.getSession()

  if (sessionError) {
    console.error("Error fetching session: ", sessionError)
    throw new Error("Error fetching session")
  }

  if (!sessionData) {
    console.error("No session data found")
    throw new Error("No session data found")
  }

  return {
    username: sessionData.session?.user?.id,
    session: sessionData.session,
  }
}
