import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom'
import { getCurrentUserInfo } from "../../common/auth"
import { BuilderComponent, Content, builder } from '@builder.io/react'
import './components/SignUp/SignUp'
import './components/Quotes/Quotes.js'

builder.init(process.env.REACT_APP_BUILDER_API_KEY ?? 'builder-api-key-not-found')

function Homepage() {
  const [builderContentJson, setBuilderContentJson] = useState<Content>()
  const history = useHistory()



  // Load content
  useEffect(() => {
    builder.get('page', { url: '/' })
      .promise().then(setBuilderContentJson)

    getCurrentUserInfo()
      .then((userInfo) => {
        if (userInfo?.id) {
          history.push("/admin/home")
        }
        // if (userInfo) {
        //   console.log("User Exists - Signing In: ", userInfo);

        //   // Send back to login
        //   history.push("/auth/login");
        // } else {
        //   console.log("User not logged in.");
        // }
      }).catch((err) => {
        console.error(err)
      })
  }, [])
  return <BuilderComponent model="page" content={builderContentJson} />
}

export default Homepage
