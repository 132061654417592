import axios from 'axios';
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { supabase } from '../../lib/supabase';

const formatPhoneNumber = (str) => {
  if (!str) return ''
  try {
    //Filter only numbers from the input
    let cleaned = ('' + str.substr(2)).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
  } catch (err) {
    console.error(err);
    return ''
  }
};

export async function updateProfile(form, profile) {
  if (!profile?.user) {
    console.warn('No profile provided')
    return
  }
  // Name
  form.getTextField('Name 1').setText(`${profile.user.first_name ?? ' '} ${profile.user.last_name ?? ' '}`);

  // Address
  form.getTextField('Address 2').setText(`${profile.user.street_1 ?? ' '} ${profile.user.street_2 ?? ' '}`);
  form.getTextField('Address 3').setText(`${profile.user.city ?? ' '}, ${profile.user.state ?? ' '} ${profile.user.zip_code ?? ' '}`);


  // Phone
  form.getTextField('Phone_2').setText(formatPhoneNumber(profile.user.phone) ?? ' ');
}

export async function updateContacts(form, profileId, supabaseInstance = supabase) {
  let { data, error } = await supabaseInstance
    .from('contact')
    .select('*, contact_type(*)')
    .eq('profile_id', profileId)

  if (error) {
    console.error('error getting contacts', error)
  }

  if (data.length > 0) {
    // Grab contacts
    const unsortedContacts = data;
    // console.log("Unsorted Contacts: ", unsortedContacts);

    // Sort
    const contacts = _.sortBy(unsortedContacts, ['created_at']);
    // console.log("SortedContacts: ", contacts);

    // Grab Champion and Format
    const champion = _.find(contacts, function (c) { return c.is_champion; });
    if (champion) {
      // Name
      form.getTextField('Name').setText(`${champion.first_name} ${champion.last_name}`);

      // Relationship
      form.getTextField('Relationship').setText("Care Champion");

      // Relationship
      form.getTextField('Phone').setText(champion.phone ? formatPhoneNumber(champion.phone) : ' ');
    } else {
      // Empty
      form.getTextField('Name').setText(" ");
      form.getTextField('Relationship').setText(" ");
      form.getTextField('Phone').setText(" ");
    }

    // Grab First Non-Champion
    const neighbor = _.find(contacts, function (c) { return c.contact_type.name === "NEIGHBOR"; });
    if (neighbor) {
      // Name
      form.getTextField('Name_2').setText(`${neighbor.first_name} ${neighbor.last_name}`);

      // Relationship
      form.getTextField('Relationship_2').setText("Neighbor");

      // Relationship
      form.getTextField('Phone_3').setText(formatPhoneNumber(neighbor.phone));
    } else {
      // Empty
      form.getTextField('Name_2').setText(" ");
      form.getTextField('Relationship_2').setText(" ");
      form.getTextField('Phone_3').setText(" ");
    }

    // Grab First Other
    const other = _.find(contacts, function (c) { return c.contact_type.name === "OTHER" && !c.is_champion; });
    if (other) {
      // Name
      form.getTextField('Doctor').setText(`${other.first_name} ${other.last_name}`);
      form.getTextField('Phone_4').setText(formatPhoneNumber(other.phone));
    } else {
      // Empty
      form.getTextField('Doctor').setText(" ");
      form.getTextField('Phone_4').setText(" ");
    }

    const doctors = _.filter(contacts, function (c) { return c.contact_type.name == "DOCTOR"; });

    // Format doctor 1
    if (doctors.length > 0) {
      form.getTextField('Doctor_2').setText(`${doctors[0].first_name} ${doctors[0].last_name}`);
      form.getTextField('Phone_5').setText(formatPhoneNumber(doctors[0].phone));
    } else {
      form.getTextField('Doctor_2').setText(" ");
      form.getTextField('Phone_5').setText(" ");
    }

    // Format doctor 2
    // if(doctors.length > 1) {
    //   form.getTextField('Doctor_2').setText(`${doctors[1].firstName} ${doctors[1].lastName}`);
    //   form.getTextField('Phone_5').setText(formatPhoneNumber(doctors[1].mobile));
    // } else {
    //   form.getTextField('Doctor_2').setText("");
    //   form.getTextField('Phone_5').setText("");
    // }
  }
}

export async function updateConditions(form, profileId, supabaseInstance = supabase) {
  let { data: conditions, error } = await supabaseInstance
    .from('condition')
    .select('*')
    .eq('profile_id', profileId)

  if (error) {
    console.error("Error fetching conditions: ", error);
  }

  // console.log("Conditions: ", conditions);

  // Iterate over each row in prescriptions
  for (var i = 0; i < 12; i++) {
    // Get prescription
    if (conditions.length > i) {
      // Update row
      form.getTextField(`Condition_Row${(i + 1)}`).setText(conditions[i].name ?? " "); // name
    } else {
      // Update row
      form.getTextField(`Condition_Row${(i + 1)}`).setText(" "); // name
    }
  }
}

export async function updatePrescriptions(form, profileId, supabaseInstance = supabase) {
  const { data: prescriptions, error } = await supabaseInstance
    .from('medication')
    .select(`*, medication_type!inner(*)`)
    .eq('medication_type.name', 'PRESCRIPTION')
    .eq('profile_id', profileId)

  if (error) {
    console.error("Error fetching prescriptions: ", error);
  }

  // Grab prescriptions
  // console.log("Prescriptions: ", prescriptions);

  // Iterate over each row in prescriptions
  for (var i = 0; i < 12; i++) {
    // Get prescription
    if (prescriptions.length > i) {
      // Update row
      form.getTextField(`Row${(i + 1)}`).setText(prescriptions[i].name ?? " "); // name
      form.getTextField(`Row${(i + 1)}_2`).setText(prescriptions[i].dose ?? " "); // dose
      form.getTextField(`Row${(i + 1)}_3`).setText(prescriptions[i].frequency ?? " "); // time
    } else {
      // Update row
      form.getTextField(`Row${(i + 1)}`).setText(" "); // name
      form.getTextField(`Row${(i + 1)}_2`).setText(" "); // dose
      form.getTextField(`Row${(i + 1)}_3`).setText(" "); // time
    }
  }
}

export async function updateOTC(form, profileId, supabaseInstance = supabase) {
  const { data: medications, error } = await supabaseInstance
    .from('medication')
    .select(`*, medication_type!inner(*)`)
    .eq('medication_type.name', 'OVERTHECOUNTER')
    .eq('profile_id', profileId)

  if (error) {
    console.error("Error: ", error);
  }

  // Iterate over each row in prescriptions
  for (var i = 0; i < 12; i++) {
    // Get prescription
    if (medications.length > i) {
      // Update row
      form.getTextField(`OTC_Row${(i + 1)}`).setText(medications[i].name ?? " "); // name
      form.getTextField(`OTC_Row${(i + 1)}_2`).setText(medications[i].dose ?? " "); // dose
      form.getTextField(`OTC_Row${(i + 1)}_3`).setText(medications[i].frequency ?? " "); // time
    } else {
      // Update row
      form.getTextField(`OTC_Row${(i + 1)}`).setText(" "); // name
      form.getTextField(`OTC_Row${(i + 1)}_2`).setText(" "); // dose
      form.getTextField(`OTC_Row${(i + 1)}_3`).setText(" "); // time
    }
  }
}

export async function updateAllergies(form, profileId, supabaseInstance = supabase) {
  let { data: allergies, error } = await supabaseInstance
    .from('allergy')
    .select('*')
    .eq('profile_id', profileId)

  if (error) {
    console.error("Error: ", error);
  }

  // Allergy 1
  if (allergies.length > 0) {
    form.getTextField('Allergy').setText(allergies[0].name ?? " ");
    form.getTextField('Reaction').setText(allergies[0].description ?? " ");
  } else {
    form.getTextField('Allergy').setText(" ");
    form.getTextField('Reaction').setText(" ");
  }

  // Allergy 2
  if (allergies.length > 1) {
    form.getTextField('Allergy_2').setText(allergies[1].name ?? " ");
    form.getTextField('Reaction_2').setText(allergies[1].description ?? " ");
  } else {
    form.getTextField('Allergy_2').setText(" ");
    form.getTextField('Reaction_2').setText(" ");
  }

  // Allergy 3
  if (allergies.length > 2) {
    form.getTextField('Allergy_3').setText(allergies[2].name ?? " ");
    form.getTextField('Reaction_3').setText(allergies[2].description ?? " ");
  } else {
    form.getTextField('Allergy_3').setText(" ");
    form.getTextField('Reaction_3').setText(" ");
  }

  // Allergy 4
  if (allergies.length > 3) {
    form.getTextField('Allergy_4').setText(allergies[3].name ?? " ");
    form.getTextField('Reaction_4').setText(allergies[3].description ?? " ");
  } else {
    form.getTextField('Allergy_4').setText(" ");
    form.getTextField('Reaction_4').setText(" ");
  }

  // Allergy 5
  if (allergies.length > 4) {
    form.getTextField('Allergy_5').setText(allergies[4].name ?? " ");
    form.getTextField('Reaction_5').setText(allergies[4].description ?? " ");
  } else {
    form.getTextField('Allergy_5').setText(" ");
    form.getTextField('Reaction_5').setText(" ");
  }
}

export async function updateImmunizations(form, profileId, supabaseInstance = supabase) {
  let { data: immunizations, error } = await supabaseInstance
    .from('immunization')
    .select('*')
    .eq('profile_id', profileId)

  if (error) {
    console.error('error fetching immunizations', error)
  }

  // Immunization - Tetanus
  const tetanus = _.find(immunizations, function (v) { return v.name.includes("Tetanus"); });
  if (tetanus) {
    form.getTextField('Tetanus').setText(tetanus.notes ?? " ");
  } else {
    form.getTextField('Tetanus').setText(" ");
  }

  // Immunization - Flu
  const flu = _.find(immunizations, function (v) { return v.name.includes("Flu"); });
  if (flu) {
    form.getTextField('Flu').setText(flu.notes ?? " ");
  } else {
    form.getTextField('Flu').setText(" ");
  }

  // Immunization - Pneumonia
  const pneumonia = _.find(immunizations, function (v) { return v.name.includes("Pneumonia"); });
  if (pneumonia) {
    form.getTextField('Pneumonia').setText(pneumonia.notes ?? " ");
  } else {
    form.getTextField('Pneumonia').setText(" ");
  }

  // Immunization - Hepatitis
  const hepatitis = _.find(immunizations, function (v) { return v.name.includes("Hepatitis"); });
  if (hepatitis) {
    form.getTextField('Hepatitis').setText(hepatitis.notes ?? " ");
  } else {
    form.getTextField('Hepatitis').setText(" ");
  }

  // Filter and sort Covid records
  const covidVaccinationsUnsorted = _.filter(immunizations, function (v) { return v.name.includes("Covid"); });
  const covidVaccinations = _.orderBy(covidVaccinationsUnsorted, ['notes'], ['desc']);

  // Immunization - Covid
  const covid = (covidVaccinations && covidVaccinations.length > 0) ? covidVaccinations[0] : null;
  //const covid = _.find(immunizations, function(v) { return v.name.includes("Covid"); });
  if (covid) {
    // Determine type
    let type = " "
    // console.log('covid', covid)
    if (covid.name.includes("Pfizer")) { type = "Pfizer"; }
    if (covid.name.includes("Johnson & Johnson")) { type = "Johnson & Johnson"; }
    if (covid.name.includes("Moderna")) { type = "Moderna"; }

    // Set form
    form.getTextField('Covid').setText(type);
    form.getTextField('Other 1').setText(covid.notes ?? " "); // Covid dates
  } else {
    form.getTextField('Covid').setText(" ");
    form.getTextField('Other 1').setText(" "); // Covid dates
  }

  // Find other
  const otherImmunizations = _.filter(immunizations, function (c) {
    return !c.name.includes("Tetanus") &&
      !c.name.includes("Flu") &&
      !c.name.includes("Pneumonia") &&
      !c.name.includes("Hepatitis") &&
      !c.name.includes("Covid");
  });

  // Combine arrays
  const allImmunizations = _.concat(otherImmunizations, covidVaccinations.shift())

  // Create entry
  let otherImmunizationsField = " ";

  // Iterate over other
  for (var i = 0; i < 3; i++) {
    // Add to field
    if (allImmunizations[i]) {
      otherImmunizationsField += `${allImmunizations[i].name} (${allImmunizations[i].notes})\n`;
    }
  }

  form.getTextField('Text3').setText(otherImmunizationsField);
  //form.getTextField('Text3').setText("YOLO!");
}

export async function updateNotes(form, profile) {
  if (!profile?.user) {
    console.warn('No profile provided')
    return
  }
  // Medical notes
  if (profile.user.medical_notes) {
    form.getTextField('Notes_01').setText(profile.user.medical_notes ?? " ");
  } else {
    form.getTextField('Notes_01').setText(" ");
  }

  // Champion notes
  if (profile.user.champion_notes) {
    form.getTextField('Notes_02').setText(profile.user.champion_notes ?? " ");
  } else {
    form.getTextField('Notes_02').setText(" ");
  }

  // Neighbor notes
  if (profile.user.neighbor_notes) {
    form.getTextField('Notes_03').setText(profile.user.neighbor_notes ?? " ");
  } else {
    form.getTextField('Notes_03').setText(" ");
  }
}

// ECC
const ECC = {
  generate: async function ({ environment, card, username }) {
    // Get image canvas
    const canvas = document.querySelector('canvas');

    // Format and send
    if (canvas) {
      // Get image data
      const image = canvas.toDataURL();

      // Create Axios post
      const url = `https://us-central1-yourhealth-app-2cda1.cloudfunctions.net/qr-api/qr-api/generate?ts=${Date.now()}`;
      const data = { environment, card, username, image };

      fetch(url, {
        method: "POST",
        body: JSON.stringify(data)
      })
        .then((response) => {
          // console.log(response)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  send: async function ({ sender, recipient, ecc }) {
    // Set send number
    sender.mobile = '+15128771104';

    // Create Axios post
    // console.log("Sending SMS: ", sender, recipient, ecc);
    const url = `https://us-central1-yourhealth-app-2cda1.cloudfunctions.net/sms-api/sms-api/ecc?ts=${Date.now()}`;
    //const url = `http://localhost:5001/yourhealth-app-2cda1/us-central1/sms-api/sms-api/ecc?ts=${Date.now()}`;
    const data = { sender, recipient, ecc };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => {
        console.error(error);
      });

  },

  confirmation: async function ({ sender, recipient, ecc }) {
    // Set send number
    sender.mobile = '+15128771104';

    // Create Axios post
    // console.log("Sending Confirmation SMS: ", sender, recipient, ecc);
    const url = `https://us-central1-yourhealth-app-2cda1.cloudfunctions.net/sms-api/sms-api/confirmation?ts=${Date.now()}`;
    //const url = `http://localhost:5001/yourhealth-app-2cda1/us-central1/sms-api/sms-api/confirmation?ts=${Date.now()}`;
    const data = { sender, recipient };

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => {
        console.error(error);
      });

  },

  view: async function (profile, callback, supabaseInstance = supabase) {
    // console.log("Viewing ECC: ", profile);

    // Load ECC PDF template
    const response = await fetch('https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/v8/ecc-template-a.pdf');

    // Convert to pdfBytes
    const pdfBytes = await response.arrayBuffer();

    // Load and parse PDF
    const pdfDoc = await PDFDocument.load(pdfBytes);


    // Get the form containing all the fields
    const form = pdfDoc.getForm();

    // Modify form
    await Promise.all([
      updateProfile(form, profile, supabaseInstance),
      updateContacts(form, profile.user.id, supabaseInstance),
      updateConditions(form, profile.user.id, supabaseInstance),
      updatePrescriptions(form, profile.user.id, supabaseInstance),
      updateOTC(form, profile.user.id, supabaseInstance),
      updateAllergies(form, profile.user.id, supabaseInstance),
      updateImmunizations(form, profile.user.id, supabaseInstance),
      updateNotes(form, profile, supabaseInstance)
    ]);

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const updatedPdfBytes = await pdfDoc.save();

    // Print modified
    const pdfFile = new Blob([updatedPdfBytes], {
      type: 'application/pdf'
    });
    const pdfUrl = URL.createObjectURL(pdfFile);

    // const scaledPdfDoc = await PDFDocument.create();
    // const [embeddedEcc] = await pdfDoc.embedPdf(updatedPdfBytes);
    // const embeddedEccDims = embeddedEcc.scale(1);
    // const page = scaledPdfDoc.addPage();
    // page.drawPage(embeddedEcc, {
    //   ...embeddedEccDims,
    //   x: page.getWidth() / 2 - embeddedEccDims.width,
    //   y: page.getHeight() - embeddedEccDims.height,
    // });

    // const scaledPdfBytes = await scaledPdfDoc.save();

    // const scaledPdfFile = new Blob([scaledPdfBytes], {
    //   type: 'application/pdf'
    // });
    // const scaledPdfUrl = URL.createObjectURL(scaledPdfFile);

    // Callback with pdf URL
    callback(pdfUrl);
  },

  // NO LONGER USED
  // print: async function (profile, supabaseInstance = supabase) {
  //   console.log("Printing ECC: ", profile);

  //   // Load ECC PDF template
  //   const response = await fetch('/assets/test.pdf');

  //   // Convert to pdfBytes
  //   const pdfBytes = await response.arrayBuffer();

  //   // Load and parse PDF
  //   const pdfDoc = await PDFDocument.load(pdfBytes);

  //   // Get the form containing all the fields
  //   const form = pdfDoc.getForm();

  //   // Modify form
  //   await Promise.all([
  //     updateProfile(form, profile, supabaseInstance),
  //     updateContacts(form, supabaseInstance),
  //     updateConditions(form, supabaseInstance),
  //     updatePrescriptions(form, supabaseInstance),
  //     updateOTC(form, supabaseInstance),
  //     updateAllergies(form, supabaseInstance),
  //     updateImmunizations(form, supabaseInstance),
  //     updateNotes(form, profile, supabaseInstance)
  //   ]);

  //   // Serialize the PDFDocument to bytes (a Uint8Array)
  //   const updatedPdfBytes = await pdfDoc.save();

  //   // Print modified
  //   const pdfFile = new Blob([updatedPdfBytes], {
  //     type: 'application/pdf'
  //   });

  //   const pdfUrl = URL.createObjectURL(pdfFile);

  //   const printWindow = window.open(pdfUrl).print();

  // }
}

// Export module
export default ECC;
