import QRCodeStyling from "qr-code-styling";
import React, { useEffect, useRef } from "react";

// Below is QR Code stying object with default attributes
// We can also pass these all attributes as a props for different different uses, colors and data
const qrCode = new QRCodeStyling({
  image: "",
  dotsOptions: {
    color: "black",
    type: "rounded",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10,
    imageSize: 0.4
  },
  backgroundOptions: {
    color: "white",
  },
  cornersSquareOptions: {
    color: "black",
  },
  cornersDotOptionsHelper: {
    color: "black",
  }
});

export default function QrCodeStylingComponent(props) {
  const ref = useRef(null);

  useEffect(() => {
    const options = qrCode._options;
    options.width = (props.width) ? props.width : 300;
    options.height = (props.height) ? props.height : 300;
    options.cornersSquareOptions.color = props.eyeColor;
    options.image = props.centerImageSrc;
    // options.imageOptions = {
    //   imageSize: (props.width && props.width < 100) ? 0.0 : 0.4
    // };
    options.backgroundOptions.color = props.bgColor;
    options.dotsOptions.color = props.fgColor;
    options.cornersDotOptionsHelper.color = props.dotColor;
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    // Determine margin
    let margin = 10;
    switch(props.width) {
      case 75:
        margin = 0;
        break;
      case 150:
        margin = 0;
        break;
      case 225:
        margin = 20;
        break;
      default:
        break;
    }

    qrCode.update({
      data: props.data,
      width: (props.width) ? props.width : 300,
      height: (props.height) ? props.height : 300,
      margin: margin,
      imageOptions: {
        imageSize: (props.width && props.width < 100) ? 0.0 : 0.4
      }
    });
  }, [props]);

  // console.log("QR Code Url: ", props.data);

  return (
    // This is outer div which have some dynamic styles based on props, you can remove this div if you dont need any parent div.
    <div
      id="qr-container"
      style={{
        backgroundColor: props.bgColor && props.bgColor,
        display: "inline-block",
      }}
    >
      {/* Below div is most important, where canvas will be rendered of QR code*/}
      <div ref={ref}></div>
    </div>
  );
}

// class QrCodeStylingComponent extends React.Component {
//   constructor(props) {
//     super(props);
//     this.id = Date.now();
//     this.qrCode = React.createRef();
//   }
//
//   // Component mount
//   componentDidMount() {
//     const options = qrCode._options;
//     options.width = (this.props.width) ? this.props.width : 300;
//     options.height = (this.props.height) ? this.props.height : 300;
//     options.cornersSquareOptions.color = this.props.eyeColor;
//     options.image = this.props.centerImageSrc;
//     // options.imageOptions = {
//     //   imageSize: (props.width && props.width < 100) ? 0.0 : 0.4
//     // };
//     options.backgroundOptions.color = this.props.bgColor;
//     options.dotsOptions.color = this.props.fgColor;
//     options.cornersDotOptionsHelper.color = this.props.dotColor;
//     console.log("Mounted!", qrCode, this.qrCode);
//     qrCode.append(this.qrCode.current);
//
//   }
//
//   render() {
//     // This will *not* work!
//     return (
//       // This is outer div which have some dynamic styles based on props, you can remove this div if you dont need any parent div.
//       <div
//         id={`qr-container-${this.id}`}
//         style={{
//           backgroundColor: this.props.bgColor && this.props.bgColor,
//           display: "inline-block",
//         }}
//       >
//         {/* Below div is most important, where canvas will be rendered of QR code*/}
//         <div ref={this.qrCode}></div>
//       </div>
//     );
//   }
// }
//
// export default QrCodeStylingComponent;
