import { combineReducers } from 'redux';
import cardReducer from './card';
import profileReducer from './profile';
import medicalReducer from './medical';
import resourcesReducer from './resources';
import adminReducer from './admin';
import accountReducer from './account';

const reducer = combineReducers({
    card: cardReducer,
    profile: profileReducer,
    medical: medicalReducer,
    resources: resourcesReducer,
    admin: adminReducer,
    account: accountReducer
});

export default reducer;
