/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Modal
} from "reactstrap";

// AWS Auth
import { Auth } from 'aws-amplify';

// API
import { API } from 'aws-amplify';
import { listProfiles, listAllergies } from '../../../graphql/queries';
import { createAllergy, updateAllergy, deleteAllergy } from '../../../graphql/mutations';
import {
  INITIALIZE_PROFILE,
  LOAD_ALLERGIES
} from '../../../store/actions';

// core components
import InstallHeader from "../../../components/Headers/InstallHeader.js";

const initialFormState = {
  name: '',
  description: '',
  notes: ''
}

const initialSelectedState = {
  id: '',
  description: '',
  notes: '',
  profileAllergiesId: ''
}

function Install({ profile, medical, dispatch }) {
  // Local States
  const [defaultModal, setdefaultModal] = React.useState(false);
  const [activeNav, setActiveNav] = React.useState(1);
  const [formData, setFormData] = useState(initialFormState);

  // Update
  const [selectedData, setSelectedData] = useState(initialSelectedState);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
  };

  // Get Prescription Medications
  useEffect(() => {
    init();
  }, []);

  // Initialize
  async function init() {
    // TBD
  }

  return (
    <>
    <InstallHeader
      profile={profile}
      title="Install"
      description=""
    />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="12">
                  <h3 className="mb-0">Mobile App Install</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <CardText className="mb-4">
                Click the link above to install.
              </CardText>
            </CardBody>
          </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

// Connect to store
const ConnectedInstall = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Install);

export default ConnectedInstall;
