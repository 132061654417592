/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useEffect } from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

// Section Imports
import SignUp from './SignUp';
import Profile from './Profile';
import Billing from './Billing';

function Create({ profile }) {

  console.log("Profile: ", profile);

  // Hide nav
  //document.documentElement.scrollTop

  return (
    {
      'sign-up': <SignUp />,
      'profile': <Profile />,
      'billing': <Billing />
    }[profile.welcome.stage]
  )
}

// Connect to store
const ConnectedCreate = connect(state => ({
  profile: state.profile
}))(Create);

export default ConnectedCreate;
