import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Table
} from "reactstrap";
import { LOAD_CONTACTS } from '../../../store/actions';
import MedicalCardDetailsHeader from "../../../components/Headers/MedicalCardDetailsHeader";
import { notifyError } from '../../../utils/notify';
import { useSupabase } from '../../../lib/useSupabase';

function Contacts({ profile, medical, dispatch }) {
  const { supabaseInstance } = useSupabase()

  useEffect(() => {
    if (!profile.initialized || !supabaseInstance) return
    getContacts(profile.user.id);
  }, [supabaseInstance, profile]);

  async function getContacts(profileId) {
    let { data, error } = await supabaseInstance
      .from('contact')
      .select('*, contact_type(*)')
      .eq('profile_id', profileId)

    if (error) {
      console.error('error getting contacts', error)
      notifyError("Error getting contacts")
      return
    }

    dispatch({
      type: LOAD_CONTACTS,
      payload: data
    })
  }

  const formatPhoneNumber = (str) => {
    if (!str) return ''
    //Filter only numbers from the input
    let cleaned = ('' + str.substr(2)).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };

    return null
  }

  return (
    <Container fluid>
      <Row>
        <Col className="order-xl-1 px-0 py-2">
          <Card className="mt--6 pb-2">
            <MedicalCardDetailsHeader title="Emergency Contacts" />
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>Contact</th>
                  <th>Phone Number</th>
                  <th>Relation</th>
                </tr>
              </thead>
              <tbody>
                {medical.contacts.map((contact, index) => (
                  <tr key={index}>
                    <td className="table-user">
                      <img
                        alt="..."
                        className="avatar rounded-circle mr-3"
                        src="/assets/img/profiles/user.png"
                      />
                      <b>{contact.first_name} {contact.last_name}</b>
                    </td>
                    <td>
                      <a href={`tel:${contact.phone}`}>{formatPhoneNumber(contact.phone)}</a>
                    </td>
                    <td>
                      <span className="font-weight-bold">
                        {contact.contact_type.name}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const ConnectedContacts = connect(state => ({
  profile: state.profile,
  medical: state.medical
}))(Contacts)

export default ConnectedContacts
