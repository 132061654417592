import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { supabase } from '../../lib/supabase'
import { useHistory } from "react-router-dom"
import { notifySuccess } from '../../utils/notify'

export default function UpdatePassword() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const history = useHistory()

  type RequestPasswordResetFormFields = {
    password: string
    confirmPassword: string
  }
  const { register, handleSubmit } = useForm<RequestPasswordResetFormFields>()

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (!session) {
        history.replace('/auth/login')
      }
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      if (!session) {
        history.replace('/auth/login')
      }
    })

    return () => subscription.unsubscribe()
  }, [])

  const onSubmit = async ({ password, confirmPassword }: RequestPasswordResetFormFields) => {
    setIsSubmitting(true)
    setErrorMessage(null)

    if (password !== confirmPassword) {
      setIsSubmitting(false)
      setErrorMessage('Passwords do not match')
      return
    }

    const { error } = await supabase.auth.updateUser({ password })

    if (error && !error.message.includes('New password should be different from the old password')) {
      console.error('reset password for email error:', error)
      setIsSubmitting(false)
      setErrorMessage(error.message)
      return
    }

    notifySuccess('Password updated successfully')
    setIsSubmitting(false)
    history.push('/admin/home')
  }

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center w-100 h-100 px-3 pb-8"
      style={{
        paddingTop: 100,
      }}
    >
      <div>
        <img
          src="/logo.white.png"
          alt="YourHealth Logo"
          style={{
            width: 300,
            marginBottom: '2rem',
          }}
        />
      </div>
      <div
        className="p-4 shadow-lg bg-white"
        style={{
          backgroundColor: '#181A1B',
          width: 480,
          maxWidth: '100%',
          borderRadius: 6,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="d-flex flex-column mb-4">
            <label htmlFor="password">Password</label>
            <input
              {...register("password")}
              type="password"
              id="password"
            />
          </div> */}
          <div className="d-flex flex-column mb-3">
            <label htmlFor="password">New Password</label>
            <div className="password-input-container">
              <input
                {...register("password")}
                id="password"
                type={showPassword ? "text" : "password"}
                className="form-input"
                placeholder="Enter a new password"
              />
              <button
                type="button"
                className="toggle-password-button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ?
                  <i className="fas fa-eye-slash text-sm" />
                  :
                  <i className="fas fa-eye text-sm" />
                }
              </button>
            </div>
          </div>

          {/* <div className="d-flex flex-column">
            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              {...register("confirmPassword")}
              type="password"
              id="confirm-password"
            />
          </div> */}

          <div className="d-flex flex-column">
            <label htmlFor="password">Confirm Password</label>
            <div className="password-input-container">
              <input
                {...register("confirmPassword")}
                id="password"
                type={showPassword ? "text" : "password"}
                className="form-input"
                placeholder="Confirm your new password"
              />
              <button
                type="button"
                className="toggle-password-button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ?
                  <i className="fas fa-eye-slash text-sm" />
                  :
                  <i className="fas fa-eye text-sm" />
                }
              </button>
            </div>
          </div>
          <div className="d-flex flex-column mt-4">
            <button
              type="submit"
              className="btn"
              disabled={isSubmitting}
              style={{
                backgroundColor: '#047D95',
                color: '#fff',
                border: 'none',
              }}
            >
              {isSubmitting &&
                <>
                  <div
                    className="spinner-border mr-2"
                    role="status"
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                  <span>Updating password</span>
                </>
              }

              {!isSubmitting &&
                <span>Update Password</span>
              }
            </button>
          </div>
        </form>
      </div>
      {errorMessage && (
        <div className="alert alert-danger mt-4" role="alert">
          {errorMessage}
        </div>
      )}
    </div>
  )
}