// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

// action - profile
export const INITIALIZE_PROFILE = '@profile/INITIALIZE_PROFILE';
export const CLEAR_PROFILE = '@profile/CLEAR_PROFILE';
export const UPDATE_PROFILE_USER = '@profile/UPDATE_PROFILE_USER';
export const UPDATE_WELCOME_STAGE = '@profile/UPDATE_WELCOME_STAGE';
export const SET_USER_ADMIN = '@profile/SET_USER_ADMIN';

// action - medical
export const LOAD_CONTACTS = '@medical/LOAD_CONTACTS';
export const LOAD_CONDITIONS = '@medical/LOAD_CONDITIONS';
export const LOAD_MEDICATIONS = '@medical/LOAD_MEDICATIONS';
export const LOAD_ALLERGIES = '@medical/LOAD_ALLERGIES';
export const LOAD_IMMUNIZATIONS = '@medical/LOAD_IMMUNIZATIONS';

// action - resources
export const LOAD_LINKS = '@resources/LOAD_LINKS';

// action - card
export const INITIALIZE_CARD = '@card/SET_CARD_TYPE';

// action - admin
export const INITIALIZE_ADMIN = '@admin/INITIALIZE_ADMIN';

// action - account
export const INITIALIZE_ACCOUNT = '@account/INITIALIZE_ACCOUNT';
export const SET_ACCOUNT_BILLING = '@account/SET_ACCOUNT_BILLING';
