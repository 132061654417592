import { connect } from 'react-redux';
import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import AuthFooter from "../components/Footers/AuthFooter.js";
import Homepage from "../components/Builder/Homepage";

function Index({ profile }) {
  return (
    <>
      <IndexNavbar />
      <div className="main-content">
        <Homepage />
      </div>
      <AuthFooter />
    </>
  );
}

const ConnectedIndex = connect(state => ({
  profile: state.profile
}))(Index);

export default ConnectedIndex;
