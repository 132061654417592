import * as actionTypes from '../actions';

const initialState = {
    initialized: false,
    contacts: [],
    conditions: [],
    medications: {
      prescription: [],
      otc: []
    },
    allergies: [],
    immunizations: []
};

const medicalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_CONTACTS: {
          return {
              ...state,
              contacts: action.payload
          };
        }
        case actionTypes.LOAD_CONDITIONS: {
          return {
              ...state,
              conditions: action.payload
          };
        }
        case actionTypes.LOAD_MEDICATIONS: {
          return {
              ...state,
              medications: {
                prescription: (action.payload.type == "PRESCRIPTION") ? action.payload.items : state.medications.prescription,
                otc: (action.payload.type == "OVERTHECOUNTER") ? action.payload.items : state.medications.otc
              }
          };
        }
        case actionTypes.LOAD_ALLERGIES: {
          return {
              ...state,
              allergies: action.payload
          };
        }
        case actionTypes.LOAD_IMMUNIZATIONS: {
          return {
              ...state,
              immunizations: action.payload
          };
        }
        default:
            return state;
    }
};

export default medicalReducer;
