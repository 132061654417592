import { useParams, Link } from "react-router-dom"
import { CardHeader, Row, Col } from 'reactstrap'

type MedicalCardDetailsHeaderProps = {
  title: string
}
export default function MedicalCardDetailsHeader({ title }: MedicalCardDetailsHeaderProps) {
  const { id: shareKeyUrlParam } = useParams<{ id: string; type: string }>()

  return (
    <CardHeader className="border-0">
      <Row>
        <Col xs="12">
          <div className="mb-3 text-sm">
            <Link to={`/card/view/medical/${shareKeyUrlParam}`}>
              <svg className="mr-2" style={{ width: 15, height: 15 }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
              </svg>
              Back to Medical Information
            </Link>
          </div>

          <h2 className="m-0 text-xl">{title}</h2>
        </Col>
      </Row>
    </CardHeader>
  )
}