/*
    Tutorial API
 */

// Tutorial
const Tutorial = {
  print: async function(profile) {
    console.log("Printing Getting Started: ", profile);

    // Open new window with PDF
    const printWindow = window.open('https://storage.googleapis.com/avant-garde-cdn/scratch/yourhealth/Getting_Started_10-06-22.pdf');
    printWindow.print();
  }
}

// Export module
export default Tutorial;
