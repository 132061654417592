// Imports
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';

// reactstrap components
import {
    Badge,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Media,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
    Modal
  } from "reactstrap";

// Import config
import config from "../../../config";

// Analytics (Firebase)
import { getAnalytics, logEvent } from "firebase/analytics";

const initialFormState = {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipCode: ''
}

export default function Checkout(props) {
  // State
  const [formData, setFormData] = useState(initialFormState);
  const [location, setLocation] = React.useState(window.location.href);

  // History
  let history = useHistory();

  // Track checkout start
  const trackCheckoutStart = () => {
    // Log initial profile creation
    const analytics = getAnalytics();
    logEvent(analytics, 'initiated_checkout');
  }

  return (
    <Card>
        <CardHeader>
            <Row className="align-items-center">
                <Col xs="8">
                <h3 className="mb-0">Setup Billing</h3>
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            <Row>
                <Col xs="12" md="4">
                    <Card style={{heigh: 450}}>
                        <CardHeader className="border-0">
                            <Row>
                                <Col xs="12">
                                    <h3 className="mb-0">The Essential Plan</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-2">
                              <Col xs="12" className="text-center">
                                <CardText className="mb-4">
                                  A complete Emergency Care Card with individualized QR codes for easy access anywhere.
                                </CardText>
                                <div className="display-2 text-black">$0.99</div>
                                <span className="text-black">per month</span>
                                <form action={`${config.api.billing.url}/billing-api/create-checkout-session`} method="POST">
                                    {/* Add a hidden field with the lookup_key of your Price */}
                                    <input type="hidden" name="lookup_key" value="essential" />
                                    <input type="hidden" name="return_url" value={location} />
                                    <Button className="mt-5" block color="primary" size="lg" type="submit" block>
                                        Purchase
                                    </Button>
                                </form>
                                <span className="text-muted text-center mt-1">30-Day Money Back Guarantee</span>
                              </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" md="4">
                    <Card style={{heigh: 450}}>
                        <CardHeader className="border-0">
                            <Row>
                                <Col xs="12">
                                    <h3 className="mb-0">The Enhanced Plan</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-2">
                              <Col xs="12" className="text-center">
                                <CardText className="mb-4">
                                  Everything in Essential plan, plus the ability to store any health records or personal directives you like, unlimited view of historical care records, alerts, inbound and outbound messaging to your Care Circle.
                                </CardText>
                                <Button className="mt-5" block color="primary" size="lg" block disabled>
                                    Soon to be Released
                                </Button>
                              </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" md="4">
                    <Card style={{heigh: 450}}>
                        <CardHeader className="border-0">
                            <Row>
                                <Col xs="12">
                                    <h3 className="mb-0">The Family Plan</h3>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row className="mb-2">
                              <Col xs="12" className="text-center">
                                <CardText className="mt-4 mb-4">
                                  Designed to manage health and wellness for each family member on an individual secure basis.
                                </CardText>
                                <Button className="mt-7" block color="primary" size="lg" block disabled>
                                    Soon to be Released
                                </Button>
                              </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </CardBody>
    </Card>
  );
}
